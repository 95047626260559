import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeToggleValue } from '../../actions/appState/appStateActions';
import { emptyMailList } from '../../actions/mail/mailActions';
import './Toggle.scss';

const Toggle = ({ toggleValues, toggleCounts, selectedValue }) => {
  const dispatch = useDispatch();
  const activeIndex = useSelector((state) => state.appStateReducer.toggleValue);


  const toggleClick = (index) => {
    if (index !== activeIndex) {
      dispatch(emptyMailList());
      dispatch(changeToggleValue(index));
    }
  };

  const nFormatter = (num, digits) => {
    const si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

  return (
    <nav role="navigation" className="ox-navigation-container toggle-nav-menu justify-center">
      <ul className="ox-navigation ox-navigation--grow">
        {
          toggleValues.map((value, index) => (
            <li
              key={`${value}-input`}
              className="ox-navigation__item"
              aria-current={index === selectedValue ? 'page' : ''}
            >
              <span
                role="button"
                className="ox-navigation__item__link toggle-nav-item"
                onClick={() => toggleClick(index)}
              >
                {value}&nbsp;
                <span className="ox-badge">
                   {nFormatter(toggleCounts[index], 1)}
                </span>
              </span>
            </li>
          ))
        }
      </ul>
    </nav>
  );
};

export default Toggle;
