import * as branchOfficeTypes from '../actions/branchOffice/branchOfficeTypes';
import { sortJsonArrayByKey } from '../helpers/arrayHelpers';
import { getOfficeIdOfUser, getActiveUserFromLocalstorage } from '../helpers/accountHelpers';

const initialState = {
  branchOffices: [],
  updateBranchOffice: null,
  loading: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case branchOfficeTypes.FETCH_BRANCH_OFFICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case branchOfficeTypes.FETCH_BRANCH_OFFICE_SUCCES: {
      const { data: branchOffices } = action;
      const userBranchOffices = getOfficeIdOfUser(getActiveUserFromLocalstorage());
      const branchOfficeFiltered = branchOffices.filter((branchOffice) => userBranchOffices.includes(branchOffice.id));
      sortJsonArrayByKey(branchOfficeFiltered, 'name');

      return {
        ...state,
        loading: false,
        branchOffices: branchOfficeFiltered,
      };
    }
    case branchOfficeTypes.FETCH_BRANCH_OFFICE_FAILED: {
      const { error } = action;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case branchOfficeTypes.CLEAR_BRANCH_OFFICES: {
      return { ...state, branchOffices: [] };
    }

    case branchOfficeTypes.UPDATE_BRANCH_OFFICE: {
      const { branchOffice } = action;
      const { branchOffices } = state;

      branchOffices.map((obj) => {
        if (branchOffice.id === obj.id) {
          return branchOffice;
        }
        return obj;
      });
      sortJsonArrayByKey(branchOffices, 'name');

      return { ...state, branchOffices, updateBranchOffice: branchOffice };
    }

    default:
      return state;
  }
};
