import React, { useEffect } from 'react';

export default ({
  remove, delay, message: { message, type }, autoRemove = true,
}) => {
  useEffect(() => {
    if (autoRemove) {
      setTimeout(remove, delay);
    }
  }, []);

  return (
    <div>
      <div
        className="feedback-type"
        message-type={type}
      />
      <div className="feedback-card">
        { message }
        <span
          role="button"
          className="close-button"
          onClick={remove}
        >
          <span className="fa fa-times" />
        </span>
      </div>
    </div>
  );
};
