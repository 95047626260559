import React from 'react';
import { checkIfAdmin, checkIfUser } from '../../helpers/accountHelpers';
import PrivateRoute from './PrivateRoute';
import useLocalStorage from '../../hooks/useLocalStorage';

const RoleRoute = ({
  userComponent: UserComponent, adminComponent: AdminComponent, ...rest
}) => {
  const { getValue: getActiveUser } = useLocalStorage('active-user', null);

  if (checkIfAdmin(getActiveUser())) {
    return (<PrivateRoute {...rest} component={AdminComponent} />);
  }
  if (checkIfUser(getActiveUser())) {
    return (<PrivateRoute {...rest} component={UserComponent} />);
  }
  return (<PrivateRoute {...rest} component={UserComponent} />);
};

export default RoleRoute;
