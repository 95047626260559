import { filterMachinesExtras, OPTIONS, ATTACHMENTS } from './machineHelpers';
import { containsOnly } from './arrayHelpers';


/**
 * Enormous function dat determines the properties for the entityList.
 * @param {
      disabled,
      entity,
      machines,
      index,
      activeOrder,
      machineOptions,
      canAddOptions,
      machineAttachments,
      canAddAttachments,
      customers,
      customerInfo,
      extraOptions,
    }
 * @param t useTranslation hook
 * @returns {errorMessage, isOptionsEntity, options, needsToBeDisabled, titleName, returnOption,extraInfo},
 * */
export const determineEntityRowProperties = ({
  disabled,
  entity,
  machines,
  index,
  canAddOptions,
  canAddAttachments,
  customers,
  customerInfo,
  extraOptions,
}, t) => {
  let errorMessage;
  let isOptionsEntity = false;
  let isAttachmentsEntity = false;
  let options = null;
  let needsToBeDisabled = disabled;
  let removeEnabled = !disabled;
  let titleName = entity.name;
  let returnOption = 'optionOne';
  let extraInfo = null;

  switch (entity.name.toLowerCase()) {
    case 'code': {
      const foundMachine = machines.find((machine) => machine.machkode === parseInt(entity.value[index][0] || '-1', 10));
      if (!foundMachine) {
        if (entity.value[index][0] !== null) {
          errorMessage = t('errors.invalidMachine');
        }
      } else {
        titleName = `${foundMachine.model} (${foundMachine.brand})`;
      }

      options = machines.map((machine) => ({
        optionOne: machine.model,
        optionTwo: machine.machkode,
        optionThree: machine.brand,
      }));
      returnOption = 'optionTwo';
      break;
    }
    case 'options': {
      const validMachineOptions = filterMachinesExtras(index.toString() || null, OPTIONS);
      const entityValues = entity.value[index];
      options = validMachineOptions.filter((option) => !entityValues.includes(option.description))
        .map((option) => ({
          optionOne: option.description,
          optionTwo: option.code,
          optionThree: null,
        }));
      isOptionsEntity = true;
      needsToBeDisabled = disabled || (options.length <= 0 && !canAddOptions(index.toString()));
      removeEnabled = entityValues.length > 0;
      if (!entityValues.includes(null) && !entityValues.includes('')
        && !containsOnly(validMachineOptions.map((item) => item.description), entityValues)) {
        errorMessage = t('errors.invalidOptions');
      }
      break;
    }
    case 'attachments': {
      const validMachineAttachments = filterMachinesExtras(index.toString() || null, ATTACHMENTS);
      const entityValues = entity.value[index];
      options = validMachineAttachments.filter((option) => !entityValues.includes(option.description))
        .map((option) => ({
          optionOne: option.description,
          optionTwo: option.code,
          optionThree: null,
        }));
      isAttachmentsEntity = true;
      needsToBeDisabled = disabled || (options.length <= 0 && !canAddAttachments(index.toString()));
      removeEnabled = entityValues.length > 0;
      if (!entityValues.includes(null) && !entityValues.includes('')
        && !containsOnly(validMachineAttachments.map((item) => item.description), entityValues)) {
        errorMessage = t('errors.invalidAttachments');
      }
      break;
    }
    case 'customer_number': {
      options = customers.map((customer) => ({
        optionOne: customer.customer_name,
        optionTwo: customer.vat_number,
        optionThree: customer.customer_no,
      }));
      extraInfo = customerInfo.vat;
      titleName = customerInfo.name || entity.displayName;
      returnOption = 'optionThree';
      break;
    }
    case 'extra': {
      options = extraOptions.map((option) => ({
        optionOne: option.subcategory,
        optionTwo: option.machine_code,
      }));

      const foundExtra = extraOptions.find((extraOption) => extraOption.machine_code === (entity.value[index][0] || '').toString());
      if (!foundExtra) {
        if (entity.value[index][0] !== null) {
          errorMessage = t('errors.invalidExtraOption');
        }
      } else {
        titleName = `${foundExtra.subcategory}`;
      }
      returnOption = 'optionTwo';
      break;
    }
    default:
      break;
  }
  return {
    errorMessage,
    isOptionsEntity,
    isAttachmentsEntity,
    options,
    needsToBeDisabled,
    removeEnabled,
    titleName,
    returnOption,
    extraInfo,
  };
};
