import React from 'react';
import './FullScreenLoader.scss';

const FullScreenLoader = ({ loading = false }) => (
  <div className="loading-container">
    <div className={loading ? 'loading' : 'loading hidden'}>
      <div className="uil-ring-css">
        <div />
      </div>
    </div>
  </div>
);

export default FullScreenLoader;
