import React from 'react';

export default ({
  text,
  action,
  configMap,
  groupName,
  className = '',
}) => (
  <div className={`add-machine ${className}`} role="button" onClick={() => action(configMap, groupName)}>
    <span className="add-machine-text ox-button--link">{text}</span>
    <i className="ox-icon ox-icon--add add-machine-icon" />
  </div>
);
