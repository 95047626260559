import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { determineEntityRowProperties } from '../../../../helpers/determineEntityRowProperties';
import Modal from '../../../modal/Modal';
import EntityRow from '../EntityRow';
import './MachineModal.scss';
import { MACHINE_PICKUP_DATE_ENTITY_ID } from '../../../../constants/constants';

const EXCLUDE_TITLE = [MACHINE_PICKUP_DATE_ENTITY_ID];

const mapStateToProps = (state) => ({
  ...state,
});

const MachineModal = ({
  currentMachineIndex,
  disabled,
  form,
  groupedEntities,
  openModal,
  setOpenModal,
  orderReducer: {
    activeOrder,
  },
  customerReducer: {
    customers,
  },
  extraOptionsReducer: {
    options: extraOptions,
  },
  machineReducer: {
    machines,
    options: machineOptions,
    attachments: machineAttachments,
  },
}) => {
  const { t } = useTranslation('annotationView');

  const renderTitle = useCallback((entityId) => {
    if (!EXCLUDE_TITLE.includes(entityId)) {
      return (
        <div className="group-header-wrapper">
          <p className="group-title">{t(`groupHeaders.${entityId}`)}</p>
          <hr className="fill-line" />
        </div>
      );
    }
    return null;
  }, []);

  const machineModalItems = useMemo(() => {
    const machineGroup = groupedEntities.find((group) => group.index === 2);
    if (!machineGroup && !openModal) {
      return null;
    }
    const entitiesForModal = machineGroup.entityTypes.filter((entity) => entity.showInModal);
    return entitiesForModal.map((entity) => {
      const isTextArea = entity.name.toLowerCase() === 'comments';
      // The reason for this destruction is a huge switch-case that can become even larger.
      const combinedParameters = {
        disabled: (entity.featureDisabled || disabled),
        entity,
        machines,
        index: currentMachineIndex,
        activeOrder,
        machineOptions,
        machineAttachments,
        customers,
        extraOptions,
      };
      const {
        errorMessage,
        options,
        needsToBeDisabled,
        titleName,
        extraInfo,
      } = determineEntityRowProperties(combinedParameters);

      return (
        <React.Fragment key={`machine-modal-wrapper-${entity.id}`}>
          {renderTitle(entity.id)}
          <EntityRow
            key={`entity-row-modal-index-${entity.id}`}
            id={entity.id}
            index={currentMachineIndex}
            entity={entity}
            value={entity.value[currentMachineIndex]}
            name={titleName}
            isTextArea={isTextArea}
            options={options}
            selected={false}
            disabled={needsToBeDisabled}
            required={entity.required}
            hasMultipleAnnotations={false}
            errorMessage={errorMessage}
            extraInfo={extraInfo}
            form={form}
            annotatingDisabled
            featureDisabled={entity.featureDisabled}
          />
        </React.Fragment>
      );
    });
  }, [groupedEntities, currentMachineIndex]);

  const modalContent = (
    <div className="test-container">
      <div className="ox-modal__body ox-modal__large">
        <h3 className="ox-modal__title">{t('modalTitle')}</h3>
        <p className="ox-modal__sub-title">{t('modalSubTitle')}</p>
        {machineModalItems}
      </div>
      <div className="ox-modal__footer">
        <div className="ox-button-group ox-button-group--reverse">
          <button
            type="button"
            className="ox-button ox-button--secondary"
            onClick={() => setOpenModal(false, currentMachineIndex)}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );

  return <Modal Open={openModal} Wide Content={modalContent} Close={() => setOpenModal(false, currentMachineIndex)} />;
};

export default connect(mapStateToProps)(MachineModal);
