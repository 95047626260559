import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getAccessibleTextColor from '../../../helpers/entityHelpers';
import * as entityActionFile from '../../../actions/entity/entityActions';
import './HotKey.scss';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  entityActions: bindActionCreators(entityActionFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  disabled,
  entityActions,
  entityId,
  name,
  color,
  hotkey,
  highlighted,
  index = '1',
}) => {
  const colorStyle = {
    backgroundColor: '#bebebe',
    color: '#3e3e3e',
  };

  if (highlighted && !disabled) {
    colorStyle.backgroundColor = color;
    colorStyle.color = getAccessibleTextColor(color);
  }

  const handleHotkeyClicked = useCallback(() => {
    if (!disabled) {
      entityActions.changeSelectedEntity(entityId, color, name, (index || '1').toString());
    }
  }, [disabled]);

  return (
    <span disabled={disabled} role="button" onClick={handleHotkeyClicked} className="hotkey-block" style={colorStyle}>{hotkey}</span>
  );
});
