import { uniqWith, cloneDeep } from 'lodash';
import * as annotationsTypes from '../actions/annotation/annotationTypes';
import getAccessibleTextColor from '../helpers/entityHelpers';
import { findEntitiesOfGroupByGroupName } from '../helpers/reduxHelpers';
import hash from 'object-hash';

const initialState = {
  loading: false,
  error: undefined,
  documents: {},
  marks: [],
  removeMarks: [],
};

const annotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case annotationsTypes.RESET_STATE: {
      return initialState;
    }
    case annotationsTypes.FETCH_ANNOTATIONS:
      return {
        ...state,
        loading: true,
        documents: {},
        marks: [],
        removeMarks: [],
      };
    case annotationsTypes.FETCH_ANNOTATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case annotationsTypes.FETCH_ANNOTATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case annotationsTypes.UPDATE_ANNOTATIONS:
      const {
        marks,
        documentId,
      } = action;
      const { documents } = state;
      const updatedDocuments = {
        ...documents,
      };

      updatedDocuments[documentId].marks = marks;

      let allMarks = []
      for (const [key, document] of Object.entries(updatedDocuments)) {
        allMarks = allMarks.concat(document.marks);
      }

      return {
        ...state,
        documents: updatedDocuments,
        marks: allMarks,
      };

    case annotationsTypes.OVERWRITE_ANNOTATIONS: {
      const {
        mailDocuments,
        entityTypes,
      } = action;

      const documents = {}
      let allMarks = []
      mailDocuments.forEach((mailDocument) => {
        const marks = mailDocument.entityAnnotations.map((mark) => {
            const entityType = entityTypes.find((entity) => entity.id === mark.entityType.id);
            if (entityType && mark.text_ids && mark.text_ids.length) {
              const tokens = mark.tokens
              if (!tokens) {tokens = []}
              const newMark = {
                id: mark.id,
                page: mark.page,
                tokens: tokens,
                scores: mark.scores,
                textIds: mark.text_ids,
                entity: {
                  index: mark.index,
                  id: entityType.id,
                  name: entityType.name,
                  color: entityType.color,
                  entityType: "NER"
                },
              }
              return newMark;
            }
            return null;
        }).filter((mark) => mark !== null);
        // Combine all marks
        allMarks = allMarks.concat(marks)

        // Add marks to document
        documents[mailDocument.id] = {
          marks: marks,
        };
      });

      return {
        ...state,
        documents,
        marks: allMarks,
      };
    }

    case annotationsTypes.CLEAR_ANNOTATIONS:
      return {
        ...state,
        documents: {},
        marks: [],
      };

    case annotationsTypes.REMOVE_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        removeMarks: [],
      };

    case annotationsTypes.REMOVE_ANNOTATIONS_BY_ENTITY_ID: {
      const { entityId, index, orderIndex } = action;
      const { documents } = state;
      let removeMarks = []
      let marks = []
      for (const [key, document] of Object.entries(documents)) {
        document.marks.forEach((mark) => {
          mark.documentId = key;
          marks = marks.concat(mark)
        })
      }

      // Sort marks to make sure index of removed entity matches
      marks.sort(function(a, b){
        if (typeof a.id === 'string' || a.id instanceof String || typeof b.id === 'string' || b.id instanceof String) return 0;
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      })

      // Search for mark to delete
      const markToDelete = marks.filter(
        (mark) => entityId === mark.entity.id && orderIndex.toString() === mark.entity.index.toString())[index];
      if (markToDelete) {
        removeMarks = removeMarks.concat({
          documentId: markToDelete.documentId,
          mark: markToDelete
        })
      }

      return {
        ...state,
        removeMarks,
      };
    }

    case annotationsTypes.REMOVE_ANNOTATIONS_BY_GROUP: {
      const { configMap, indexToRemove, groupName } = action;
      const { documents } = state;

      const groupEntitiesIds = findEntitiesOfGroupByGroupName(configMap, 0, groupName)
        .filter((entity) => entity.canBeAnnotated)
        .map((entity) => entity.id);
      if (!groupEntitiesIds.length){
        return state;
      }

      let removeMarks = []
      for (const [key, document] of Object.entries(documents)) {
        const marks = document.marks;
        const entityMarks = marks.filter((mark) => groupEntitiesIds.includes(mark.entity.id))
        entityMarks.forEach((entityMark) => {
          const markIndex = marks.findIndex((mark) => mark.textIds === entityMark.textIds);
          if (indexToRemove === entityMark.entity.index.toString()) {
            removeMarks = removeMarks.concat({
              documentId: key,
              mark: marks[markIndex]
            })
          } else if (entityMark.entity.index > indexToRemove) {
            marks[markIndex].entity.index -= 1;
          }
        })
      }

      return {
        ...state,
        removeMarks,
      };

      return state;
    }

    default:
      return state;
  }
};

export { annotationReducer as default };
