import React from 'react';
import { NavLink } from 'react-router-dom';
import './InfoSidebarSkeleton.scss';
import './SkeletonAnimation.scss';

export default () => (
  <div className="sidebar documents info-sidebar">
    <div className="title">
      <NavLink to="/overview">
        <span className="ox-icon ox-icon--chevron-left" /> Back to overview
      </NavLink>
    </div>
    <div className="sidebar-content-skeleton">
      <span className="sidebar-content-skeleton-header skeleton-box" />
      <span className="sidebar-content-skeleton-text skeleton-box" />
      <span className="sidebar-content-skeleton-text skeleton-box" />
    </div>
    <div className="attachment-item-container-skeleton">
      <div className="attachment-skeleton">
        <span className="fa fa-file attachment-icon" />
        <span className="attachment-name-skeleton skeleton-box" />
      </div>
      <div className="attachment-skeleton">
        <span className="fa fa-file attachment-icon" />
        <span className="attachment-name-skeleton skeleton-box" />
      </div>
    </div>
  </div>
);
