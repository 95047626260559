import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import * as annotationActionFile from '../../../actions/annotation/annotationActions';
import * as mailActionsFile from '../../../actions/mail/mailActions';
import * as documentActionsFile from '../../../actions/document/documentActions';
import PriorityLabel from '../../priorityLabel/PriorityLabel';
import AttachmentItem from './AttachmentItem';
import './InfoSidebar.scss';
import * as metricsReportActionsFile from '../../../actions/metricsReport/metricsReportActions';
import { formatDateTime } from '../../../helpers/date-helpers';
import DocumentTypeSelector from './DocumentTypeSelector';
import { Mixpanel } from '../../../mixpanel/Mixpanel';

const validLabels = [
  'PRIO',
  'DAY AFTER TOMORROW',
];

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  mailActions: bindActionCreators(mailActionsFile, dispatch),
  documentActions: bindActionCreators(documentActionsFile, dispatch),
  metricsReportActions: bindActionCreators(metricsReportActionsFile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({
  annotationActions,
  mailActions,
  annotationReducer,
  mailReducer: {
    activeMail,
    mailDocuments,
    activeDocument,
  },
  documentReducer: {
    documentTypes,
  },
  cancelMail,
}) => {
  const { t } = useTranslation('annotationView');

  const handleBackClick = useCallback(() => {
    Mixpanel.track('Back to overview clicked')
    cancelMail('BACK');
  }, [cancelMail]);

  const CreatePriorityLabel = useMemo(() => {
    if (activeMail && activeMail.categories) {
      const filteredCategories = activeMail.categories.filter((category) => validLabels.includes(category));

      if (filteredCategories.length > 0) {
        return <PriorityLabel label={filteredCategories[0]} />;
      }
    }

    return null;
  }, [activeMail]);

  const retrieveExtensionFromName = (filename) => {
    const extensionRegex = /(?:\.([^.]+))?$/;
    const extension = extensionRegex.exec(filename)[0];
    const images = ['.jpg', '.png'];

    if (!extension) {
      return 'mail';
    }
    if (images.includes(extension.toLowerCase())) {
      return 'image';
    }

    return extension.toLowerCase().replace('.', '');
  };

  const isEmail = (attachment) => attachment.name && attachment.name.toLowerCase() === 'body';

  const getAttachmentName = (attachment) => {
    if (isEmail(attachment)) {
      return t('mail');
    } if (attachment.name.toLowerCase() === 'body.pdf') {
      return 'e-mail text';
    }
    return attachment.name;
  };

  const createTitleContainer = useMemo(() => {
    const { processedAt } = activeMail || {};
    if (!processedAt) {
      return null;
    }
    return (
      <div className="sidebar-title">
        <p className="sidebar-title--content">
          Processed by {activeMail.employee.username}
        </p>
      </div>
    );
  }, [activeDocument]);

  return (
    <div className="sidebar documents info-sidebar">
      <div className="title">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a role="button" onClick={handleBackClick}>
          <span className="ox-icon ox-icon--chevron-left" />
          {t('back')}
        </a>
      </div>
      {
        activeMail
          ? (
            <>
              {createTitleContainer}
              <div className="sidebar-content">
                <h4>{activeMail.subject || 'No subject'}</h4>
                <p>{activeMail.sender}</p>
                <p>{formatDateTime(activeMail.timestamp)}</p>
                {CreatePriorityLabel}
              </div>
              {
            mailDocuments
              .map((attachment, index) => (
                <div key={`${attachment.id}-wrapper`} className={mailDocuments[activeDocument].id === attachment.id ? 'attachment-wrapper selected' : 'attachment-wrapper'}>
                  <AttachmentItem
                    key={attachment.id}
                    AttachmentIcon={retrieveExtensionFromName(attachment.name)}
                    AttachmentName={getAttachmentName(attachment)}
                    DocumentType={documentTypes.find((documentType) => documentType?.id === attachment?.documentType?.id)}
                    Selected={mailDocuments[activeDocument].id === attachment.id}
                    OnClick={() => {
                      mailActions.changeActiveDocument(index);
                    }}
                  />
                  {attachment.documentType && !isEmail(attachment)
                    ? (
                      <DocumentTypeSelector
                        key={`${attachment.id}-DocumentTypeSelector`}
                        ChangeSelection={mailActions.changeAttachmentType}
                        Disabled={activeMail && activeMail.processedAt}
                        Type={attachment.documentType}
                        DocumentId={attachment.id}
                        DocumentTypes={documentTypes}
                      />
                    )
                    : null}

                </div>
              ))
              }
            </>
          )
          : null
        }
    </div>
  );
});
