import * as keyEventTypes from '../actions/keyEvent/keyEventTypes';

const initialState = {
  isKeyEventEnabled: true,
};

const keyEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case keyEventTypes.ENABLE_KEY_EVENT:
      return { ...state, isKeyEventEnabled: true };
    case keyEventTypes.DISABLE_KEY_EVENT:
      return { ...state, isKeyEventEnabled: false };
    default:
      return state;
  }
};

export { keyEventReducer as default };
