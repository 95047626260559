export const HOLD_ORDER = 'HOLD_ORDER';
export const HOLD_ORDER_SUCCESS = 'HOLD_ORDER_SUCCESS';
export const HOLD_ORDER_FAILED = 'HOLD_ORDER_FAILED';

export const APPROVE_ORDER = 'APPROVE_ORDER';
export const APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS';
export const APPROVE_ORDER_FAILED = 'APPROVE_ORDER_FAILED';

export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';

export const DIRTY_FORM = 'SET_FORM_IS_DIRTY';

export const CHANGE_VARIABLE = 'CHANGE_VARIABLE';
export const CLEAR_VARIABLE = 'CLEAR_VARIABLE';

export const ADD_GROUP_TO_ORDER = 'ADD_GROUP_TO_ORDER';
export const REMOVE_GROUP_FROM_ORDER = 'REMOVE_MACHINE_FROM_ORDER';

export const CHANGE_ACTIVE_TAB_INDEX = 'CHANGE_ACTIVE_TAB_INDEX';

export const CLEAR_ORDER = 'CLEAR_ORDER';
