import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../../modal/Modal';
import * as mailActionFile from '../../../../actions/mail/mailActions';
import * as feedbackActionFile from '../../../../actions/feedback/feedbackActions';
import './ArchiveModal.scss';
import * as annotationActionFile from '../../../../actions/annotation/annotationActions';
import * as orderActionFile from '../../../../actions/order/orderActions';
import { Mixpanel } from '../../../../mixpanel/Mixpanel';

const MAX_CHARS = 75;

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  mailActions: bindActionCreators(mailActionFile, dispatch),
  feedbackActions: bindActionCreators(feedbackActionFile, dispatch),
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  orderActions: bindActionCreators(orderActionFile, dispatch),
});

const ArchiveModal = ({
  reasons,
  open,
  close,
  mailId,
  history,
  mailActions,
  feedbackActions,
  annotationActions,
  orderDocument,
  annotationReducer: {
    documents,
    marks,
  },
  mailReducer: {
    activeMail,
  },
  orderReducer: {
    activeOrder,
  },
  machineReducer: {
    attachments: machineAttachments,
  },
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [customText, setCustomText] = useState('');
  const [selectedReason, setSelectedReason] = useState('Other reason');

  const archiveMail = async () => {
    Mixpanel.track('Archive click');
    const readOnly = activeMail.processedAt !== null;
    if (!readOnly) {
      setIsApproving(true);
      await annotationActions.saveAnnotations(documents);
      await mailActions.reportMail(selectedReason, customText, mailId);
      feedbackActions.addSuccessMessage('The mail has been archived.');
      history.push('/');
    }
  };

  const modalContent = useMemo(() => (
    <>
      <div className="ox-modal__body report-modal">
        <h3 className="ox-modal__title">Archive mail</h3>
        <div className="ox-form__group">
          <div className="ox-form__group group">
            <label htmlFor="selection" className="ox-form__label">Reason</label>
            <select
              value={selectedReason}
              onChange={(event) => setSelectedReason(event.target.value)}
              className="input-field ox-form__field ox-form__field--small"
            >
              {
                reasons.concat([{
                  id: 'OTHER_REASON',
                  reason: 'Other reason',
                }]).map(({ id, reason }) => (
                  <option key={id} value={reason}>{reason}</option>
                ))
              }
            </select>
          </div>
          <div className="ox-form__group group">
            <label className="ox-form__label">Extra info</label>
            <textarea
              id="description-input"
              value={customText}
              onChange={(event) => setCustomText(event.target.value)}
              maxLength={MAX_CHARS}
              className="input-field ox-form__field"
            />
            <label className="ox-form__label ox-text--right"> {customText.length}/75</label>
          </div>
        </div>
      </div>
      <div className="ox-modal__footer">
        <div className="ox-button-group ox-button-group--right">
          <button
            type="button"
            onClick={close}
            className="ox-button ox-button--secondary"
          >
            <span className="ox-icon ox-icon--cancel" /> Cancel
          </button>
          <button
            disabled={isApproving}
            type="button"
            onClick={archiveMail}
            className="ox-button ox-button--primary"
          >
            <span className="ox-icon ox-icon--archive" /> Archive
          </button>
        </div>
      </div>
    </>
  ), [activeMail, reasons, selectedReason, customText, isApproving, mailId, orderDocument, activeOrder, machineAttachments, marks]);

  return (
    <Modal
      Open={open}
      Close={close}
      Content={modalContent}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveModal);
