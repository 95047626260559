import {
  useState,
  useEffect,
} from 'react';
import useWebSocket from './useWebSocket';

const useAssigned = (localAssignedId, mailId, hasBeenAssigned = true) => {
  const topic = `mail/${mailId}`;
  const [message, setMessage] = useState(null);
  const [isAssigned, setIsAssigned] = useState(hasBeenAssigned);

  const { socketConnected, subscribeTopic } = useWebSocket(setMessage);

  useEffect(() => {
    if (socketConnected) {
      subscribeTopic(topic);
    }
  }, [socketConnected]);

  useEffect(() => {
    if (message) {
      const assigned = message.body;
      if (!isAssigned && (assigned && assigned.employee && assigned.employee.id === localAssignedId)) {
        setIsAssigned(true);
      } else if (isAssigned && (assigned && (!assigned.employee || assigned.employee.id !== localAssignedId))) {
        setIsAssigned(false);
      }
    }
  }, [message]);

  return { isAssigned, setIsAssigned };
};

export default useAssigned;
