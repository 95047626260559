import React from 'react';
import './Loader.scss';

export default ({
  loading, label, height = 30, width = 30,
}) => {
  const loadingClass = `"ox-is-loading ${label ? 'ox-is-loading--text' : ''}`;
  if (!loading) {
    return null;
  }
  return (
    <div className={loadingClass} role="status" aria-live="polite" aria-label="Content loading">
      <img
        className="ox-loader"
        src="https://ox.qone.mateco.eu/assets/img/loader.svg"
        alt="Loading..."
        aria-hidden="true"
        height={height}
        width={width}
      />
      {label ? <label>{label}</label> : null}
    </div>
  );
};
