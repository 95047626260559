import axios from 'axios';
import * as userTypes from './userTypes';
import { addErrorMessage } from '../feedback/feedbackActions';
import { baseUrl } from '../../constants/constants';
import { Mixpanel } from '../../mixpanel/Mixpanel';

// In axios error handling, call this function on 401 response
export const invalidApiKey = () => ({ type: userTypes.INVALID_API_KEY });

const userLoginSuccess = ({
  token, roles, id, language, branchOfficeId,
}) => ({
  type: userTypes.USER_LOGIN_SUCCESS, token, roles, id, language, branchOfficeId,
});
const userLoginFailed = (error) => ({ type: userTypes.USER_LOGIN_FAILED, error });
const userLoginLoading = () => ({ type: userTypes.USER_LOGIN });

export function loginUser(userName, userPassword) {
  return (dispatch) => {
    dispatch(userLoginLoading());
    const data = { username: userName, password: userPassword };

    axios.post(`${baseUrl}/authenticate`, data)
      .then((response) => {
        if (!response.data.branchOfficeId.length) {
          Mixpanel.track('Unsuccessful login');
          dispatch(userLoginFailed('You are not assigned to an office, please contact an admin.'));
          dispatch(addErrorMessage('You are not assigned to an office, please contact an admin.'));
        } else {
          Mixpanel.identify(response.data.id);
          Mixpanel.track('Successful login');
          dispatch(userLoginSuccess(response.data));
        }
      }, (error) => {
        Mixpanel.track('Unsuccessful login');
        dispatch(userLoginFailed(error));
        dispatch(addErrorMessage('Login failed! Invalid username or password.'));
      });
  };
}

export const logoutUser = () => ({ type: userTypes.USER_LOGOUT });
