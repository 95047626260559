import axios from 'axios';
import * as uploadTypes from './uploadTypes';
import { addErrorMessage, addSuccessMessage } from '../feedback/feedbackActions';
import {
  authenticationHeader,
  baseUrl,
} from '../../constants/constants';

// In axios error handling, call this function on 401 response
export const invalidApiKey = () => ({ type: uploadTypes.INVALID_API_KEY });

const uploadSuccess = () => ({ type: uploadTypes.UPLOAD_DOCUMENT_SUCCESS });
const uploadFailed = (error) => ({ type: uploadTypes.UPLOAD_DOCUMENT_FAILED, error });
const uploadLoading = () => ({ type: uploadTypes.UPLOAD_DOCUMENT });

export function uploadFile(file, branchId) {
  return (dispatch) => {
    dispatch(uploadLoading());
    const formData = new FormData();
    formData.append('file', file);
    axios.post(`${baseUrl}/upload/${branchId}`, formData, authenticationHeader)
      .then(() => {
        dispatch(uploadSuccess());
        dispatch(addSuccessMessage('Document upload succeeded'));
      }, (error) => {
        dispatch(uploadFailed(error));
        dispatch(addErrorMessage('Document upload failed'));
      });
  };
}

export function showError(text) {
  return (dispatch) => {
    dispatch(addErrorMessage(text));
  };
}
