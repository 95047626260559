import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import HotKey from './HotKey';
import * as annotationActionFile from '../../../actions/annotation/annotationActions';
import * as keyEventActionFile from '../../../actions/keyEvent/keyEventActions';
import './EntityRow.scss';
import * as orderActionsFile from '../../../actions/order/orderActions';
import * as configMapActionsFile from '../../../actions/configMap/configMapActions';
import * as entityActionsFile from '../../../actions/entity/entityActions';
import { formatDate, formatHour } from '../../../helpers/date-helpers';
import { checkAllDependentEntitiesForValueChanges } from '../../../helpers/configMapConstraintHelpers';
import EntityComponent from './EntityComponent';
import {
  isValidNumber,
  checkIfRequiredAndEmptyValue,
  checkMultiValues,
  checkIfDateIsFuture,
  checkDateTimeValues,
  checkIfMinLengthIsMet,
} from '../../../helpers/formValidationHelpers';
import { ENTITY_IDS_THAT_HAVE_MINIMUM_LENGTH } from '../../../constants/constants';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  keyEventActions: bindActionCreators(keyEventActionFile, dispatch),
  orderActions: bindActionCreators(orderActionsFile, dispatch),
  configMapActions: bindActionCreators(configMapActionsFile, dispatch),
  entityActions: bindActionCreators(entityActionsFile, dispatch),
});

const EntityRow = ({
  id,
  index: indexToAdjust,
  entity,
  name,
  isTextArea,
  options,
  value: initialValue,
  hasMultipleAnnotations,
  disabled,
  removeEnabled,
  annotatingDisabled,
  selected,
  annotationActions,
  orderActions,
  configMapActions,
  entityActions,
  required = false,
  errorMessage = null,
  extraInfo = null,
  returnOption = 'optionOne',
  featureDisabled = false,
  configMapReducer: {
    configMap,
  },
  orderReducer: {
    activeTabIndex,
  },
  mailReducer: {
    activeMail,
  },
  form,
}) => {
  const [value, setValue] = useState(initialValue);
  const [autoFormatted, setAutoFormatted] = useState(false);

  // Properties are used to debounce the format of the date.
  const [isError, setIsError] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [computedErrorMessage, setComputedErrorMessage] = useState(errorMessage);
  const [entityRowClass, setEntityRowClass] = useState('');
  const [entityInfoClass, setEntityInfoClass] = useState('');

  const { setError, errors, clearError } = form;
  const isEmpty = value && value.length === 0;
  const requiredValue = useMemo(() => required, [entity, required]);

  const { t } = useTranslation('annotationView');

  useEffect(() => {
    if (!lodash.isEqual(value, initialValue)) {
      setValue(initialValue);
      setAutoFormatted(false);
    }
  }, [initialValue, initialValue.length, isRemoving]);

  useEffect(() => {
    setComputedErrorMessage(errorMessage);
  }, [errorMessage]);

  const checkDependentProperties = () => {
    if (!entity || activeMail.processedAt) {
      return;
    }
    const requiredVariables = {
      configMap,
      value,
      entity,
      configMapActions,
      orderActions,
      indexToAdjust,
      activeTabIndex,
    };
    checkAllDependentEntitiesForValueChanges(requiredVariables);
  };

  const updateStore = useCallback((updateValue = null) => {
    let valueToWrite = (updateValue || value).filter(Boolean);
    if (!valueToWrite.length) {
      setValue([null]);
      valueToWrite = [null];
    }
    if (id) {
      orderActions.changeVariable({
        orderEntityId: id,
        indexToAdjust,
        value: valueToWrite,
      });
      if (value) {
        checkDependentProperties();
      }
    }
  }, [value]);

  useEffect(() => {
    if (isRemoving) {
      updateStore();
      setIsRemoving(false);
    }
  }, [isRemoving]);

  useEffect(() => {
    if (!isEditing) {
      updateStore();
    }
  }, [isEditing]);

  const updateValues = ({
    error,
    returnValue,
    errorTextMessage,
  }) => {
    setIsError(error);
    setComputedErrorMessage(errorTextMessage);
    setValue(returnValue);
    updateStore(returnValue);
  };

  const autoFormatValues = () => {
    if (disabled) {
      return;
    }
    const isDate = (entity || {}).componentType === 'date';
    const isHour = (entity || {}).componentType === 'time';

    const valueToFormat = value[0];
    if (!valueToFormat) {
      return;
    }
    if (isDate) {
      formatDate(value).then((result) => {
        updateValues(result);
      });
    }

    if (isHour) {
      formatHour(value).then((result) => {
        updateValues(result);
      });
    }
  };

  // This effect is needed to format values on initialisation of a component.
  // Using the state autoFormatted here, helps to control the amount of updates.
  useEffect(() => {
    if (value && value[0] && !autoFormatted) {
      autoFormatValues();
    }
    setAutoFormatted(true);
  }, [value, autoFormatted]);

  const validateValue = (preComputedErrorMessage) => {
    const errorsToSet = [];
    const errorId = `${entity.id}-${indexToAdjust}`;
    if (featureDisabled) {
      errorsToSet.push({ type: 'disabled', name: errorId, message: t('errors.disabled') });
    } else {
      clearError(errorId, 'disabled');
    }
    if (checkIfRequiredAndEmptyValue(requiredValue, value, entity, indexToAdjust)) {
      const message = t('errors.required.1');
      errorsToSet.push({ type: 'required', name: errorId, message });
    } else {
      clearError(errorId, 'required');
    }
    if (isValidNumber(entity, value)) {
      clearError(errorId, 'invalid');
    } else {
      errorsToSet.push({ type: 'invalid', name: errorId, message: t('errors.invalid') });
    }
    if (checkIfDateIsFuture(entity, value)) {
      clearError(errorId, 'isNotFutureDate');
    } else {
      errorsToSet.push({ type: 'isNotFutureDate', name: errorId, message: t('errors.isNotFutureDate.1') });
    }
    if (preComputedErrorMessage) {
      errorsToSet.push({ type: 'general', name: errorId, message: preComputedErrorMessage });
    } else {
      clearError(errorId, 'general');
    }
    if (checkDateTimeValues(entity, indexToAdjust)) {
      errorsToSet.push({
        type: 'incorrectDateTime',
        message: t('errors.incorrectDateTime'),
        name: errorId,
      });
    } else {
      clearError(errorId, 'incorrectDateTime');
    }
    if (checkMultiValues(entity, value)) {
      errorsToSet.push({ type: 'multiValues', name: errorId, message: t('errors.multiValues') });
    } else {
      clearError(errorId, 'multiValues');
    }
    if (!checkIfMinLengthIsMet(entity, value)) {
      errorsToSet.push({
        type: 'minLength',
        name: errorId,
        message: `${t(`entities.${entity.name}`)} ${t('errors.minLength.1')} ${ENTITY_IDS_THAT_HAVE_MINIMUM_LENGTH[entity.id]} ${t('errors.minLength.2')}`,
      });
    } else {
      clearError(errorId, 'minLength');
    }
    if (!activeMail.processedAt) {
      setError(errorsToSet);
    }
  };

  const checkErrors = useMemo(() => {
    validateValue(computedErrorMessage);
    const errorToCheck = errors[`${entity.id}-${indexToAdjust}`] || {};
    if (errorToCheck.message || isError || computedErrorMessage) {
      if (errorToCheck.type !== 'disabled') {
        setEntityRowClass('entity-info--required');
        setEntityInfoClass('ox-is-invalid');
      }

      return (
        <div className="ox-form__feedback form-validation-container">
          <span className="ox-form__feedback" id="contact-person-validation-message">
            {computedErrorMessage || errorToCheck.message}
          </span>
          <span className="ox-form__feedback" id="contact-person-validation-message" />
          <span className="ox-icon ox-icon--error ox-text--error validation-icon" />
        </div>
      );
    }
    setEntityInfoClass('');
    return null;
  }, [value, errors, isError, computedErrorMessage, requiredValue, entity]);

  const handleRemoveClicked = (index, orderIndex) => {
    if ((!disabled || removeEnabled) && !activeMail.processedAt) {
      setIsRemoving(true);
      const valueCopy = value.slice();
      if (index > valueCopy.length || valueCopy.length === 1) {
        setValue([null]);
      } else {
        valueCopy.splice(index, 1);
        setValue(valueCopy);
      }

      if ((entity && entity.id) || id) {
        const idToRemove = id || entity.id;
        // This will remove the mark from the PDF if an enitity is present on the current row
        // updateStore();
        annotationActions.removeAnnotationsForEntityId(idToRemove, index, orderIndex);
      }
      setIsError(false);
      setComputedErrorMessage('');
      orderActions.setFormDirty(true);
    }
  };

  const metroStyle = { borderColor: '#bebebe' };

  if (selected && entity && !annotatingDisabled) {
    const { color } = entity;
    metroStyle.borderColor = color;
  }

  const handleChange = (changedValue, index) => {
    const valueCopy = value.slice();
    if (index > valueCopy.length) {
      valueCopy.push(changedValue);
    } else {
      valueCopy[index] = changedValue;
    }

    if (changedValue === null && valueCopy.length > 1) {
      valueCopy.splice(index, 1);
    }

    setValue(valueCopy);
    orderActions.setFormDirty(true);
  };

  const handleRowHovered = () => {
    if (!annotatingDisabled && entity && entity.canBeAnnotated && value) {
      entityActions.changeHoveredEntity(entity.id);
    }
  };

  const handleRowNotHovered = () => {
    if (!annotatingDisabled && entity && entity.canBeAnnotated && value) {
      entityActions.clearHoveredEntity();
    }
  };

  const canBeClicked = useCallback(() => !annotatingDisabled && entity && entity.canBeAnnotated, [annotatingDisabled, entity]);

  const letterBlock = useMemo(() => {
    if (!isTextArea) {
      if (entity.canBeAnnotated) {
        const {
          id: entityId,
          name,
          color,
          hotkey: hotKeyLetter,
        } = entity;
        return (
          <HotKey
            entityId={entityId}
            highlighted={selected}
            name={name}
            color={color}
            hotkey={hotKeyLetter}
            disabled={annotatingDisabled}
            index={indexToAdjust}
          />
        );
      }

      return <span className="ox-machine-icon ox-machine-icon--engine-manual hotkey-placeholder-block" />;
    }
    return null;
  }, [entity, selected, annotatingDisabled, isEmpty, indexToAdjust]);

  return (
    <div
      role="button"
      className={entityRowClass}
      onClick={canBeClicked ? () => {} : entityActions.clearSelectedEntity}
    >
      <div
        onMouseLeave={handleRowNotHovered}
        onMouseEnter={handleRowHovered}
        className={`entity-row ${entity.componentType || ''}`}
      >
        {letterBlock}
        <div className={`entity-info ${entityInfoClass}`}>
          <EntityComponent
            value={value}
            entity={entity}
            name={name}
            index={indexToAdjust}
            disabled={disabled}
            removeEnabled={removeEnabled}
            metroStyle={metroStyle}
            isTextArea={isTextArea}
            hasMultipleAnnotations={hasMultipleAnnotations}
            required={required}
            returnOption={returnOption}
            extraInfo={extraInfo}
            initialValue={initialValue}
            options={options}
            handleChange={handleChange}
            handleRemoveClicked={handleRemoveClicked}
            updateStore={updateStore}
            autoFormatValues={autoFormatValues}
            form={form}
            changeIsEditing={setIsEditing}
          />
          {checkErrors}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityRow);
