import axios from 'axios';
import * as branchOfficeTypes from './branchOfficeTypes';
import { addErrorMessage } from '../feedback/feedbackActions';
import { authenticationHeader, baseUrl } from '../../constants/constants';

export const fetchBranchOfficeSuccess = (data) => ({ type: branchOfficeTypes.FETCH_BRANCH_OFFICE_SUCCES, data });
const fetchBranchOfficeFailed = (error) => ({ type: branchOfficeTypes.FETCH_BRANCH_OFFICE_FAILED, error });
const fetchBranchOfficeLoading = () => ({ type: branchOfficeTypes.FETCH_BRANCH_OFFICE_LOADING });

export const clearBranchOffices = () => ({ type: branchOfficeTypes.CLEAR_BRANCH_OFFICES });

export const updateBranchOffice = (branchOffice) => ({
  type: branchOfficeTypes.UPDATE_BRANCH_OFFICE,
  branchOffice,
});

export const fetchBranchOffice = (branchOfficeId) => (dispatch) => {
  dispatch(fetchBranchOfficeLoading());

  return axios.get(`${baseUrl}/branch-offices/${branchOfficeId}`, authenticationHeader)
    .then(({ data }) => {
      dispatch(fetchBranchOfficeSuccess(data));
    }, (error) => {
      dispatch(fetchBranchOfficeFailed(error));
      dispatch(addErrorMessage('There was a problem loading the branch office'));
    });
};
