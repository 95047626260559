import React from 'react';
import './LoadingControls.scss';
import { useTranslation } from 'react-i18next';

const LoadingControls = ({
  loading = false,
}) => {
  const { t } = useTranslation('annotationView');

  return (
    <div className="ox-button-group ox-button-group--loading right">
      <button
        type="button"
        className="ox-button ox-button--secondary"
        disabled
      >
        <span className="ox-icon ox-icon--pause" />
        {t('controls.cancel')}
      </button>
      <button
        type="button"
        className="ox-button ox-button--secondary"
        disabled
      >
        <span className="ox-icon ox-icon--archive" />
        {t('controls.hold')}
      </button>
      <button
        type="button"
        className="ox-button ox-button--primary"
        disabled
      >
        <span className="ox-icon ox-icon--check" />
        {t('controls.archive')}
      </button>
      <button
        type="button"
        className="ox-button ox-button--primary ox-button--loading"
        disabled
      >
        {
          loading ? (
            <img
              src="https://ox.qone.mateco.eu/assets/img/loader.svg"
              alt="Loading..."
              className="ox-loader ox-loader-button"
              aria-hidden="true"
            />
          ) : (
            <span className="ox-icon ox-icon--check" />
          )
        }
        {t('controls.approve')}
      </button>
    </div>
  );
};

export default LoadingControls;
