import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateSubtitle } from '../../actions/appState/appStateActions';
import LanguageSelector from './languageSelector/LanguageSelector';
import Avatar from '../avatar/Avatar';
import './Settings.scss';
import useLocalStorage from '../../hooks/useLocalStorage';

const Settings = () => {
  const { t, i18n } = useTranslation('settings');
  const dispatch = useDispatch();
  const { storedValue: userObject } = useLocalStorage('active-user');
  const { sub: username } = JSON.parse(atob(userObject['api-key'].split('.')[1]));

  useEffect(() => {
    dispatch(updateSubtitle(t('title')));
  }, [i18n.language]);

  return (
    <div className="settings-container-wrapper">
      <div className="settings-container">
        <div className="sidebar">
          <div className="sidebar-nav">
            <div className="sidebar-nav-header">
              <Avatar
                name={username}
                disabledTooltip
              />
              <div className="sidebar-nav__user-info">
                <label className="user-info__name">{username}</label>
                <label className="user-info__title">{t('title')}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="settings">
          <div className="settings-header-wrapper">
            <h4 className="settings-header__title">{t('language')}</h4>
            <hr className="settings-header__line" />
          </div>
          <div className="settings-items-wrapper">
            <div className="settings-item-wrapper">
              <label className="settings-item__label">{t('languageLabel')}</label>
              <LanguageSelector />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
