import React, { useMemo } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useLanguage } from '../../../hooks/useLanguage';
import { LANGUAGES } from '../../../constants/constants';
import 'react-flags-select/scss/react-flags-select.scss';
import './LanguageSelector.scss';
import { getIdOfUser } from '../../../helpers/accountHelpers';
import useLocalStorage from '../../../hooks/useLocalStorage';

const mapping = LANGUAGES.reduce((map, language) => {
  // eslint-disable-next-line no-param-reassign
  map[language.flag] = language.code;
  return map;
}, {});

const LanguageSelector = () => {
  const { changeLanguage, selectedLanguage } = useLanguage();
  const { getValue: getActiveUser } = useLocalStorage('active-user');

  const renderLanguageSelector = useMemo(() => (
    <ReactFlagsSelect
      defaultCountry={LANGUAGES.find((language) => language.code === (selectedLanguage || 'en')).flag}
      countries={LANGUAGES.map((language) => language.flag)}
      customLabels={
          LANGUAGES.reduce((map, language) => {
            // eslint-disable-next-line no-param-reassign
            map[language.flag] = language.label;
            return map;
          }, {})
        }
      selectedSize={16}
      optionsSize={16}
      onSelect={(countryCode) => changeLanguage(getIdOfUser(getActiveUser()), mapping[countryCode])}
    />
  ), [selectedLanguage]);

  return (
    <div className="language-selector-container">
      {renderLanguageSelector}
    </div>
  );
};

export default LanguageSelector;
