import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as userActionsFile from '../../actions/user/userActions';
import * as appStateActionsFile from '../../actions/appState/appStateActions';
import useLocalStorage from '../../hooks/useLocalStorage';
import './Login.scss';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActionsFile, dispatch),
  appStateActions: bindActionCreators(appStateActionsFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  userReducer: {
    loading,
  },
  userActions,
  appStateActions,
}) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const {
    getValue: getActiveUser,
  } = useLocalStorage('active-user');
  const { t, i18n } = useTranslation('login');

  useEffect(() => {
    appStateActions.updateSubtitle('Login');
  }, []);

  return (
    <div className="login card">
      <h2>Log in</h2>
      {
          getActiveUser()
            ? <Redirect to={{ pathname: '/overview' }} />
            : (
              <form>
                <input
                  className="input ox-form__field"
                  type="text"
                  name="name"
                  placeholder="Username"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <input
                  className="input ox-form__field"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <button
                  className="submit-button ox-button ox-button--primary"
                  onClick={(event) => {
                    event.preventDefault();
                    userActions.loginUser(name, password, i18n);
                  }}
                  disabled={loading}
                  type="submit"
                >
                  <i className="fa fa-sign-in-alt" /> Log in
                </button>
              </form>
            )
        }
    </div>
  );
});
