import React from 'react';
import './ErrorBoundary.scss';

// TODO: refactor as soon as there is a hook for getDerivedStateFromError
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      // error: undefined,
      // errorInfo: undefined,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(/* error, errorInfo */) {
    // You can also log the error to an error reporting service
    // this.setState({ error, errorInfo });
  }

  handleBackToOverview = () => {
    const { history } = this.props;

    history.push('/');
    history.go();
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-card">
          <span className="error-icon ox-icon ox-icon--error" />
          <h1>Oops ( ° ʖ̯°)</h1>
          <p>Something went wrong</p>
          <button
            type="button"
            onClick={this.handleBackToOverview}
            className="ox-button ox-button--secondary"
          >
            Back to overview
          </button>
        </div>
      );
    }

    return children;
  }
}
