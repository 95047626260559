import React from 'react';
import './Preloader.scss';

export default ({ small }) => (
  <div className={`lds-ring ${small ? 'small' : ''}`}>
    <div className="ring-one" />
    <div className="ring-two" />
    <div className="ring-three" />
    <div className="ring-four" />
  </div>
);
