import React from 'react';

const SiteIndicator = ({ color }) => {
  const style = { borderColor: `transparent ${color} transparent transparent` };

  return (
    <div style={style} className="site-indicator" />
  );
};

export default SiteIndicator;
