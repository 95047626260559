import * as extraOptionTypes from '../actions/extraOptions/extraOptionsTypes';

const initialState = {
  loading: false,
  error: undefined,
  options: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case extraOptionTypes.FETCH_EXTRA_OPTIONS:
      return { ...state, loading: true };
    case extraOptionTypes.FETCH_EXTRA_OPTIONS_SUCCESS: {
      const { data, t } = action;

      const options = data.map((item) => ({
        ...item,
        subcategory: t(`extraOptions.${item.machine_code}`),
      }));

      return {
        ...state,
        options,
        loading: false,
      };
    }
    case extraOptionTypes.FETCH_EXTRA_OPTIONS_FAILED: {
      const { error } = action;

      return { ...state, loading: false, error };
    }

    default:
      return state;
  }
};
