import * as documentTypes from '../actions/document/documentTypes';

const initialState = {
  loading: false,
  documentTypes: [],
  selectedDocumentTypeId: null,
  fetchDocumentTypesError: null,
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentTypes.FETCH_DOCUMENT_TYPES:
      return {
        ...state,
        loading: true,
      };
    case documentTypes.FETCH_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentTypes: action.data,
        loading: false,
      };
    case documentTypes.FETCH_DOCUMENT_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        fetchDocumentTypesError: action.error,
      };

    case documentTypes.CHANGE_SELECTED_DOCUMENT_TYPE:
      if (state.selectedDocumentTypeId === action.id) {
        return { ...state, selectedDocumentTypeId: null };
      }
      return { ...state, selectedDocumentTypeId: action.id };

    default:
      return state;
  }
};

export { documentReducer as default };
