const getAccessibleTextColor = (backgroundColorHex) => {
  function cutHex(hex) {
    return (hex.charAt(0) === '#') ? hex.substring(1, 7) : hex;
  }
  function hexToR(hex) {
    return parseInt((cutHex(hex)).substring(0, 2), 16);
  }

  function hexToG(hex) {
    return parseInt((cutHex(hex)).substring(2, 4), 16);
  }

  function hexToB(hex) {
    return parseInt((cutHex(hex)).substring(4, 6), 16);
  }

  const threshold = 148;
  const hRed = hexToR(backgroundColorHex);
  const hGreen = hexToG(backgroundColorHex);
  const hBlue = hexToB(backgroundColorHex);

  const colorBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
  let textColor = '#000000';

  if (colorBrightness < threshold) {
    textColor = '#FFFFFF';
  }

  return textColor;
};

export const findUnusedHotKey = (hotkeys) => {
  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const hotkey = alphabet.filter((letter) => !hotkeys.includes(letter))[0];
  return hotkey != null ? hotkey : '';
};

export const normalizeOrderValue = (value) => {
  const normalizedValue = value;
  Object.keys(value).forEach((key) => {
    value[key].forEach((item, index) => {
      if (typeof item === 'string') {
        normalizedValue[key][index] = item.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()).split('_').join(' ');
      }
    });
  });
  return normalizedValue;
};

export { getAccessibleTextColor as default };
