import * as feedbackTypes from '../actions/feedback/feedbackTypes';
import { generateTimestampHash } from '../helpers/hashHelpers';

const initialState = {
  messages: [],
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case feedbackTypes.ADD_SUCCESS_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages, {
            id: generateTimestampHash(action.message),
            message: action.message,
            type: 'SUCCESS',
            autoRemove: action.autoRemove,
          },
        ],
      };
    case feedbackTypes.ADD_ERROR_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages, {
            id: generateTimestampHash(action.message),
            message: action.message,
            type: 'ERROR',
            autoRemove: action.autoRemove,
          },
        ],
      };
    case feedbackTypes.ADD_WARNING_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages, {
            id: generateTimestampHash(action.message),
            message: action.message,
            type: 'WARNING',
            autoRemove: action.autoRemove,
          },
        ],
      };
    case feedbackTypes.ADD_INFO_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages, {
            id: generateTimestampHash(action.message),
            message: action.message,
            type: 'INFO',
            autoRemove: action.autoRemove,
          },
        ],
      };
    case feedbackTypes.ADD_DEBUG_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages, {
            id: generateTimestampHash(action.message),
            message: action.message,
            type: 'DEBUG',
            autoRemove: action.autoRemove,
          },
        ],
      };
    case feedbackTypes.REMOVE_MESSAGE: {
      const newMessages = [...state.messages].filter((message) => message.id !== action.index);
      return { ...state, messages: newMessages };
    }
    default:
      return state;
  }
};

export { feedbackReducer as default };
