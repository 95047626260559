import moment from 'moment';
import { parseDateString, parseTimeString, calculateFutureDate } from '../services/parser';

const handleError = (returnValue, errorTextMessage) => ({
  returnValue,
  error: true,
  errorTextMessage,
});

export const formatDate = (debouncedValue) => parseDateString(debouncedValue[0])
  .then((formattedDate) => {
    const dateValue = formattedDate;
    let returnValue = [debouncedValue[0]];
    let error = false;
    let errorTextMessage = '';

    if (dateValue) {
      returnValue = [moment(dateValue).format('DD/MM/YYYY')];
    } else {
      error = true;
      errorTextMessage = 'Invalid date format.';
    }
    return {
      returnValue,
      error,
      errorTextMessage,
    };
  }).catch(() => handleError([debouncedValue[0]], 'Invalid date format.'));

export const formatHour = (debouncedValue) => parseTimeString(debouncedValue[0])
  .then((formattedHour) => {
    const hourValue = formattedHour;
    let returnValue = [debouncedValue[0]];
    let error = false;
    let errorTextMessage = '';

    if (hourValue) {
      // Slice is used to cut of the seconds of the timestamp. 00:00:00 -> 00:00
      returnValue = [hourValue.slice(0, -3)];
    } else {
      error = true;
      errorTextMessage = 'Invalid time format.';
    }
    return {
      returnValue,
      error,
      errorTextMessage,
    };
  }).catch(() => handleError([debouncedValue[0]], 'Invalid time format.'));

export const calculateDate = (debouncedValue, amount, amountType) => calculateFutureDate(debouncedValue[0], amount, amountType)
  .then((calculatedDate) => {
    const date = calculatedDate;

    let returnValue = [debouncedValue[0]];
    let error = false;
    let errorTextMessage = '';

    if (calculatedDate) {
      returnValue = [moment(date).format('DD/MM/YYYY')];
    } else {
      error = true;
      errorTextMessage = 'Invalid date format.';
    }
    return {
      returnValue,
      error,
      errorTextMessage,
    };
  }).catch(() => handleError([debouncedValue[0]], 'Invalid date format.'));

export const isFutureDate = (dateToCheck) => {
  const today = new Date().getTime();
  const parsedDate = dateToCheck.split('/');
  return (today - new Date(parsedDate[2], parsedDate[1] - 1, parsedDate[0]).getTime()) < 0;
};

export const formatDateTime = (timestamp, format = 'DD/MM/YYYY HH:MM:ss') => {
  if (timestamp) {
    const date = moment(timestamp);
    return date.format(format);
  }
  return 'N/A';
};
