import axios from 'axios';
import * as metricsReportTypes from './metricsReportTypes';
import { authenticationHeader, baseUrl } from '../../constants/constants';

const fetchMetricsReportLoading = () => ({ type: metricsReportTypes.FETCH_METRICS_REPORTS_MONTH });
const fetchMetricsReportSuccess = (data) => ({ type: metricsReportTypes.FETCH_METRICS_REPORTS_MONTH_SUCCESS, data });
const fetchMetricsReportFailed = (error) => ({ type: metricsReportTypes.FETCH_METRICS_REPORTS_MONTH_FAILED, error });

export const fetchMetricsReport = () => (dispatch) => {
  dispatch(fetchMetricsReportLoading());

  return axios.get(`${baseUrl}/metrics/mails`, authenticationHeader)
    .then((response) => {
      dispatch(fetchMetricsReportSuccess(response.data));
    }, (error) => {
      dispatch(fetchMetricsReportFailed(error));
    });
};

export const addToMetrics = (userId, mailId, userAction) => () => {
  const requestBody = {
    documentId: mailId,
    userAction,
    userId,
  };
  return axios.post(`${baseUrl}/audit-trail`, requestBody, authenticationHeader);
};
