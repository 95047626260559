import React from 'react';
import './Dropdown.scss';
import { useTranslation } from 'react-i18next';

export default ({
  label,
  selectedValue,
  options = [],
  isSmall = true,
  changeSelection,
  disabled = false,
  hasChanged,
}) => {
  const { t } = useTranslation('annotationView');
  const handleSelectionChange = (event) => {
    if (!disabled) {
      changeSelection(event.target.value || options[0]);
      hasChanged(true);
    }
  };

  return (
    <div className="ox-form__group">
      <label htmlFor="selection" className="ox-form__label">
        {t(`entities.${label || ''}`).replace('entities.', '')}
      </label>
      <select
        id="selection"
        className={`ox-form__field ${isSmall ? 'ox-form__field--small' : ''} ${disabled ? 'disabled' : ''}`}
        onChange={handleSelectionChange}
        disabled={disabled}
        value={selectedValue}
      >
        {options.map((option, index) => {
          const { value, label: optionLabel } = option;
          return (
            <option
              // eslint-disable-next-line react/no-array-index-key
              key={`dropdown-option-${index}`}
              value={optionLabel}
            >
              {t(`entityOptions.${label}.${optionLabel}`) || value}
            </option>
          );
        })}
      </select>
    </div>
  );
};
