export const FETCH_ALL_MAILS = 'FETCH_ALL_MAILS';
export const FETCH_ALL_MAILS_SUCCESS = 'FETCH_ALL_MAILS_SUCCESS';
export const FETCH_ALL_MAILS_FAILED = 'FETCH_ALL_MAILS_FAILED';

export const PROCESS_MAILS_DONE = 'PROCESS_MAILS_DONE';
export const PROCESS_MAILS_LOADING = 'PROCESS_MAILS_LOADING';

export const SET_MAIL_COUNT = 'SET_MAIL_COUNT';
export const EXPAND_MAILS_LIST = 'EXPAND_MAILS_LIST';
export const RESET_MAILS_LIST = 'RESET_MAILS_LIST';
export const FETCHED_ALL_ARCHIVED_MAILS = 'FETCHED_ALL_ARCHIVED_MAILS';
export const FETCHED_ALL_OVERVIEW_MAILS = 'FETCHED_ALL_OVERVIEW_MAILS';

export const FETCH_MAIL_BY_ID_SUCCESS = 'FETCH_MAIL_BY_ID_SUCCESS';
export const FETCH_MAIL_BY_ID_FAILED = 'FETCH_MAIL_BY_ID_FAILED';
export const FETCH_MAIL_BY_ID = 'FETCH_MAIL_BY_ID';

export const SET_MAIL_STATUS_LOADING = 'SET_MAIL_STATUS_LOADING';
export const SET_MAIL_STATUS_SUCCESS = 'SET_MAIL_STATUS_SUCCESS';
export const SET_MAIL_STATUS_FAILED = 'SET_MAIL_STATUS_FAILED';

export const CHANGE_ATTACHMENT_TYPE = 'CHANGE_ATTACHMENT_TYPE';

export const SET_MAIL_ACTIVE_DOCUMENT = 'SET_MAIL_ACTIVE_DOCUMENT';

export const SEND_MAIL_BACK_TO_O365 = 'SEND_MAIL_BACK_TO_O365';
export const SEND_MAIL_BACK_TO_O365_SUCCESS = 'SEND_MAIL_BACK_TO_O365_SUCCESS';
export const SEND_MAIL_BACK_TO_O365_FAILED = 'SEND_MAIL_BACK_TO_O365_FAILED';

export const SET_MAIL_STATUS_TODO = 'SET_MAIL_STATUS_TODO';
export const SET_MAIL_STATUS_TODO_SUCCESS = 'SET_MAIL_STATUS_TODO_SUCCESS';
export const SET_MAIL_STATUS_TODO_FAILED = 'SET_MAIL_STATUS_TODO_FAILED';

export const SEARCH_ALL_MAILS = 'SEARCH_ALL_MAILS';
export const SEARCH_ALL_MAILS_SUCCESS = 'SEARCH_ALL_MAILS_SUCCESS';
export const SEARCH_ALL_MAILS_FAILED = 'SEARCH_ALL_MAILS_FAILED';

export const CLEAR_FETCHED_MAIL = 'CLEAR_FETCHED_MAIL';
export const NO_MORE_ARCHIVED_MAILS = 'NO_MORE_ARCHIVED_MAILS';

export const UPDATE_MAIL_OVERVIEW = 'UPDATE_MAIL_OVERVIEW';
export const CLEAR_OVERVIEW_BY_BRANCH_ID = 'CLEAR_OVERVIEW_BY_BRANCH_ID';
