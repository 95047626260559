import axios from 'axios';
import { baseUrl } from '../constants/constants';
// const baseUrl = 'http://localhost:8000/api/v1'; // Test url for local development

/**
 * Parses a string value to a date IsoString.
 * @param value: string: Value that is parsed to a datetime string.
 */
export const parseDateString = (value) => {
  const url = `${baseUrl}/parser/date-parse?date=${value}`;
  return axios.get(url).then((parsedDate) => parsedDate.data).catch((error) => {
    console.error(`[Parser] - Failed to parse the value: ${value} into a ISO string.`, error);
  });
};

/**
 * Parses a string value to a timestring.
 * @param value: string: Value that is parsed to a time string.
 */
export const parseTimeString = (value) => {
  const url = `${baseUrl}/parser/time-parse?time=${value}`;
  return axios.get(url).then((parsedDate) => parsedDate.data).catch((error) => {
    console.error(`[Parser] - Failed to parse the value: ${value} into a ISO string.`, error);
  });
};

/**
 * Calculates a string value to a date and calculates the future date.
 * @param value: string: Value that is parsed to a time string.
 * @param duration: string: Value that should be brought into account (9 Days/ 7 Weeks).
 * @param type: The duration type, can be WEEKDAYS, WEEKDAYS_INCL_SATURDAY or ALL_DAYS
 */
export const calculateFutureDate = (value, duration = '1 day', type = 'WEEKDAYS') => {
  const url = `${baseUrl}/parser/date-calculate?start-date=${value}&duration=${duration}&duration-type=${type}`;
  return axios.get(url).then((parsedDate) => parsedDate.data).catch((error) => {
    console.error(`[Parser] - Failed to parse the value: ${value} into a ISO string.`, error);
  });
};
