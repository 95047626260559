import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../loader/Loader';

const canUnassignMail = (mail) => mail && mail.status && mail.status.name && mail.status.name.toLowerCase() !== 'to do';

const TableDropdownMenu = ({
  handleSendBack,
  disabled,
  mail = {},
  isLoading,
  handleRemoveStatus,
}) => {
  const { t } = useTranslation('overview');

  const [opened, toggleOpened] = useState(false);
  const dropdownId = `toggle-dropdown-menu-${mail.id}`;
  return (
    <div className="ox-dropmenu-wrapper" id="dox-drop-menu-example-2" aria-haspopup="true" aria-expanded="false">
      <input
        type="checkbox"
        className="ox-dropmenu-toggle"
        id={dropdownId}
        checked={opened}
        onChange={() => toggleOpened(!opened)}
      />
      <label
        htmlFor={dropdownId}
        aria-label="Toggle dropmenu"
      >
        <span className="fa fa-ellipsis-v drop-menu-button" />
      </label>
      <div className="ox-dropmenu drop-menu">
        <ul
          className="ox-dropmenu__list"
          tabIndex="-1"
          role="menu"
          aria-label="Overflow"
          data-floating-menu-direction="bottom"
        >
          <li
            className="ox-dropmenu__option ox-dropmenu__option--error"
            role="menuitem"
            aria-disabled={disabled}
          >
            {isLoading ? <Loader loading={isLoading} label="Sending back the email.." />
              : (
                <button
                  type="button"
                  className="ox-dropmenu__option__button"
                  role="menuitem"
                  disabled={disabled}
                  onClick={() => {
                    if (!disabled) {
                      handleSendBack();
                      toggleOpened(false);
                    }
                  }}
                >
                  <span className="ox-dropmenu__option__icon ox-icon ox-icon--mail " />
                  {t('actions.sendBack')}
                </button>
              )}
          </li>
          {canUnassignMail(mail) ? (
            <li
              className="ox-dropmenu__option ox-dropmenu__option--separator"
              role="presentation"
            >
              <button
                type="button"
                className="ox-dropmenu__option__button"
                role="menuitem"
                onClick={() => {
                  handleRemoveStatus();
                  toggleOpened(false);
                }}
              >
                <span className="ox-dropmenu__option__icon ox-icon ox-icon--cancel" />
                {t('actions.unassign')}
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default TableDropdownMenu;
