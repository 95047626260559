import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import * as PdfJs from 'pdfjs-dist/build/pdf';

const Page = ({ pdf, index, scale = 1.5 }) => {
  const canvasRef = useRef(null);

  const [pdfViewport, setPdfViewport] = useState({ width: 0, height: 0 });
  const [pdfText, setPdfText] = useState({});

  const calculateTextProperties = (pageTransform, textItem, style) => {
    const tx = PdfJs.Util.transform(pageTransform, textItem.transform);
    let angle = Math.atan2(tx[1], tx[0]);
    if (style.vertical) {
      angle += Math.PI / 2;
    }
    const fontHeight = Math.sqrt(tx[2] * tx[2] + tx[3] * tx[3]);
    let fontAscent = fontHeight;
    if (style.ascent) {
      fontAscent *= style.ascent;
    } else if (style.descent) {
      fontAscent *= (1 + style.descent);
    }

    let left;
    let top;
    if (angle === 0) {
      // eslint-disable-next-line prefer-destructuring
      left = tx[4];
      top = tx[5] - fontAscent;
    } else {
      left = tx[4] + fontAscent * Math.sin(angle);
      top = tx[5] - fontAscent * Math.cos(angle);
    }

    let canvasWidth;
    let transform = 1;

    if (style.vertical) {
      canvasWidth = textItem.height * pdfViewport.scale;
    } else {
      canvasWidth = textItem.width * pdfViewport.scale;
    }

    if (canvasWidth) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.font = `${fontHeight}px ${style.fontFamily}`;

      const { width } = context.measureText(textItem.str);
      transform = canvasWidth / width;
    }

    return {
      left, top, fontHeight, transform,
    };
  };

  useEffect(() => {
    if (canvasRef) {
      pdf.getPage(index).then((page) => {
        page.getTextContent().then((text) => {
          setPdfText(text);
        });
        const viewport = page.getViewport({ scale });
        const { width, height } = viewport;
        setPdfViewport(viewport);
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        page.render({
          canvasContext: context,
          viewport,
        });
      });
    }
  }, [pdf, index, scale, canvasRef]);

  const renderText = useMemo(() => {
    if (canvasRef && pdfText.items && pdfText.items.length) {
      return pdfText.items.map((item) => {
        const style = pdfText.styles[item.fontName];
        const {
          left,
          top,
          fontHeight,
          transform,
        } = calculateTextProperties(pdfViewport.transform, item, style);

        return (
          <span
            className="page__text-layer_text-item"
            style={{
              left: `${left}px`,
              top: `${top}px`,
              fontSize: `${fontHeight}px`,
              fontFamily: `${style.fontFamily}`,
              transform: `scaleX(${transform})`,
            }}
            key={`${left}-${top}`}
          >
            {item.str}
          </span>
        );
      });
    }
    return null;
  }, [pdfText, pdfViewport, scale, canvasRef]);

  return (
    <div className="page-container">
      <div
        className="page-container__wrapper"
        style={{ width: `${pdfViewport.width}px`, height: `${pdfViewport.height}px` }}
      >
        <div
          className="page__canvas-container"
          style={{ width: `${pdfViewport.width}px`, height: `${pdfViewport.height}px` }}
        >
          <canvas ref={canvasRef} />
        </div>
        <div
          className="page__text-layer-container"
          style={{ width: `${pdfViewport.width}px`, height: `${pdfViewport.height}px` }}
        >
          {renderText}
        </div>
      </div>
    </div>
  );
};

export default Page;
