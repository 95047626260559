import { combineReducers } from 'redux';
import userReducer from './userReducer';
import uploadReducer from './uploadReducer';
import annotationReducer from './annotationReducer';
import entityReducer from './entityReducer';
import documentReducer from './documentReducer';
import feedbackReducer from './feedbackReducer';
import keyEventReducer from './keyEventReducer';
import mailReducer from './mailReducer';
import appStateReducer from './appStateReducer';
import branchOfficeReducer from './branchOfficeReducer';
import orderReducer from './orderReducer';
import customerReducer from './customerReducer';
import machineReducer from './machineReducer';
import configMapReducer from './configMapReducer';
import extraOptionsReducer from './extraOptionsReducer';
import metricsReportReducer from './metricsReportReducer';

const rootReducer = combineReducers({
  annotationReducer,
  appStateReducer,
  branchOfficeReducer,
  configMapReducer,
  customerReducer,
  documentReducer,
  entityReducer,
  extraOptionsReducer,
  feedbackReducer,
  keyEventReducer,
  machineReducer,
  mailReducer,
  metricsReportReducer,
  orderReducer,
  uploadReducer,
  userReducer,
});

export { rootReducer as default };
