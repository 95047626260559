import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../infoIcon/InfoIcon';

const GroupHeader = ({
  id,
  keyValue,
  groupName,
  deleteAction,
  configMap,
  showExtraOption,
  extraAction,
  count,
}) => {
  const { t } = useTranslation('annotationView');

  return (
    <div className="group-header-wrapper">
      <p className="group-title">{`${t(`groupHeaders.${id}`)} ${keyValue}`}</p>
      <hr className="fill-line" />
      {showExtraOption && extraAction ? (
        <InfoIcon
          className="ox-icon ox-icon--settings machine-settings-icon"
          onClick={() => extraAction(true, keyValue || 1)}
          count={count}
        />
      ) : null}
      {deleteAction ? (
        <span
          aria-label="Delete action"
          className="fa fa-trash-alt remove-machine-icon"
          role="button"
          onClick={() => deleteAction(configMap, keyValue, groupName)}
        />
      ) : null}
    </div>
  );
};

export default GroupHeader;
