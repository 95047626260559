import React from 'react';
import { getInitialsFromName } from '../../helpers/accountHelpers';
import './Avatar.scss';

export default ({ name, disabledTooltip }) => {
  if (disabledTooltip) {
    return (
      <div className="user-avatar">
        {getInitialsFromName(name || '')}
      </div>
    );
  }

  return (
    <>
      {
        name ? (
          <div className="user-avatar ox-has-tooltip" aria-label={name} data-tooltip={name}>
            {getInitialsFromName(name || '')}
          </div>
        ) : (
          <div className="user-avatar-empty">
            <span className="fa fa-user" />
          </div>
        )
      }
    </>
  );
};
