import axios from 'axios';
import moment from 'moment';
import { authenticationHeader, baseUrl } from '../constants/constants';

export const exportAuditTrailToCsv = () => axios.get(`${baseUrl}/metrics/export/audit-trail`, authenticationHeader)
  .then((response) => {
    const csvContent = `data:text/csv;charset=utf-8,id,elapsed_time_sec,updated_timestamp\n${
      response.data.map((e) => `${e.id},${e.elapsedTimeSec},${e.updatedTimestamp}`).join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `audit_trail_${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  })
  .catch((error) => {
    console.error(error);
    return false;
  });
