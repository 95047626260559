import React from 'react';
import './ProgressLabel.scss';

const iconMapping = {
  1: 'fa fa-user-edit',
  2: 'fa fa-user-clock',
  3: 'fa fa-user-lock',
  4: 'fa fa-user-check',
};

const ProgressLabel = ({ statusId, status, processed = null }) => (
  <div className="progress-label">
    <span className={iconMapping[statusId]} />
    <label className="status-info">{processed || status}</label>
  </div>
);

export default ProgressLabel;
