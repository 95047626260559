import { useState } from 'react';

export const useSearch = (searchActions, alwaysShowResults) => {
  const [suggestions, setSuggestions] = useState([]);

  const search = (searchValue) => {
    if (((searchValue && searchValue.length >= 2) || alwaysShowResults)) {
      searchActions.search(searchValue).then((result) => {
        setSuggestions(result.map((item) => ({
          optionTwo: item.vat_number,
          optionThree: item.customer_no,
          optionOne: item.customer_name,
        })));
      });
    } else {
      setSuggestions([]);
    }
  };

  return {
    search,
    suggestions,
  };
};
