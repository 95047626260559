import React, { useMemo, useState, useEffect } from 'react';
import Dropdown from '../../dropdown/Dropdown';
import InputField from './InputField';
import TypeaheadField from './TypeaheadField';
import CheckBox from './CheckBox';
import DateTimeField from './DateTimeField';
import MultiCheckBox from './MultiCheckBox';
import MeasureField from './MeasureField';
import { COMMENT_FIELD_ENTITY_IDS } from '../../../constants/constants';

const alwaysShowResults = (name) => {
  const listOfNames = ['options', 'attachments', 'extra'];
  return name && listOfNames.includes(name.toLowerCase());
};

const EntityComponent = ({
  value,
  entity,
  name,
  index: indexToAdjust,
  disabled,
  removeEnabled,
  metroStyle,
  isRowHovered,
  isTextArea,
  hasMultipleAnnotations,
  required,
  returnOption,
  extraInfo,
  initialValue,
  options,
  handleChange,
  handleRemoveClicked,
  updateStore,
  autoFormatValues,
  form,
  changeIsEditing,
}) => {
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (hasChanged) {
      updateStore();
      setHasChanged(false);
    }
  }, [hasChanged]);

  const checkBox = useMemo(() => (
    value.map((item, index) => (
      <CheckBox
        key={`entity-checkbox-${entity.id}`}
        label={entity.displayName}
        value={item}
        disabled={disabled}
        onChange={(changedValue) => handleChange(changedValue, index)}
        hasChanged={setHasChanged}
      />
    ))
  ), [value, disabled, entity]);

  const multiCheckBox = useMemo(() => (
    <MultiCheckBox
      disabled={disabled}
      value={value}
      entityName={entity.name}
      options={entity.dropDownOptions}
      onChange={handleChange}
      hasChanged={setHasChanged}
    />
  ), [value, disabled, entity]);

  const measureField = useMemo(() => (
    <MeasureField
      name={name}
      disabled={disabled}
      onRemove={() => handleRemoveClicked(0, indexToAdjust)}
      value={value}
      required={required}
      onChange={(changedValue) => handleChange(changedValue, 0)}
      updateStore={updateStore}
      extraInfo={extraInfo}
      entity={entity}
      form={form}
      autoFormatValues={autoFormatValues}
    />
  ), [initialValue, value, disabled, options, entity]);

  const dateTimeField = useMemo(() => (
    value.map((item, index) => (
      <DateTimeField
        key={`entity-dateTimeField-${entity.id}`}
        label={entity.name}
        value={item}
        index={index}
        disabled={disabled}
        updateStore={updateStore}
        removeEnabled={removeEnabled}
        entity={entity}
        form={form}
        autoFormatValues={autoFormatValues}
        onChange={(changedValue) => handleChange(changedValue, index)}
        changeIsEditing={changeIsEditing}
      />
    ))
  ), [value, disabled, entity]);

  const dropDown = useMemo(() => (
    <Dropdown
      key={`entity-dropdown-${entity.id}`}
      selectedValue={(value && value[0]) ? value[0] : ''}
      label={entity.name}
      options={entity.dropDownOptions}
      isSmall
      disabled={disabled}
      changeSelection={(changedValue) => handleChange(changedValue, 0)}
      autoFormatValues={autoFormatValues}
      hasChanged={setHasChanged}
    />
  ), [value, disabled, metroStyle, isRowHovered, entity]);

  const inputField = useMemo(() => (
    value.map((item, index) => (
      <InputField
        key={`entity-input-${entity.id + index}-${entity.entityId}`}
        name={COMMENT_FIELD_ENTITY_IDS.includes(entity.entityId) ? `#${index + 1}` : name}
        disabled={disabled}
        removeEnabled={removeEnabled}
        isTextArea={isTextArea}
        metroStyle={metroStyle}
        onRemove={() => handleRemoveClicked(index, indexToAdjust)}
        value={item}
        showName={index === 0 || COMMENT_FIELD_ENTITY_IDS.includes(entity.entityId)}
        hasMultipleAnnotations={hasMultipleAnnotations}
        required={required}
        onChange={(changedValue) => handleChange(changedValue, index)}
        updateStore={updateStore}
        returnOption={returnOption}
        extraInfo={extraInfo}
        entity={entity}
        form={form}
        isLast={index === value.length - 1}
        autoFormatValues={autoFormatValues}
        showRemoveButton={index > 0 && COMMENT_FIELD_ENTITY_IDS.includes(entity.entityId)}
        changeIsEditing={changeIsEditing}
      />
    ))
  ), [initialValue, value, disabled, removeEnabled, options, isTextArea, metroStyle, entity, hasMultipleAnnotations, indexToAdjust]);

  const typeAhead = useMemo(() => (
    value.map((item, index) => (
      <TypeaheadField
        key={`entity-typeahead-${entity.id + index}-${entity.entityId}`}
        name={name}
        disabled={disabled}
        removeEnabled={removeEnabled}
        options={options}
        metroStyle={metroStyle}
        onRemove={() => handleRemoveClicked(index, indexToAdjust)}
        value={item}
        showName={index === 0}
        hasMultipleAnnotations={hasMultipleAnnotations}
        required={entity.isRequired || required}
        onChange={(changedValue) => handleChange(changedValue, index)}
        updateStore={updateStore}
        returnOption={returnOption}
        extraInfo={extraInfo}
        alwaysShowResults={alwaysShowResults(name)}
        isLast={index === value.length - 1}
        autoFormatValues={autoFormatValues}
        entity={entity}
        changeIsEditing={changeIsEditing}
        index={index}
      />
    ))
  ), [initialValue, value, disabled, removeEnabled, options, metroStyle, entity, hasMultipleAnnotations, indexToAdjust]);

  switch (entity.componentType) {
    case 'dropDown':
      return dropDown;
    case 'inputField':
      return inputField;
    case 'typeAhead':
      return typeAhead;
    case 'checkBox':
      return checkBox;
    case 'dateTimeField':
      return dateTimeField;
    case 'multiCheckBox':
      return multiCheckBox;
    case 'measureField':
      return measureField;
    default:
      return inputField;
  }
};

export default EntityComponent;
