import axios from 'axios';
import * as orderTypes from './orderTypes';
import {
  baseUrl,
  authenticationHeader,
  OPTIONS_ENTITY_ID,
  ATTACHMENTS_ENTITY_ID,
} from '../../constants/constants';
import { addErrorMessage } from '../feedback/feedbackActions';
import { mapDescriptionToCode } from '../../helpers/codeMapperHelpers';

const holdOrdersLoading = () => ({ type: orderTypes.HOLD_ORDER });
const holdOrdersSuccess = () => ({ type: orderTypes.HOLD_ORDER_SUCCESS });
const holdOrdersFailed = () => ({ type: orderTypes.HOLD_ORDER_FAILED });

const approveOrdersLoading = () => ({ type: orderTypes.APPROVE_ORDER });
const approveOrdersSuccess = () => ({ type: orderTypes.APPROVE_ORDER_SUCCESS });
const approveOrdersFailed = () => ({ type: orderTypes.APPROVE_ORDER_FAILED });

const getUpdatedOrder = (order, mailId) => order.map((orderItem) => {
  if (orderItem.entityId === OPTIONS_ENTITY_ID || orderItem.entityId === ATTACHMENTS_ENTITY_ID) {
    const fixedValues = mapDescriptionToCode(orderItem);

    return {
      ...orderItem,
      value: fixedValues,
      mailId,
    };
  }
  return {
    ...orderItem,
    mailId,
  };
});

export const fetchOrderSuccess = (data) => ({ type: orderTypes.FETCH_ORDER_SUCCESS, data });

export const clearOrder = () => ({ type: orderTypes.CLEAR_ORDER });

export const changeVariable = ({
  orderEntityId,
  indexToAdjust,
  value,
  shouldFocusInput,
}) => ({
  type: orderTypes.CHANGE_VARIABLE,
  orderEntityId,
  indexToAdjust,
  value,
  shouldFocusInput,
});

export const clearVariable = (entityID, orderIndex = '1', index = -1) => ({
  type: orderTypes.CLEAR_VARIABLE,
  entityId: entityID,
  orderIndex,
  index,
});

export const setFormDirty = (value) => ({
  type: orderTypes.DIRTY_FORM,
  value,
});

export const addGroupToOrder = (configMap, groupName) => ({ type: orderTypes.ADD_GROUP_TO_ORDER, configMap, groupName });
export const removeGroupFromOrder = (configMap, indexToRemove, groupName) => (
  {
    type: orderTypes.REMOVE_GROUP_FROM_ORDER, configMap, indexToRemove, groupName,
  }
);

export const changeActiveTabIndex = (index) => ({ type: orderTypes.CHANGE_ACTIVE_TAB_INDEX, index });

export const holdOrder = (activeOrder, mailId) => (dispatch) => {
  dispatch(holdOrdersLoading());

  const orderUpdated = getUpdatedOrder(activeOrder, mailId);

  const promise = axios.put(`${baseUrl}/order-value/${mailId}/hold`, orderUpdated, authenticationHeader);
  promise.then(() => {
    dispatch(holdOrdersSuccess());
  }, (error) => {
    if (error.response && error.response.status && error.response.status === 406) {
      dispatch(addErrorMessage(error.response.data.message));
    } else {
      dispatch(addErrorMessage(error.message));
    }
    dispatch(holdOrdersFailed());
    return error;
  });

  return promise;
};

export const approveOrder = (activeOrder, mailId, sendOrderConfirmation) => (dispatch) => {
  dispatch(approveOrdersLoading());

  const orderUpdated = getUpdatedOrder(activeOrder, mailId);
  const data = {
    orderValues: orderUpdated,
    sendOrderConfirmation,
  };

  const promise = axios.put(`${baseUrl}/order-value/${mailId}/approve`, data, authenticationHeader);
  promise.then(() => {
    dispatch(approveOrdersSuccess());
  }, (error) => {
    if (error.response && error.response.status && error.response.status === 406) {
      dispatch(addErrorMessage(error.response.data.message));
    } else {
      dispatch(addErrorMessage(error.message));
    }
    dispatch(approveOrdersFailed());
    return error;
  });

  return promise;
};
