import axios from 'axios';
import { authenticationHeader, baseUrl } from '../constants/constants';

export const queryPossibleMachine = (machineModel) => axios
  .get(`${baseUrl}/machines/machine-code?model=${machineModel}`, authenticationHeader)
  .then((response) => {
    const foundMachine = response.data;
    if (!foundMachine) {
      return null;
    }
    return foundMachine.machkode;
  }).catch((error) => {
    console.error('[Failed to query the machine code]', error);
    return null;
  });
