export default function generateHash(data) {
  let hash = 0;
  let chr;

  if (data.length === 0) {
    return hash;
  }

  for (let i = 0; i < data.length; i += 1) {
    chr = data.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr; // eslint-disable-line no-bitwise
    hash |= 0; // eslint-disable-line no-bitwise
  }

  return Math.abs(hash);
}

export function generateTimestampHash(data) {
  const time = new Date().getTime();
  const dataWithTime = `${data}-${time}`;

  const hash = generateHash(dataWithTime);
  return hash;
}

export function generateRandomColor() {
  const c = (generateTimestampHash() & 0x00FFFFFF) // eslint-disable-line no-bitwise
    .toString(16)
    .toUpperCase();

  const color = `#${'00000'.substring(0, 6 - c.length) + c}`;

  return color;
}
