import { getApiKeyFromActiveUser } from '../helpers/accountHelpers';

export const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const authenticationHeader = {
  headers: {
    Authorization: `Bearer ${getApiKeyFromActiveUser()}`,
  },
};

export const patchAuthenticationHeader = {
  headers: {
    ...authenticationHeader.headers,
    'Content-Type': 'application/json-patch+json',
  },
};

export function updateAuthenticationHeader(token) {
  authenticationHeader.headers = { Authorization: `Bearer ${token}` };
  patchAuthenticationHeader.headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json-patch+json',
  };
}

export const siteColorMapping = {
  1: '#4F8F9D',
  2: '#3611FF',
  3: '#ffcdd2',
  4: '#ffccbc',
  5: '#d1c4e9',
};

export const colors = [
  {
    background: '#E57373',
    color: '#000000',
  },
  {
    background: '#F06292',
    color: '#000000',
  },
  {
    background: '#BA68C8',
    color: '#ffffff',
  },
  {
    background: '#9575CD',
    color: '#ffffff',
  },
  {
    background: '#7986CB',
    color: '#ffffff',
  },
  {
    background: '#64B5F6',
    color: '#000000',
  },
  {
    background: '#4FC3F7',
    color: '#000000',
  },
  {
    background: '#4DD0E1',
    color: '#000000',
  },
  {
    background: '#4DB6AC',
    color: '#000000',
  },
  {
    background: '#81C784',
    color: '#000000',
  },
  {
    background: '#AED581',
    color: '#000000',
  },
  {
    background: '#DCE775',
    color: '#000000',
  },
  {
    background: '#FFF176',
    color: '#000000',
  },
  {
    background: '#FFD54F',
    color: '#000000',
  },
  {
    background: '#FFB74D',
    color: '#000000',
  },
  {
    background: '#FF8A65',
    color: '#000000',
  },
  {
    background: '#A1887F',
    color: '#ffffff',
  },
  {
    background: '#90A4AE',
    color: '#000000',
  },
];

export const COMMENT_FIELD_ENTITY_IDS = [36, 37, 38, 39];

export const ARCHIVE_REASONS = [
  {
    id: 'NO_DELIVERY_DATE',
    reason: 'No valid delivery date',
  },
  {
    id: 'NO_REFERENCE_NUMBER',
    reason: 'No Van Marcke valid order reference',
  },
  {
    id: 'COPY_STOCK_ORDER',
    reason: 'Copy of stock order',
  },
  {
    id: 'NO_ORDER',
    reason: 'Not an order confirmation',
  },
  {
    id: 'CANNOT_PROCESS',
    reason: 'Unreadable document',
  }
];

export const CONTACT_PERSON_FIRST_NAME_ENTITY_ID = 23;
export const CONTACT_PERSON_LAST_NAME_ENTITY_ID = 24;
export const CONTACT_PERSON_EMAIL_ENTITY_ID = 25;

export const OPTIONS_ENTITY_ID = 12;
export const ATTACHMENTS_ENTITY_ID = 13;

export const MACHINE_PICKUP_DATE_ENTITY_ID = 35;
export const MACHINE_DELIVERY_DATE_ENTITY_ID = 40;

export const ENTITY_IDS_THAT_SHOULD_BE_NUMBERS = [];
export const ENTITY_IDS_THAT_SHOULD_BE_DATES = [];

export const MACHINE_DESCRIPTION_ID = 30;
export const DELIVERY_DATE_ENTITY_ID = 3;
export const DELIVERY_HOUR_ENTITY_ID = 4;
export const PICKUP_DATE_ENTITY_ID = 5;
export const PICKUP_HOUR_ENTITY_ID = 6;
export const DURATION_ENTITY_ID = 7;
export const FIXED_RENTAL_END_ENTITY_ID = 8;
export const DURATION_TYPE_ENTITY_ID = 9;
export const MACHINE_TYPE_ENTITY_ID = 10;
export const MACHINE_ENTITY_ID = 11;
export const CUSTOMER_NUMBER_ENTITY_ID = 1;
export const LOCATION_NAME_ENTITY_ID = 15;

export const ENTITY_IDS_THAT_HAVE_MINIMUM_LENGTH = {};

export const OUT_OF_BOUNDS_ERROR = 'EntityAnnotationOutOfBoundError';
export const TEXT_IDS_EMPTY = 'EntityTextIdsEmpty';
export const DIFFERENT_DOCUMENT_ERROR = 'EntityAnnotationDifferentDocumentIdError';
export const DUPLICATE_ANNOTATION_ERROR = 'EntityAnnotationDuplicateAnnotationError';

export const LANGUAGES = [
  {
    code: 'en',
    flag: 'GB',
    label: 'English',
  },
  {
    code: 'nl',
    flag: 'NL',
    label: 'Nederlands',
  },
  {
    code: 'fr',
    flag: 'FR',
    label: 'Français',
  },
];
