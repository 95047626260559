import React from 'react';
import './FlagLabel.scss';

const labelMapping = {
  flagged: 'ox-icon ox-icon--flag error',
  complete: 'ox-icon ox-icon--check success',
};

export default ({ label }) => (
  <span className={labelMapping[label.toLowerCase()]} />
);
