import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import moment, { months } from 'moment';
import 'moment/min/locales';
import Preloader from '../../preloader/Preloader';
import MetricsColumn from './MetricsColumn';
import * as metricsReportActionFile from '../../../actions/metricsReport/metricsReportActions';
import * as appStateActionFile from '../../../actions/appState/appStateActions';
import * as feedbackActionFile from '../../../actions/feedback/feedbackActions';
import './MetricsReport.scss';
import { exportAuditTrailToCsv } from '../../../services/auditTrailService';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  metricsReportActions: bindActionCreators(metricsReportActionFile, dispatch),
  appStateActions: bindActionCreators(appStateActionFile, dispatch),
  feedbackActions: bindActionCreators(feedbackActionFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  metricsReportActions,
  appStateActions,
  feedbackActions,
  metricsReportReducer: {
    loading,
    metrics,
  },
}) => {
  const { t, i18n } = useTranslation('metrics');
  const [selectedMonth, setSelectedMonth] = useState(-1);
  const [filteredMetrics, setFilteredMetrics] = useState([]);

  useEffect(() => {
    metricsReportActions.fetchMetricsReport();
  }, []);

  useEffect(() => {
    appStateActions.updateSubtitle(t('title'));
  }, [i18n.language]);

  useEffect(() => {
    moment.locale(i18n.language);
    const metricArray = [[], [], [], [], [], []];
    if (selectedMonth === -1) {
      months().forEach((month, index) => {
        const data = metrics.find((metric) => metric.month === (index + 1));
        metricArray[5].push(month.substring(0, 3));
        if (data) {
          metricArray[0].push(data.count);
          metricArray[1].push(data.low);
          metricArray[2].push(data.top);
          metricArray[3].push(data.average);
          metricArray[4].push(data.median);
        } else {
          metricArray.forEach((_, metricIndex) => {
            if (metricIndex < 5) {
              metricArray[metricIndex].push('N/A');
            }
          });
        }
      });
    } else {
      const data = metrics.find((metric) => metric.month - 1 === selectedMonth);
      metricArray[5].push(months()[selectedMonth].substring(0, 3));
      if (data) {
        metricArray[0].push(data.count);
        metricArray[1].push(data.low);
        metricArray[2].push(data.top);
        metricArray[3].push(data.average);
        metricArray[4].push(data.median);
      } else {
        metricArray.forEach((__, metricIndex) => {
          if (metricIndex < 5) {
            metricArray[metricIndex].push('N/A');
          }
        });
      }
    }
    setFilteredMetrics(metricArray);
  }, [selectedMonth, metrics, i18n.language]);

  const downloadAuditTrailReport = async () => {
    const result = await exportAuditTrailToCsv();

    if (!result) {
      const message = 'Something went wrong downloading the export. Please try again later...';
      feedbackActions.addErrorMessage(message);
    }
  };

  return (
    <div className="audit-report-container">
      {
        loading ? <Preloader /> : (
          <div className="ox-table-wrapper">
            <div className="ox-form__group select-wrapper">
              <select
                value={selectedMonth}
                onChange={(event) => setSelectedMonth(parseInt(event.target.value, 0))}
                className="ox-form__field"
              >
                <option value="-1">{t('year')}</option>
                {
                    months().map((month, index) => (
                      <option value={index} key={month}>{month}</option>
                    ))
                }
              </select>
              <button
                type="button"
                className="ox-button ox-button--primary"
                onClick={() => downloadAuditTrailReport()}
              >
                Export audit trail <span className="fa fa-file-csv" />
              </button>
            </div>
            <h4>{`${t('header')} ${months()[selectedMonth] || t('year').toLowerCase()}`}</h4>
            <table className="ox-table">
              <thead>
                <tr>
                  <th width="150" scope="col">{t('month')}</th>
                  {
                    (filteredMetrics[5] || []).map((month) => (
                      <th width="110" className="center-value" scope="col" key={month}>{month}.</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"># {t('processed')}</th>
                  <MetricsColumn values={filteredMetrics[0] || []} />
                </tr>
                <tr>
                  <th scope="row">{t('low')}</th>
                  <MetricsColumn values={filteredMetrics[1] || []} />
                </tr>
                <tr>
                  <th scope="row">{t('high')}</th>
                  <MetricsColumn values={filteredMetrics[2] || []} />
                </tr>
                <tr>
                  <th scope="row">{t('average')}</th>
                  <MetricsColumn values={filteredMetrics[3] || []} />
                </tr>
                <tr>
                  <th scope="row">{t('median')}</th>
                  <MetricsColumn values={filteredMetrics[4] || []} />
                </tr>
              </tbody>
            </table>
          </div>
        )
      }
    </div>
  );
});
