import * as userTypes from '../actions/user/userTypes';
import { updateAuthenticationHeader } from '../constants/constants';

const initialState = {
  loading: false,
  loginError: undefined,
  logoutError: undefined,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.USER_LOGIN:
      return { ...state, loading: true };
    case userTypes.USER_LOGIN_SUCCESS: {
      const userObject = {
        'api-key': action.token,
        roles: action.roles,
        id: action.id,
        officeId: action.branchOfficeId,
        language: action.language,
      };
      localStorage.setItem('active-user', JSON.stringify(userObject));
      updateAuthenticationHeader(action.token);
      return { ...state, loading: false };
    }
    case userTypes.USER_LOGIN_FAILED:
      return { ...state, loading: false, loginError: action.error };
    case userTypes.USER_LOGOUT:
      localStorage.removeItem('active-user');
      return { ...state };

    case userTypes.INVALID_API_KEY:
      localStorage.removeItem('active-user');
      return { ...state };
    default:
      return state;
  }
};

export { userReducer as default };
