import React from 'react';
import './navigationAlert.scss';

const NavigationAlert = ({
  onConfirm, onCancel, message, title,
}) => (
  <dialog className="ox-modal" open>
    <button type="button" className="ox-modal__close" aria-label="Close the modal" onClick={onCancel}>
      <span className="ox-icon ox-icon--close ox-icon--small" />
    </button>
    <div className="ox-modal__body">
      <h3 className="ox-modal__title">{title}</h3>
      <p>{message}</p>
    </div>
    <div className="ox-modal__footer">
      <div className="ox-button-group ox-button-group--reverse">
        <button type="button" className="ox-button ox-button--secondary" onClick={onConfirm}>
          Yes
        </button>
        <button type="button" className="ox-button ox-button--plain" onClick={onCancel}>No</button>
      </div>
    </div>
  </dialog>
);

export default NavigationAlert;
