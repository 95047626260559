import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as keyEventActionFile from '../../../actions/keyEvent/keyEventActions';
import HoverButtons from './HoverButtons';
import EditContainer from './EditContainer';
import { shouldBeNumeric } from '../../../helpers/formValidationHelpers';
import { COMMENT_FIELD_ENTITY_IDS } from '../../../constants/constants';
import './EntityRow.scss';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  keyEventActions: bindActionCreators(keyEventActionFile, dispatch),
});

const InputField = ({
  name,
  value,
  disabled,
  removeEnabled,
  required,
  metroStyle,
  isTextArea,
  onChange,
  isRowHovered,
  onRemove,
  keyEventActions,
  autoFormatValues,
  entity,
  showName = true,
  hasMultipleAnnotations = false,
  extraInfo = null,
  isLast = false,
  showRemoveButton,
  changeIsEditing,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputField = useRef(null);
  const isEmpty = COMMENT_FIELD_ENTITY_IDS.includes(entity.id) ? false : value === null;

  const handleFocus = () => {
    if (disabled) {
      return;
    }
    if (isEmpty) {
      onChange('');
    }
    setIsEditing(true);
    changeIsEditing(true);
    keyEventActions.disableKeyEventListener();

    setTimeout(() => {
      inputField.current.focus();
    });
  };

  const handleBlur = () => {
    if (value === '') {
      onChange(null);
    }

    if (shouldBeNumeric(entity)) {
      onChange(parseInt(value, 10));
    }

    autoFormatValues();
    keyEventActions.enableKeyEventListener();
    setIsEditing(false);
    changeIsEditing(false);
  };

  const handleKeyEvent = (event) => {
    if (isEditing) {
      switch (event.key) {
        case 'Enter': {
          if (!isTextArea) {
            event.preventDefault();
            inputField.current.blur();
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const isEditingClass = (isEditing ? 'editing' : '');

  // Lifecycle hooks
  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  const renderTextField = useMemo(() => {
    if (isTextArea) {
      return (
        <textarea
          id={name}
          disabled={disabled}
          ref={inputField}
          rows="3"
          className={`ox-form__field ${disabled ? 'disabled' : ''}`}
          value={value || ''}
          required={required}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          onChange={({ target: { value: changedValue } }) => onChange(changedValue)}
        />
      );
    }
    return (
      <input
        id={name}
        disabled={disabled}
        type={shouldBeNumeric(entity) ? 'number' : 'text'}
        ref={inputField}
        className={`annotation-text ${isEmpty ? 'empty' : ''} ${isEditingClass}`}
        value={value}
        required={required}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        onChange={({ target: { value: changedValue } }) => onChange(changedValue)}
      />
    );
  }, [value, isEditing, isRowHovered, isEmpty, isTextArea, hasMultipleAnnotations, disabled, required]);

  const renderParagraph = useMemo(() => (
    <p
      className="annotation-text"
      onClick={() => handleFocus()}
    >{(value || '').toString()}
    </p>
  ), [value]);
  const valueItem = useMemo(() => {
    if (!isEmpty || isTextArea) {
      return (
        <div className={`edit-container ${hasMultipleAnnotations ? 'metro' : ''}`}>
          <div className="metro-line">
            <span style={metroStyle} className={`line ${isLast ? 'last' : ''}`} />
            <span style={metroStyle} className="dot" />
          </div>
          <div className={`typeahead-container ${isEditingClass} ${isTextArea ? 'textArea' : ''}`}>
            {!isEditing && !isTextArea ? renderParagraph : renderTextField}
          </div>
          {isTextArea ? null : (
            <HoverButtons
              disabled={disabled}
              removeEnabled={removeEnabled}
              isEmpty={isEmpty}
              isRowHovered={isRowHovered}
              isEditing={isEditing}
              handleFocus={handleFocus}
              onRemove={onRemove}
            />
          )}
        </div>
      );
    }
    return null;
  }, [value, isEditing, isRowHovered, isEmpty, isTextArea, hasMultipleAnnotations, disabled, onRemove, handleFocus]);

  const extraInfoContainer = useMemo(() => {
    if (extraInfo && !isEditing) {
      return <span className="ox-form__feedback">{extraInfo}</span>;
    }
    return null;
  }, [extraInfo, isEditing]);

  return (
    <>
      <EditContainer
        name={name}
        showName={showName}
        disabled={disabled}
        isEmpty={isEmpty}
        isRowHovered={isRowHovered}
        isEditing={isEditing}
        hasMultipleAnnotations={hasMultipleAnnotations}
        handleFocus={handleFocus}
        isTextArea={isTextArea}
        showRemoveButton={showRemoveButton}
        onRemove={onRemove}
      />
      {valueItem}
      {extraInfoContainer}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InputField);
