import * as PdfJs from 'pdfjs-dist/build/pdf';
import * as PdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
import axios from 'axios';
import { getApiKeyFromActiveUser } from '../helpers/accountHelpers';
import { authenticationHeader, baseUrl } from '../constants/constants';

export const fetchPdf = (url) => {
  PdfJs.GlobalWorkerOptions.workerSrc = PdfWorker;
  return PdfJs.getDocument({
    url,
    httpHeaders: {
      Accept: 'application/pdf',
      Authorization: `Bearer ${getApiKeyFromActiveUser()}`,
    },
  }).promise;
};

export const downloadPdf = (fileLocation) => {
  const headers = {
    ...authenticationHeader,
    responseType: 'arraybuffer',
  };
  return axios.get(`${baseUrl}/bucket/pdf?objectName=${fileLocation}`, headers)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', fileLocation);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }, (error) => {
      console.error(error);
      return false;
    });
};
