import { useState, useEffect } from 'react';
import { fetchPdf } from '../services/pdfService';

const useDocument = (url) => {
  const [pdfPages, setPdfPages] = useState(0);
  const [pdfData, setPdfData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPdf(url)
      .then((pdf) => {
        setPdfData(pdf);
        setPdfPages(pdf.numPages);
        setError(null);
      })
      .catch((fetchError) => {
        setPdfPages(0);
        setPdfData(null);
        setError(fetchError);
      });
  }, [url]);

  return { pdfPages, pdfData, error };
};

export default useDocument;
