import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store/index';
import * as serviceWorker from './serviceWorker';
import './i18n';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-pdf-ner-annotator/lib/css/style.css';
import Preloader from './components/preloader/Preloader';

ReactDOM.render(
  <Suspense fallback={<Preloader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
