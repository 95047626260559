import * as metricsReportTypes from '../actions/metricsReport/metricsReportTypes';

const initialState = {
  loading: false,
  error: null,
  metrics: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case metricsReportTypes.FETCH_METRICS_REPORTS_MONTH:
      return { ...state, loading: true };
    case metricsReportTypes.FETCH_METRICS_REPORTS_MONTH_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, metrics: data };
    }
    case metricsReportTypes.FETCH_METRICS_REPORTS_MONTH_FAILED: {
      const { error } = action;
      return { ...state, loading: false, error };
    }

    default:
      return state;
  }
};
