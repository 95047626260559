import React from 'react';
import './ScrollButton.scss';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const ScrollButton = ({ shouldRender = false }) => (
  <div className="scroll-button-container">
    <button
      type="button"
      className={`ox-button ox-button--secondary btn-circle btn-xl btn-top ${shouldRender ? 'visible' : 'invisible'}`}
      onClick={() => scrollToTop()}
    >
      <span className="ox-button__icon ox-icon ox-icon--chevron-up" aria-hidden="true" />
    </button>
  </div>
);

export default ScrollButton;
