import axios from 'axios';
import * as configMapTypes from './configMapTypes';
import { baseUrl, authenticationHeader } from '../../constants/constants';
import { invalidApiKey } from '../user/userActions';
import { addErrorMessage } from '../feedback/feedbackActions';
import { tokenIsValid } from '../../helpers/accountHelpers';

const fetchConfigMapLoading = () => ({ type: configMapTypes.FETCH_CONFIG_MAP });
const fetchConfigMapSuccess = (data) => ({ type: configMapTypes.FETCH_CONFIG_MAP_SUCCESS, data });
const fetchConfigMapFailed = (error) => ({ type: configMapTypes.FETCH_CONFIG_MAP_FAILED, error });

const editEntityConfigProperties = (data) => ({ type: configMapTypes.EDIT_CONFIG_MAP_PROPERTY, data });

export const fetchConfigMap = () => (dispatch) => {
  dispatch(fetchConfigMapLoading());

  return axios.get(`${baseUrl}/config-map`, authenticationHeader)
    .then((response) => {
      dispatch(fetchConfigMapSuccess(response.data));
    })
    .catch((error) => {
      if (error.response.status && error.response.status === 401) {
        if (!tokenIsValid()) {
          dispatch(invalidApiKey());
        }
      }
      dispatch(fetchConfigMapFailed(error));
      dispatch(addErrorMessage(error.response.data.error.message));
    });
};

export const editEntityConfig = (id, data) => (dispatch) => {
  dispatch(editEntityConfigProperties({ id, ...data }));
};
