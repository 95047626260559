function comparer(otherArray){
  return function(current){
    return otherArray.filter(function(other){
      return other.id == current.id
      && other.textIds == current.textIds
      && other.entity == current.entity
    }).length == 0;
  }
}

export const getChangedAnnotations = (annotations, prevAnnotations) => {
  if (!prevAnnotations){
    return null;
  }
  const onlyInA = annotations.filter(comparer(prevAnnotations));
  const onlyInB = prevAnnotations.filter(comparer(annotations));

  return onlyInA.concat(onlyInB);
}


export const selectionIsEmpty = (selection) => {
  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);

  return position === 0 && selection.focusOffset === selection.anchorOffset;
};

export const selectionIsBackwards = (selection) => {
  if (selectionIsEmpty(selection)) return false;

  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);

  let backward = false;
  if ((!position && selection.anchorOffset > selection.focusOffset)
        || position === Node.DOCUMENT_POSITION_PRECEDING) backward = true;

  return backward;
};

/**
 * Validates whether the user selected something meaningful
 * This selection is the raw mouse selection before the tokens are checked
 * @param  {Object} selection Selection object (like from window.getSelection())
 * @return {Boolean}          True if selection was valid
 */
export const validateUserSelection = (selection) => {
  if (selectionIsEmpty(selection)) return false;

  if (!selection.anchorNode.parentElement.hasAttribute('data-i')
    || !selection.focusNode.parentElement.hasAttribute('data-i')) {
    window.getSelection().empty();
    return false;
  }

  return true;
};

/**
 * Gets the start and end indices from the user's selections
 * @param  {Object} selection   An selection object (like window.getSelection())
 * @param  {Array}  tokens      An array of active tokens to check the selection against
 * @return {Object}             An object containin numeric start and end indices
 */
export const getStartAndEndFromSelection = (selection) => {
  let start = parseInt(
    selection.anchorNode.parentElement.getAttribute('data-i'),
    10,
  );
  let end = parseInt(
    selection.focusNode.parentElement.getAttribute('data-i'),
    10,
  );

  // if (tokens[start] === '\n') start += 1;
  // if (tokens[end] === '\n') end -= 1;

  if (selectionIsBackwards(selection)) {
    [start, end] = [end, start];
  }

  end += 1;

  return { start, end };
};
