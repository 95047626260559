import React from 'react';
import useDocument from '../../../hooks/useDocument';
import Page from './Page';
import './Viewer.scss';

const Viewer = ({ url }) => {
  const { pdfPages, pdfData } = useDocument(url);

  return (
    <div className="pdf-viewer-container">
      {
        (Array(pdfPages).fill(0)).map((_, index) => {
          const key = `pdf-page-${index}`;
          return (
            <Page
              pdf={pdfData}
              index={index + 1}
              key={key}
            />
          );
        })
      }
    </div>
  );
};

export default Viewer;
