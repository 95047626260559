import store from '../store';

export const OPTIONS = 'options';
export const ATTACHMENTS = 'attachments';

const getState = () => {
  const state = store.getState();
  const {
    orderReducer: {
      activeOrder,
    },
    machineReducer: {
      machineOptions,
      machineAttachments,
    },
  } = state;
  return { activeOrder, machineOptions, machineAttachments };
};


export const filterMachinesExtras = (index, type, machineCodeId = 11) => {
  const { activeOrder, machineOptions, machineAttachments } = getState();
  const machineCode = activeOrder.find((item) => item.entityId === machineCodeId);
  if (machineCode && index) {
    if (type.toLowerCase() === OPTIONS) {
      return machineOptions[(machineCode.value[index][0] || '').toString()] || [];
    }
    if (type.toLowerCase() === ATTACHMENTS) {
      return machineAttachments[(machineCode.value[index][0] || '').toString()] || [];
    }
    return [];
  }
  return [];
};
