import lodash from 'lodash';
import { findEntitiesOfGroupByGroupName } from '../helpers/reduxHelpers';
import * as orderTypes from '../actions/order/orderTypes';

const initialState = {
  activeOrder: [],
  originalOrder: [],
  loading: false,
  isEdited: false,
  formIsDirty: false,
  activeTabIndex: 0,
};

const removeFromOrder = (activeOrder, groupEntities, indexToRemove) => {
  let updatedOrder = JSON.parse(JSON.stringify(activeOrder));
  const groupIds = groupEntities.map((groupEntity) => groupEntity.id);

  if (groupIds.length) {
    updatedOrder = activeOrder.map((orderItem) => {
      if (groupIds.includes(orderItem.entityId)) {
        const { [indexToRemove]: _, ...updatedValue } = orderItem.value;
        const fixedValues = {};

        Object.keys(updatedValue).forEach((key, index) => {
          fixedValues[index + 1] = updatedValue[key];
        });
        return {
          ...orderItem,
          value: fixedValues,
        };
      }
      return orderItem;
    });
  }
  return updatedOrder;
};

const addToOrder = (activeOrder, groupEntities) => activeOrder.map((activeOrderItem) => {
  const entity = groupEntities.find((groupEntity) => groupEntity.id === activeOrderItem.entityId);
  if (entity) {
    const value = { ...activeOrderItem.value, [Object.keys(activeOrderItem.value).length + 1]: [entity.defaultValue] };
    return {
      ...activeOrderItem,
      value,
    };
  }
  return activeOrderItem;
});

const findVariableByEntityId = (state, idToFind) => {
  const { activeOrder } = state;
  let indexOfVariableToAdjust = null;

  activeOrder.some((variable, index) => {
    if (variable.entityId === idToFind) {
      indexOfVariableToAdjust = index;
      return true;
    }

    return false;
  });

  return indexOfVariableToAdjust;
};

const updateOrderValue = (order, indexOfVariableToAdjust, value) => ([
  ...order.slice(0, indexOfVariableToAdjust),
  {
    ...order[indexOfVariableToAdjust],
    value,
  },
  ...order.slice(indexOfVariableToAdjust + 1, order.length),
]);

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderTypes.FETCH_ORDER_SUCCESS: {
      const { data } = action;
      const fixed = data.map((d) => ({
        id: d.id,
        value: d.value,
        entityId: d.entityType.id,
      }));
      // Use JSON.parse & stringify to create deep copies instead of shallow copies.

      const activeOrder = lodash.cloneDeep(fixed);
      const originalOrder = lodash.cloneDeep(activeOrder);
      return {
        ...state,
        activeOrder,
        originalOrder,
        loading: false,
        isEdited: false,
        formIsDirty: false,
      };
    }

    case orderTypes.CHANGE_VARIABLE: {
      let {
        orderEntityId, indexToAdjust, value, id, shouldFocusInput,
      } = action;

      // Make sure that value is an array
      if (typeof value === 'string' || value instanceof String){
        value = [value]
      }

      const shouldFocusObject = shouldFocusInput ? { index: value.length - 1, orderEntityId } : null;
      const { activeOrder, originalOrder } = state;
      const indexOfVariableToAdjust = findVariableByEntityId(state, orderEntityId);
      let updatedOrder = JSON.parse(JSON.stringify(activeOrder));

      if (indexOfVariableToAdjust !== null) {
        const updatedValue = activeOrder[indexOfVariableToAdjust].value;
        updatedValue[indexToAdjust] = value;
        updatedOrder = updateOrderValue(activeOrder, indexOfVariableToAdjust, updatedValue);
      } else {
        updatedOrder.push({
          id: null,
          value: { [indexToAdjust]: value },
          annotationIds: { [indexToAdjust]: id },
          entityId: orderEntityId,
        });
      }

      return {
        ...state,
        activeOrder: updatedOrder,
        shouldFocusInput: shouldFocusObject,
        isEdited: JSON.stringify(originalOrder) !== JSON.stringify(updatedOrder) && state.formIsDirty,
      };
    }

    case orderTypes.CLEAR_VARIABLE: {
      const { entityId, orderIndex, index } = action;
      const { activeOrder, originalOrder } = state;
      const indexOfVariableToAdjust = findVariableByEntityId(state, entityId);

      let updatedOrder = JSON.parse(JSON.stringify(activeOrder));

      if (indexOfVariableToAdjust !== null && index !== undefined) {
        const updatedValue = activeOrder[indexOfVariableToAdjust].value;
        if (updatedValue[orderIndex] && updatedValue[orderIndex].length > 1 && index !== -1) {
          updatedValue[orderIndex].splice(index, 1);
        } else {
          updatedValue[orderIndex] = [null];
        }

        updatedOrder = updateOrderValue(activeOrder, indexOfVariableToAdjust, updatedValue);
      }

      return {
        ...state,
        activeOrder: updatedOrder,
        isEdited: JSON.stringify(originalOrder) !== JSON.stringify(updatedOrder) && state.formIsDirty,
      };
    }

    case orderTypes.ADD_GROUP_TO_ORDER: {
      const { configMap, groupName } = action;
      const { activeOrder, originalOrder, activeTabIndex } = state;
      const machineGroupEntities = findEntitiesOfGroupByGroupName(configMap, activeTabIndex, groupName);
      const updatedOrder = addToOrder(activeOrder, machineGroupEntities);

      return {
        ...state,
        activeOrder: updatedOrder,
        isEdited: JSON.stringify(originalOrder) !== JSON.stringify(updatedOrder),
      };
    }
    case orderTypes.REMOVE_GROUP_FROM_ORDER: {
      const { configMap, indexToRemove, groupName } = action;
      const { activeOrder, originalOrder, activeTabIndex } = state;
      const machineGroupEntities = findEntitiesOfGroupByGroupName(configMap, activeTabIndex, groupName);
      const updatedOrder = removeFromOrder(activeOrder, machineGroupEntities, indexToRemove);

      return {
        ...state,
        activeOrder: updatedOrder,
        isEdited: JSON.stringify(originalOrder) !== JSON.stringify(updatedOrder) && state.formIsDirty,
      };
    }

    case orderTypes.DIRTY_FORM: {
      const { value } = action;
      return {
        ...state,
        formIsDirty: value,
      };
    }

    case orderTypes.CHANGE_ACTIVE_TAB_INDEX: {
      const { index } = action;

      return { ...state, activeTabIndex: index };
    }

    case orderTypes.CLEAR_ORDER: {
      return initialState;
    }

    default:
      return state;
  }
};

export { orderReducer as default };
