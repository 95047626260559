import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkIfAdmin, tokenIsValid } from '../../helpers/accountHelpers';
import useLocalStorage from '../../hooks/useLocalStorage';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { getValue: getActiveUser, clearValue } = useLocalStorage('active-user', null);

  useEffect(() => {
    if (!tokenIsValid()) {
      clearValue();
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (checkIfAdmin(getActiveUser()) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ))}
    />
  );
};

export default AdminRoute;
