import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as uploadActionsFile from '../../actions/upload/uploadActions';


const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  uploadActions: bindActionCreators(uploadActionsFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  text,
  icon,
  branchId,
  uploadActions,
}) => {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length > 0) {
      uploadActions.showError(fileRejections[0].errors[0].message);
    }
    acceptedFiles.forEach((file) => {
      uploadActions.uploadFile(file, branchId);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxSize: 1048576,
    maxFile: 1,
    multiple: false,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <li className="ox-dropmenu__option dropdown-toggle-item" role="presentation">
        <button
          type="button"
          className="ox-dropmenu__option__button toggle-button-container"
          role="menuitem"
        >
          <div className="text-item">
            <span className={`ox-dropmenu__option__icon ox-icon ${icon}`} />
            {text}
          </div>
        </button>
      </li>
    </div>
  );
});

// export default connect(mapStateToProps, mapDispatchToProps)(uploadMenuItem)


// export default function UploadMenuItem(props) {
//   const { text, icon, branchId } = props;
//
// const onDrop = useCallback((acceptedFiles) => {
//   console.log(`Upload files to ${branchId}`);
//   acceptedFiles.forEach((file) => {
//     // TODO - Upload file
//     console.log(file);
//     uploadActionsFile.uploadFile(file, branchId)
//   });
// }, []);
//
// const {
//   getRootProps,
//   getInputProps,
// } = useDropzone({
//   onDrop,
//   accept: 'application/pdf',
// });
//
// return (
//   <div {...getRootProps()}>
//     <input {...getInputProps()} />
//     <li className="ox-dropmenu__option dropdown-toggle-item" role="presentation">
//       <button
//         type="button"
//         className="ox-dropmenu__option__button toggle-button-container"
//         role="menuitem"
//       >
//         <div className="text-item">
//           <span className={`ox-dropmenu__option__icon ox-icon ${icon}`} />
//           {text}
//         </div>
//       </button>
//     </li>
//   </div>
// );
// }
//
// // export default UploadMenuItem;
