import * as appStateTypes from './appStateTypes';

export const updateSubtitle = (subtitle) => ({
  type: appStateTypes.UPDATE_SUBTITLE,
  subtitle,
});
export const changeToggleValue = (toggleValue) => ({
  type: appStateTypes.CHANGE_TOGGLE_VALUE,
  toggleValue,
});
