import React, {
  useMemo,
} from 'react';
import './EntityRow.scss';

const HoverButtons = ({
  disabled,
  removeEnabled,
  isEmpty,
  isEditing,
  handleFocus,
  onRemove,
}) => {
  const isEditingClass = (isEditing ? 'editing' : '');

  const hoverButtons = useMemo(() => {
    if (!disabled) {
      return (
        <div className={`${isEditing ? 'hidden' : ''} icon-actions`}>
          <div className="icon-container">
            <span
              role="button"
              onClick={() => handleFocus()}
              className={`edit-icon ox-icon ox-icon--edit ${isEditingClass} ${!isEditing && !isEmpty ? 'shown' : ''}`}
            />
          </div>
          <div className="icon-container">
            <span
              role="button"
              onClick={onRemove}
              className={`delete-icon ox-icon ox-icon--cancel ${isEditingClass} ${!isEditing && !isEmpty ? 'shown' : ''}`}
            />
          </div>
        </div>
      );
    }

    if (removeEnabled && !disabled) {
      return (
        <div className={`${isEditing ? 'hidden' : ''} icon-actions`}>
          <div className="icon-container">
            <span
              role="button"
              onClick={onRemove}
              className={`delete-icon ox-icon ox-icon--cancel ${isEditingClass} ${!isEditing && !isEmpty ? 'shown' : ''}`}
            />
          </div>
        </div>
      );
    }

    return null;
  }, [disabled, removeEnabled, isEmpty, isEditing, handleFocus]);

  return (
    hoverButtons
  );
};

export default HoverButtons;
