import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import EntityList from './EntityList';
import EntitiesSidebarNav from './EntitiesSidebarNav';
import * as documentActionFile from '../../../actions/document/documentActions';
import * as orderActionFile from '../../../actions/order/orderActions';
import './EntitiesSidebar.scss';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  documentActions: bindActionCreators(documentActionFile, dispatch),
  orderActions: bindActionCreators(orderActionFile, dispatch),
});

const EntitiesSidebar = ({
  disabled,
  annotatingDisabled,
  form,
  configMapReducer: {
    configMap,
  },
  orderReducer: {
    activeTabIndex,
  },
  orderActions,
}) => {
  const { t } = useTranslation('annotationView');

  return (
    <div className="sidebar entities">
      {
        configMap ? (
          <EntitiesSidebarNav
            tabItems={configMap.map((item) => ({ id: item.id, name: item.name, index: item.index }))}
            activeIndex={activeTabIndex}
            indexChange={orderActions.changeActiveTabIndex}
          />
        ) : null
      }
      <EntityList disabled={disabled} annotatingDisabled={annotatingDisabled} form={form} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesSidebar);
