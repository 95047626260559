import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { tokenIsValid } from '../../helpers/accountHelpers';
import useLocalStorage from '../../hooks/useLocalStorage';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getValue: getActiveUser, clearValue } = useLocalStorage('active-user');

  useEffect(() => {
    if (!tokenIsValid()) {
      clearValue();
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (getActiveUser() && tokenIsValid() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      ))}
    />
  );
};

export default PrivateRoute;
