import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PrivateRoute from './privateRoute/PrivateRoute';
import RoleRoute from './privateRoute/RoleRoute';
import AdminRoute from './admin/AdminRoute';
import Login from './login/Login';
import UserOverview from './overview/UserOverview';
import TokenAnnotator from './annotation/TokenAnnotator';
import ErrorBoundary from './error/ErrorBoundary';
import './App.scss';
import Header from './header/Header';
import Settings from './settings/Settings';
import { createBrowserHistory } from 'history'; // eslint-disable-line
import Toast from './toast/Toast';
import Health from './Health';
import AuditReport from './admin/metrics/MetricsReport';
import useLocalStorage from '../hooks/useLocalStorage';
import * as machineActionsFile from '../actions/machine/machineActions';
import * as extraOptionActionsFile from '../actions/extraOptions/extraOptionsActions';
import * as documentActionsFile from '../actions/document/documentActions';
import { tokenIsValid } from '../helpers/accountHelpers';

export const history = createBrowserHistory();

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  machineActions: bindActionCreators(machineActionsFile, dispatch),
  extraOptionActions: bindActionCreators(extraOptionActionsFile, dispatch),
  documentActions: bindActionCreators(documentActionsFile, dispatch),
});

const App = ({
  documentActions,
  machineActions,
  extraOptionActions,
}) => {
  const { t, i18n } = useTranslation('');
  const { getValue: getActiveUser } = useLocalStorage('active-user', {});

  useEffect(() => {
    const user = getActiveUser();
    if (user && tokenIsValid(user)) {
      documentActions.fetchDocumentTypes();
    }
  }, [getActiveUser()['api-key']]);

  useEffect(() => {
    const user = getActiveUser();
    if (user && tokenIsValid(user) && getActiveUser().language) {
      i18n.changeLanguage(getActiveUser().language);
    }
  }, [getActiveUser().language]);

  return (
    <div className="App">
      <Router history={history}>
        <ErrorBoundary history={history}>
          <Header history={history} />
          <Switch>
            <Route path="/login" component={Login} />
            <Redirect
              exact
              from="/"
              to="/overview"
            />
            <RoleRoute
              exact
              path="/overview"
              userComponent={UserOverview}
              adminComponent={UserOverview}
            />
            <PrivateRoute
              path="/annotator/:annotationType/:mailId"
              component={TokenAnnotator}
            /> <PrivateRoute
              path="/annotator"
              component={TokenAnnotator}
            /> <AdminRoute
              path="/metrics"
              component={AuditReport}
            />
            <PrivateRoute path="/settings" component={Settings} />
            <Route path="/health" component={Health} />
          </Switch>
        </ErrorBoundary>
      </Router> <Toast />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
