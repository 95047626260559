import * as entityTypes from '../actions/entity/entityTypes';

const initialState = {
  loading: false,
  error: undefined,
  selectedEntity: {
    index: '1',
    id: null,
    color: null,
    name: null,
    entityType: 'NER',
  },
  previousSelectedEntity: {
    index: '1',
    id: null,
    color: null,
    name: null,
    entityType: 'NER',
  },
  hoveredEntityId: null,
};

const entityReducer = (state = initialState, action) => {
  switch (action.type) {
    case entityTypes.CHANGE_SELECTED_ENTITY: {
      const { entityId, entityColor, entityName, index } = action;
      const { id, color, name, entityType } = state.selectedEntity;

      if (id === entityId) {
        return {
          ...state,
          previousSelectedEntity: {
            index,
            id,
            color,
            name,
            entityType
          },
          selectedEntity: {
            index: '1',
            id: null,
            index: null,
            name: null,
            entityType: 'NER'
          }
        };
      }
      return {
        ...state,
        previousSelectedEntity: {
          index,
          id,
          color,
          name,
          entityType
        },
        selectedEntity: {
          index: index,
          id: entityId,
          color: entityColor ,
          name: entityName,
          entityType: 'NER'
        }
      };
    }

    case entityTypes.CLEAR_SELECTED_ENTITY:
      return {
        ...state,
        selectedEntity: {
          index: '1',
          id: null,
          color: null,
          name: 'selected_entity',
          entityType: 'NER'
        },
      };

    case entityTypes.CHANGE_HOVERED_ENTITY: {
      const { hoveredEntityId: previousHoveredEntityId } = state;
      if (previousHoveredEntityId === action.id) {
        return state;
      }
      return {
        ...state,
        hoveredEntityId: action.id,
      };
    }
    case entityTypes.CLEAR_HOVERED_ENTITY:
      return {
        ...state,
        hoveredEntityId: null,
      };

    default:
      return state;
  }
};

export { entityReducer as default };
