import axios from 'axios';
import * as customerTypes from './customerTypes';
import { authenticationHeader, baseUrl } from '../../constants/constants';
// TODO: Set this url to test this locally
//  const baseUrl = 'http://localhost:8081/api/v1';

const fetchCustomersLoading = () => ({ type: customerTypes.FETCH_CUSTOMERS });
const fetchCustomersSuccess = (data) => ({ type: customerTypes.FETCH_CUSTOMERS_SUCCESS, data });
const fetchCustomersFailed = (error) => ({ type: customerTypes.FETCH_CUSTOMERS_FAILED, error });

export function search(searchValue) {
  return (dispatch) => {
    if (!searchValue) {
      return [];
    }
    dispatch(fetchCustomersLoading());

    return axios.get(`${baseUrl}/customers/search?query=${searchValue}`, authenticationHeader)
      .then((response) => {
        dispatch(fetchCustomersSuccess(response.data));
        return response.data;
      }).catch(() => {
        dispatch(fetchCustomersFailed());
        return [];
      });
  };
}

const fetchCustomerByIdSuccess = (data) => ({ type: customerTypes.FETCH_CUSTOMER_BY_ID_SUCCESS, data });
const fetchCustomerByIdFailed = () => ({ type: customerTypes.FETCH_CUSTOMER_BY_ID_FAILED });

export function findCustomerById(customerNumber) {
  return (dispatch) => {
    if (!customerNumber) {
      return null;
    }
    dispatch(fetchCustomersLoading());
    return axios.get(`${baseUrl}/customers/${customerNumber}`, authenticationHeader)
      .then((response) => {
        dispatch(fetchCustomerByIdSuccess(response.data));
        return response.data;
      }).catch(() => {
        dispatch(fetchCustomerByIdFailed());
        return [];
      });
  };
}
