import { useState, useEffect } from 'react';
import Bloodhound from 'bloodhound-js';

export const useBloodhound = (options, alwaysShowResults = false) => {
  const [suggestions, setSuggestions] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [suggestionEngine, setSuggestionEngine] = useState(null);

  useEffect(() => {
    if (!initialized && options && options.length > 0) {
      setSuggestionEngine(
        new Bloodhound({
          local: options,
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          datumTokenizer: (option) => {
            const optionOneTokenizer = Bloodhound.tokenizers.whitespace(option.optionOne);
            const optionTwoTokenizer = Bloodhound.tokenizers.whitespace(option.optionTwo);
            const optionThreeTokenizer = Bloodhound.tokenizers.whitespace(option.optionThree);
            return optionOneTokenizer.concat(optionTwoTokenizer).concat(optionThreeTokenizer);
          },
        }),
      );
    }
  }, [options]);

  useEffect(() => {
    if (suggestionEngine) {
      suggestionEngine.initialize().then(() => {
        setInitialized(true);
      });
    }
  }, [suggestionEngine]);

  const search = (searchValue) => {
    if (alwaysShowResults && (!searchValue || searchValue[0] === null)) {
      // Set the options default if the alwaysShowResults is true and the searchValue is empty.
      setSuggestions(options);
      return;
    }
    if (initialized && ((searchValue && searchValue.length >= 2) || alwaysShowResults)) {
      suggestionEngine.search(searchValue, (result) => {
        // Search should always return the resultSet even if empty
        setSuggestions(result);
      });
    } else {
      setSuggestions([]);
    }
  };

  return {
    search,
    suggestions,
  };
};
