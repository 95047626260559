export const FETCH_MACHINES = 'FETCH_MACHINES';
export const FETCH_MACHINES_SUCCESS = 'FETCH_MACHINES_SUCCESS';
export const FETCH_MACHINES_FAILED = 'FETCH_MACHINES_FAILED';

export const FETCH_OPTIONS = 'FETCH_OPTIONS';
export const FETCH_MACHINE_OPTIONS = 'FETCH_MACHINE_OPTIONS';

export const FETCH_ATTACHMENTS = 'FETCH_ATTACHMENTS';
export const FETCH_MACHINE_ATTACHMENTS = 'FETCH_MACHINE_ATTACHMENTS';

export const CLEAR_MACHINE_ATTACHMENTS_AND_OPTIONS = 'CLEAR_MACHINE_ATTACHMENTS_AND_OPTIONS';
