import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import hash from 'object-hash';
import * as keyEventActionFile from '../../../actions/keyEvent/keyEventActions';
import * as customerActionFile from '../../../actions/customer/customerActions';
import './EntityRow.scss';
import HoverButtons from './HoverButtons';
import EditContainer from './EditContainer';
import { useBloodhound } from '../../../hooks/useBloodhound';
import { useSearch } from '../../../hooks/useSearch';
import { CUSTOMER_NUMBER_ENTITY_ID } from '../../../constants/constants';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  keyEventActions: bindActionCreators(keyEventActionFile, dispatch),
  customerActions: bindActionCreators(customerActionFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  name,
  value,
  disabled,
  removeEnabled,
  required,
  options,
  metroStyle,
  onChange,
  isRowHovered,
  onRemove,
  keyEventActions,
  autoFormatValues,
  showName = true,
  hasMultipleAnnotations = false,
  returnOption = 'optionOne',
  extraInfo = null,
  alwaysShowResults = false,
  isLast = false,
  entity,
  orderReducer: {
    shouldFocusInput,
  },
  customerActions,
  changeIsEditing,
  index,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [maxOptions, setMaxOptions] = useState(0);
  const isEmpty = value === null;
  const inputField = useRef();

  let search;
  let suggestions = [];

  // Nasty fix for customers search
  if (entity.id === CUSTOMER_NUMBER_ENTITY_ID) {
    const { search: newSearch, suggestions: results } = useSearch(customerActions, alwaysShowResults);
    search = newSearch;
    suggestions = results;
  } else {
    const { search: newSearch, suggestions: results } = useBloodhound(options, alwaysShowResults);
    search = newSearch;
    suggestions = results;
  }

  const handleFocus = () => {
    if (disabled) {
      return;
    }
    if (isEmpty) {
      onChange('');
    }

    setIsEditing(true);
    changeIsEditing(true);
    keyEventActions.disableKeyEventListener();

    setTimeout(() => {
      if (inputField && inputField.current) {
        inputField.current.focus();
      }
    });
  };

  const handleBlur = () => {
    if (value === '') {
      onChange(null);
    }

    keyEventActions.enableKeyEventListener();
    autoFormatValues();
    setIsEditing(false);
    changeIsEditing(false);
  };

  useEffect(() => {
    if (!shouldFocusInput) {
      return;
    }
    const { orderEntityId, index: indexToFocus } = shouldFocusInput;
    if (entity.entityId === orderEntityId && index === indexToFocus) {
      handleFocus();
    }
  }, [shouldFocusInput, entity, disabled, index]);

  const updateSelectedIndex = (updateValue) => {
    if (selectedIndex + updateValue >= 0 && selectedIndex + updateValue < maxOptions) {
      setSelectedIndex(selectedIndex + updateValue);
    }
  };

  const handleKeyEvent = (event) => {
    if (options && isEditing) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          updateSelectedIndex(+1);
          break;
        case 'ArrowUp':
          event.preventDefault();
          updateSelectedIndex(-1);
          break;
        case 'Enter': {
          if (suggestions.length > 0 && (suggestions[selectedIndex][returnOption] || suggestions[selectedIndex].optionOne)) {
            const selectedOption = suggestions[selectedIndex][returnOption] || suggestions[selectedIndex].optionOne;
            onChange(selectedOption);
          }
          inputField.current.blur();
          break;
        }
        default:
          break;
      }
    }
  };

  const isEditingClass = (isEditing ? 'editing' : '');

  // Lifecycle hooks
  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  useEffect(() => {
    search(value);
  }, [value, isEditing, disabled, selectedIndex]);

  const renderSuggestionItem = (suggestion, suggestionIndex) => (
    <li
      key={hash(suggestion)}
      className={`suggestion-item ${suggestionIndex === selectedIndex ? 'selected' : ''}`}
      onMouseDown={() => onChange(suggestion[returnOption])}
    >
      <span className="primary-item">{suggestion.optionOne}</span>
      {
          suggestion.optionTwo ? (
            <div className="upper-items">
              <span className="secondary-item">{suggestion.optionTwo || ''}</span>
              {
                suggestion.optionThree ? (
                  <span className="ox-badge ox-badge--has-border tertiary-item">
                    {suggestion.optionThree || ''}
                  </span>
                ) : null
              }
            </div>
          ) : null
        }
    </li>
  );

  const typeaheadSuggestions = useMemo(() => {
    if (suggestions && isEditing && !disabled) {
      const suggestionsToUse = suggestions.slice(0, 5);

      setMaxOptions(suggestionsToUse.length);
      if (selectedIndex >= suggestionsToUse.length && suggestionsToUse.length > 0) {
        setSelectedIndex(suggestionsToUse.length - 1);
      }
      return (
        <ul className="typeahead-suggestions">
          { suggestionsToUse.map((suggestion, i) => renderSuggestionItem(suggestion, i)) }
        </ul>
      );
    }
    return null;
  }, [suggestions, selectedIndex, isEditing, disabled]);

  const renderTextField = useMemo(() => (
    <input
      disabled={disabled}
      type="text"
      ref={inputField}
      className={` annotation-text ${isEmpty ? 'empty' : ''} ${isEditingClass}`}
      value={value}
      required={required}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={({ target: { value: changedValue } }) => onChange(changedValue)}
    />
  ), [value, isEditing, isRowHovered, isEmpty, selectedIndex, hasMultipleAnnotations, suggestions, disabled, required]);

  const renderParagraph = useMemo(() => (
    <p
      className="annotation-text"
      onClick={handleFocus}
    >
      {(value || '').toString()}
    </p>
  ), [value, disabled]);

  const valueItem = useMemo(() => {
    if (!isEmpty) {
      return (
        <div className={`edit-container ${hasMultipleAnnotations ? 'metro' : ''}`}>
          <div className="metro-line">
            <span style={metroStyle} className={`line ${isLast ? 'last' : ''}`} />
            <span style={metroStyle} className="dot" />
          </div>
          <div className={`typeahead-container ${isEditingClass}`}>
            {!isEditing ? renderParagraph : renderTextField}
            {typeaheadSuggestions}
          </div>
          <HoverButtons
            disabled={disabled}
            removeEnabled={removeEnabled}
            isEmpty={isEmpty}
            isRowHovered={isRowHovered}
            isEditing={isEditing}
            handleFocus={handleFocus}
            onRemove={onRemove}
          />
        </div>
      );
    }
    return null;
  }, [value, isEditing, isRowHovered, isEmpty, selectedIndex, hasMultipleAnnotations, suggestions, disabled, removeEnabled, onRemove, handleFocus]);

  const extraInfoContainer = useMemo(() => {
    if (extraInfo && !isEditing) {
      return <span className="ox-form__feedback no-error">{extraInfo}</span>;
    }
    return null;
  }, [extraInfo, isEditing]);

  return (
    <>
      <EditContainer
        name={name}
        showName={showName}
        disabled={disabled}
        isEmpty={isEmpty}
        isRowHovered={isRowHovered}
        isEditing={isEditing}
        hasMultipleAnnotations={hasMultipleAnnotations}
        handleFocus={handleFocus}
      />
      {valueItem}
      {extraInfoContainer}
    </>
  );
});
