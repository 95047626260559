import { useState, useEffect, useCallback } from 'react';

const useQueue = () => {
  const [queue, setQueue] = useState([]);
  const [queueLength, setQueueLength] = useState(0);

  useEffect(() => {
    setQueueLength(queue.length);
  }, [queue]);

  const addMessageToQueue = useCallback((message) => {
    setQueue([...queue, message]);
  }, [queue]);

  const getMessageFromQueue = useCallback(() => {
    const temp = [...queue];
    const message = temp.shift();

    setQueue(temp);
    return message;
  }, [queue]);

  return { queueLength, addMessageToQueue, getMessageFromQueue };
};

export default useQueue;
