export const containsOnly = (array1, array2) => array2.every((elem) => array1.includes(elem));

export const sortJsonArrayByKey = (array, sortKey = 'index') => array.sort((a, b) => {
  if (a[sortKey] > b[sortKey]) {
    return 1;
  }
  if (b[sortKey] > a[sortKey]) {
    return -1;
  }
  return 0;
});

export const addValue = (obj, key, value) => {
    if (obj.hasOwnProperty(key)) {
        // check if it's already an array using the recommended way of detecting an array
        if (Object.prototype.toString.call(obj[key]) === "[object Array]") {
            obj[key].push(value);
        } else {
            var firstVal = obj[key];
            obj[key] = [firstVal, value];
        }
    } else {
        obj[key] = value;
    }
    return obj
};
