import * as customerTypes from '../actions/customer/customerTypes';

const initialState = {
  customers: [],
  loading: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case customerTypes.FETCH_CUSTOMERS:
      return { ...state, loading: true };

    case customerTypes.FETCH_CUSTOMERS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        loading: false,
        customers: data,
      };
    }
    case customerTypes.FETCH_CUSTOMERS_FAILED: {
      const { error } = action;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case customerTypes.FETCH_CUSTOMER_BY_ID_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        loading: false,
        customers: [data],
      };
    }

    case customerTypes.FETCH_CUSTOMER_BY_ID_FAILED: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
};
