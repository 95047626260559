import axios from 'axios';
import * as machineTypes from './machineTypes';
import { authenticationHeader, baseUrl } from '../../constants/constants';
import { invalidApiKey } from '../user/userActions';
import { history } from '../../components/App';
import { tokenIsValid } from '../../helpers/accountHelpers';

const fetchMachinesLoading = () => ({ type: machineTypes.FETCH_MACHINES });
const fetchMachinesSuccess = (data) => ({ type: machineTypes.FETCH_MACHINES_SUCCESS, data });
const fetchMachinesFailed = (error) => ({ type: machineTypes.FETCH_MACHINES_FAILED, error });

export const fetchOptionsSuccess = (data) => ({ type: machineTypes.FETCH_OPTIONS, data });
export const fetchAttachmentsSuccess = (data) => ({ type: machineTypes.FETCH_ATTACHMENTS, data });

export const fetchMachineOptions = (machineCode, data) => ({ type: machineTypes.FETCH_MACHINE_OPTIONS, machineCode, data });
export const fetchMachineAttachments = (machineCode, data) => ({ type: machineTypes.FETCH_MACHINE_ATTACHMENTS, machineCode, data });

export const clearMachineAttachmentsAndOptions = () => ({ type: machineTypes.CLEAR_MACHINE_ATTACHMENTS_AND_OPTIONS });

export function fetchMachines() {
  return (dispatch) => {
    dispatch(fetchMachinesLoading());

    return axios.get(`${baseUrl}/machines`, authenticationHeader)
      .then((response) => {
        dispatch(fetchMachinesSuccess(response.data));
      }, (error) => {
        if (error.response.status && error.response.status === 401) {
          if (!tokenIsValid()) {
            dispatch(invalidApiKey());
            history.push('/login');
          } else {
            history.push('/');
          }
        }
        dispatch(fetchMachinesFailed(error));
      });
  };
}


export const fetchMachineAttachmentsAndOptions = (machineCode) => (dispatch) => axios.get(`${baseUrl}/machines/${machineCode}`, authenticationHeader)
  .then((response) => {
    dispatch(fetchMachineOptions(machineCode, response.data.options));
    dispatch(fetchMachineAttachments(machineCode, response.data.attachments));
  });
