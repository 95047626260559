import { useEffect, useState, useCallback } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { authenticationHeader, baseUrl } from '../constants/constants';

const useWebSocket = (action = null) => {
  const [socketConnected, setSocketConnected] = useState(false);
  const [stompClient, setStompClient] = useState(null);

  const sendMessage = (topic, message) => {
    if (stompClient) {
      stompClient.send(`/app/${topic}`, {}, JSON.stringify(message));
    }
  };

  const handleMessage = useCallback((msg) => {
    if (action && msg) {
      // console.log('%cINCOMING MSG', 'background-color: #da972f; padding: 5px; border-radius: 3px; font-weight: bold; color: white', msg);
      // We add a random timeout to make sure messages don't have a race condition and get lost
      setTimeout(() => {
        action({ id: msg.headers['message-id'], topic: msg.headers.destination.replace('/topic/', ''), body: JSON.parse(msg.body) });
      }, Math.floor(Math.random() * 200) + 50);
    }
  }, []);

  const disconnectSocket = (client) => {
    if (client) {
      client.disconnect();
      // console.log('%cDISCONNECTED', 'background-color: #f44336; padding: 5px; border-radius: 3px; font-weight: bold; color: white');
    }
    setStompClient(null);
    setSocketConnected(false);
  };

  const subscribeTopic = useCallback((topic) => {
    if (stompClient) {
      stompClient.subscribe(`/topic/${topic}`, handleMessage, { id: `topic-id-${topic}` });
    }
  }, [stompClient]);

  const unsubscribeTopic = useCallback((topic) => {
    if (stompClient) {
      stompClient.unsubscribe(`topic-id-${topic}`);
    }
  }, [stompClient]);

  useEffect(() => {
    const client = Stomp.client(`${baseUrl}/live`);

    client.heartbeat.incoming = 20000;
    client.heartbeat.outgoing = 20000;

    client.webSocketFactory = () => new SockJS(`${baseUrl}/live`);

    // Disable websocket console logging
    client.debug = () => {};

    client.reconnect_delay = 5000;

    client.connect(authenticationHeader, () => {
      // console.log('%cCONNECTED', 'background-color: #1dc67f; padding: 5px; border-radius: 3px; font-weight: bold; color: white');
      setStompClient(client);
      setSocketConnected(true);
    });

    return () => {
      // Code for cleanup
      disconnectSocket(client);
    };
  }, []);

  return {
    socketConnected,
    subscribeTopic,
    unsubscribeTopic,
    sendMessage,
  };
};

export default useWebSocket;
