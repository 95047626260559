import React from 'react';
import './DocumentControls.scss';
import { useTranslation } from 'react-i18next';
import LoadingControls from './LoadingControls';

const DocumentControls = ({
  approve,
  archive,
  hold,
  cancel,
  disabled,
  approveDisabled,
  isLoading,
}) => {
  const { t } = useTranslation('annotationView');
  return (
    <div className="bottom-controls">
      <div />
      {
        disabled || isLoading
          ? <LoadingControls loading={isLoading} />
          : (
            <div className="ox-button-group right">
              <button
                type="button"
                onClick={cancel}
                className="ox-button ox-button--secondary"
              >
                <span className="ox-icon ox-icon--undo" />
                {t('controls.cancel')}
              </button>
              <button
                type="button"
                onClick={hold}
                className="ox-button ox-button--secondary"
              >
                <span className="ox-icon ox-icon--pause" />
                {t('controls.hold')}
              </button>
              <button
                type="button"
                onClick={archive}
                className="ox-button ox-button--primary"
              >
                <span className="ox-icon ox-icon--archive" />
                {t('controls.archive')}
              </button>
              <button
                type="button"
                onClick={approve}
                disabled={approveDisabled}
                className="ox-button ox-button--primary"
              >
                <span className="ox-icon ox-icon--check" />
                {t('controls.approve')}
              </button>
            </div>
          )
      }
    </div>
  );
};

export default DocumentControls;
