export const UPDATE_ANNOTATIONS = 'UPDATE_ANNOTATIONS';
export const OVERWRITE_ANNOTATIONS = 'OVERWRITE_ANNOTATIONS';

export const CLEAR_ANNOTATIONS = 'CLEAR_ANNOTATIONS';
export const REMOVE_ANNOTATIONS_SUCCESS = 'REMOVE_ANNOTATIONS_SUCCESS';
export const REMOVE_ANNOTATIONS_BY_ENTITY_ID = 'REMOVE_ANNOTATIONS_BY_ENTITY_ID';
export const REMOVE_ANNOTATIONS_BY_GROUP = 'REMOVE_ANNOTATIONS_BY_GROUP';

export const FETCH_ANNOTATIONS = 'FETCH_ANNOTATIONS';
export const FETCH_ANNOTATIONS_SUCCESS = 'FETCH_ANNOTATIONS_SUCCESS';
export const FETCH_ANNOTATIONS_FAILED = 'FETCH_ANNOTATIONS_FAILED';

export const SAVE_ANNOTATIONS_SUCCESS = 'SAVE_ANNOTATIONS_SUCCESS';
export const SAVE_ANNOTATIONS_FAILED = 'SAVE_ANNOTATIONS_FAILED';

export const FETCH_TEXT = 'FETCH_TEXT';

export const RESET_STATE = 'RESET_STATE';
