import * as configMapTypes from '../actions/configMap/configMapTypes';

const initialState = {
  configMap: null,
  loading: false,
  error: null,
};

const configMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case configMapTypes.FETCH_CONFIG_MAP:
      return { ...state, loading: true };
    case configMapTypes.FETCH_CONFIG_MAP_SUCCESS:
      return { ...state, loading: false, configMap: action.data };
    case configMapTypes.FETCH_CONFIG_MAP_FAILED:
      return { ...state, loading: false, error: action.error };
    case configMapTypes.EDIT_CONFIG_MAP_PROPERTY: {
      const {
        id, groupIndex, isRequired, activeTabIndex,
      } = action.data;
      const { configMap } = state;
      const { entityTypes } = configMap[activeTabIndex].groupBlocks[groupIndex];
      const foundEntityType = entityTypes.find((item) => item.id === id);
      const newEntities = entityTypes.filter((item) => item.id !== id);
      foundEntityType.isRequired = isRequired;
      newEntities.push(foundEntityType);
      configMap.entities = newEntities;
      return {
        ...state,
        configMap,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export { configMapReducer as default };
