import * as keyEventTypes from './keyEventTypes';

const enableKeyEvent = () => ({ type: keyEventTypes.ENABLE_KEY_EVENT });
const disableKeyEvent = () => ({ type: keyEventTypes.DISABLE_KEY_EVENT });

export function enableKeyEventListener() {
  return (dispatch) => {
    dispatch(enableKeyEvent());
  };
}

export function disableKeyEventListener() {
  return (dispatch) => {
    dispatch(disableKeyEvent());
  };
}
