import React, {
  useMemo,
} from 'react';
import './EntityRow.scss';
import { useTranslation } from 'react-i18next';

const EditContainer = ({
  name,
  showName,
  disabled,
  isEmpty,
  isRowHovered,
  isEditing,
  hasMultipleAnnotations,
  handleFocus,
  isTextArea,
  onRemove,
  showRemoveButton,
}) => {
  const { t } = useTranslation('annotationView');
  const containerClass = (baseClass) => {
    const editingClass = (isEditing && !hasMultipleAnnotations && !isTextArea) ? 'editing' : '';
    const heightClass = (isEmpty || hasMultipleAnnotations || isTextArea) ? 'full-height' : '';
    return `${baseClass} ${editingClass} ${heightClass}`;
  };

  const isEditingClass = (isEditing ? 'editing' : '');

  const editContainer = useMemo(() => {
    if (!isEmpty && showName) {
      return (
        <div className={`${containerClass('entity-name')} `}>
          {t(`entities.${name}`).replace('entities.', '')}
          {
            (!disabled && showRemoveButton)
              ? (
                <span
                  aria-label="Remove this line"
                  className="ox-icon ox-icon--close name-remove-button"
                  role="button"
                  onClick={onRemove}
                />
              ) : null
          }
        </div>
      );
    }

    if (showName) {
      return (
        <div className={`${containerClass('entity-name')} ${disabled ? 'disable-typeahead' : ''}`}>
          {t(`entities.${name}`).replace('entities.', '')}
          {
            !disabled && !isTextArea
              ? (
                <span
                  role="button"
                  onClick={() => handleFocus()}
                  className={`edit-icon ox-icon ox-icon--edit ${isEditingClass} ${!isEditing ? 'shown' : ''}`}
                />
              )
              : null
          }
        </div>
      );
    }
    return null;
  }, [name, isEmpty, isEditing, isRowHovered, disabled, showName, hasMultipleAnnotations, containerClass, showRemoveButton]);

  return (
    editContainer
  );
};

export default EditContainer;
