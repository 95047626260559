import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as feedbackActionFile from '../../actions/feedback/feedbackActions';
import './Toast.scss';
import ToastItem from './ToastItem';
import calculateHideDelay from '../../helpers/feedbackHelper';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(feedbackActionFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({ feedbackReducer, actions }) => (
  <div className="feedback-container">
    {
      feedbackReducer.messages.map((message) => (
        <ToastItem
          delay={calculateHideDelay(message.message)}
          key={`${message.id}`} // eslint-disable-line
          remove={() => actions.removeMessage(message.id)}
          message={message}
          autoRemove={message.autoRemove}
        />
      ))
    }
  </div>
));
