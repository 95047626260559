import React from 'react';

const labelPriorityMapping = {
  prio: 'ox-badge--error',
  dayaftertomorrow: 'ox-badge--warning',
  normal: 'ox-badge--update',
};

export default ({ label }) => (
  <span className={`ox-badge ox-badge--large ${label ? labelPriorityMapping[label.toLowerCase().replace(/\s/g, '')] || '' : ''} ox-badge--has-border`}>
    {label}
  </span>
);
