import React from 'react';
import './InfoIcon.scss';

const InfoIcon = ({ className, onClick, count }) => (
  <div className="info-icon-container">
    <span
      aria-label="Button with extra info"
      className={className}
      role="button"
      onClick={onClick}
    />
    {
      count > 0 ? (
        <span className="extra-info-bubbel">{count}</span>
      ) : null
    }
  </div>
);

export default InfoIcon;
