import moment from 'moment';
import store from '../store/index';
import {
  DELIVERY_DATE_ENTITY_ID,
  DELIVERY_HOUR_ENTITY_ID, ENTITY_IDS_THAT_HAVE_MINIMUM_LENGTH,
  ENTITY_IDS_THAT_SHOULD_BE_DATES,
  ENTITY_IDS_THAT_SHOULD_BE_NUMBERS,
  MACHINE_DELIVERY_DATE_ENTITY_ID,
  MACHINE_DESCRIPTION_ID,
  MACHINE_PICKUP_DATE_ENTITY_ID,
  MACHINE_TYPE_ENTITY_ID,
  PICKUP_DATE_ENTITY_ID,
  PICKUP_HOUR_ENTITY_ID,
} from '../constants/constants';

const getActiveOrder = () => {
  const state = store.getState();
  const {
    orderReducer: {
      activeOrder,
    },
  } = state;
  return activeOrder;
};

export const shouldBeNumeric = (entity) => {
  if (!entity) {
    return false;
  }
  return ENTITY_IDS_THAT_SHOULD_BE_NUMBERS.includes(entity.id);
};

export const isValidNumber = (entity, value) => {
  if (!shouldBeNumeric(entity) || !(value && value[0])) {
    return true;
  }
  const parsedValue = Math.floor(Number(value));
  return Math.sign(parsedValue) > 0;
};

const changeMachineTypeRequired = (foundValue, value, index) => {
  if (foundValue && foundValue.value && foundValue.value[index] && foundValue.value[index][0]) {
    return false;
  }
  return !(value && value[0]);
};

const checkMachineType = (entity, value, index) => {
  const foundValue = getActiveOrder().find((item) => item.entityId === MACHINE_DESCRIPTION_ID) || {};
  return changeMachineTypeRequired(foundValue, value, index);
};

const checkMachineDescription = (entity, value, index) => {
  if (entity.entityId === MACHINE_TYPE_ENTITY_ID) {
    const foundValue = getActiveOrder().find((item) => item.entityId === MACHINE_DESCRIPTION_ID) || {};
    return changeMachineTypeRequired(foundValue, value, index);
  }
  if (entity.entityId === MACHINE_DESCRIPTION_ID) {
    return checkMachineType(entity, value, index);
  }
  return false;
};

export const checkIfRequiredAndEmptyValue = (requiredValue, value, entity, index) => {
  if (!entity.isRequired && requiredValue === false) {
    return false;
  }

  if (entity.entityId === MACHINE_TYPE_ENTITY_ID || entity.entityId === MACHINE_DESCRIPTION_ID) {
    return checkMachineDescription(entity, value, index);
  }

  return !(value && value[0]);
};

export const checkMultiValues = (entity, value) => {
  if (!value || entity.multipleValues) {
    return false;
  }
  return Object.keys(value).length > 1;
};

export const checkIfMinLengthIsMet = (entity, value) => {
  if (!(value && value[0]) || !ENTITY_IDS_THAT_HAVE_MINIMUM_LENGTH[entity.id]) {
    return true;
  }

  return value[0].toString().length >= ENTITY_IDS_THAT_HAVE_MINIMUM_LENGTH[entity.id];
};

export const checkIfDateIsFuture = (entity, value) => {
  if (!(value && value[0]) || !ENTITY_IDS_THAT_SHOULD_BE_DATES.includes(entity.id)) {
    return true;
  }
  const possibleFutureDate = moment(value[0], 'DD/MM/YYYY').toDate();
  const now = moment().toDate();

  // Set the possible future date to 1 because the date can be today.
  possibleFutureDate.setHours(1, 0, 0, 0);
  now.setHours(0, 0, 0, 0);

  return possibleFutureDate > now;
};

/**
 * Find the delivery date and the pickup date from the active order
 * @return {deliveryDate, pickupDate} | {}
 */
const getDeliveryAndPickupDate = () => {
  const activeOrder = getActiveOrder();
  const foundDeliveryDateEntity = activeOrder.find((item) => item.entityId === DELIVERY_DATE_ENTITY_ID) || {};
  const { value: deliveryDateValue } = foundDeliveryDateEntity;
  const deliveryDate = Object.values(deliveryDateValue || {}).flat()[0];
  const foundPickupDateEntity = activeOrder.find((item) => item.entityId === PICKUP_DATE_ENTITY_ID) || {};
  const { value: pickupDateValue } = foundPickupDateEntity;
  const pickupDate = Object.values(pickupDateValue || {}).flat()[0];
  return {
    deliveryDate,
    pickupDate,
  };
};

/**
 * Find the delivery hour and the pickup hour from the active order
 * @returns {deliveryDate, pickupDate} | {}
 */
const getDeliveryAndPickupHour = () => {
  const activeOrder = getActiveOrder();
  const foundDeliveryHourEntity = activeOrder.find((item) => item.entityId === DELIVERY_HOUR_ENTITY_ID) || {};
  const { value: deliveryHourValue } = foundDeliveryHourEntity;
  const deliveryHour = Object.values(deliveryHourValue || {}).flat()[0];
  const foundPickupHourEntity = activeOrder.find((item) => item.entityId === PICKUP_HOUR_ENTITY_ID) || {};
  const { value: pickupHourValue } = foundPickupHourEntity;
  const pickupHour = Object.values(pickupHourValue || {}).flat()[0];
  return {
    deliveryHour,
    pickupHour,
  };
};

export const checkIfDeliveryHourIsInFuture = (entity) => {
  if (!entity || entity.id !== DELIVERY_HOUR_ENTITY_ID) {
    return true;
  }

  const { deliveryDate } = getDeliveryAndPickupDate();
  if (!deliveryDate) {
    return true;
  }

  const { deliveryHour } = getDeliveryAndPickupHour();
  if (!deliveryHour) {
    return true;
  }

  const possibleFutureDateTime = moment(`${deliveryDate} ${deliveryHour}`, 'DD/MM/YYYY hh:mm').toDate();
  const now = moment().toDate();

  return possibleFutureDateTime > now;
};

/**
 * This function validates if the pickup hour is after or equal to the delivery hour
 * if the pickup date and the delivery date are equal.
 * @returns boolean
 */
export const checkIfPickupHourIsAfterDeliveryHour = (entity) => {
  if (!entity || entity.id !== PICKUP_HOUR_ENTITY_ID) {
    return false;
  }
  const { deliveryDate, pickupDate } = getDeliveryAndPickupDate();

  if (!deliveryDate || !pickupDate) {
    return false;
  }

  const { deliveryHour, pickupHour } = getDeliveryAndPickupHour();
  if (!deliveryHour || !pickupHour) {
    return false;
  }

  const now = moment().toDate().getTime();

  if (deliveryDate === pickupDate) {
    return deliveryHour > pickupHour && moment(deliveryHour, 'hh:mm') > now;
  }
  return false;
};

/**
 * This function validates if the pickup date is after or equal to the delivery date.
 * @returns boolean
 */
export const checkPickupAndDeliveryDate = (entity) => {
  if (!entity || entity.id !== PICKUP_DATE_ENTITY_ID) {
    return false;
  }
  const { deliveryDate, pickupDate } = getDeliveryAndPickupDate();
  if (!deliveryDate || !pickupDate) {
    return false;
  }

  return moment(pickupDate) < moment(deliveryDate);
};

/**
 * Find the delivery datetime and the pickup datetime from the active order
 * @returns {machineDeliveryDateTime, machinePickupDateTime} | {}
 */
const getDeliveryAndPickupDateTime = (indexToCheck) => {
  const activeOrder = getActiveOrder();
  const foundMachineDeliveryDateEntity = activeOrder.find((item) => item.entityId === MACHINE_DELIVERY_DATE_ENTITY_ID) || {};
  const { value: machineDeliveryDateValue } = foundMachineDeliveryDateEntity;
  const machineDeliveryDateTime = (machineDeliveryDateValue || { [indexToCheck]: [null] })[indexToCheck][0];
  const foundMachinePickupDateEntity = activeOrder.find((item) => item.entityId === MACHINE_PICKUP_DATE_ENTITY_ID) || {};
  const { value: machinePickupDateValue } = foundMachinePickupDateEntity;
  const machinePickupDateTime = (machinePickupDateValue || { [indexToCheck]: [null] })[indexToCheck][0];
  if (!machineDeliveryDateTime || !machinePickupDateTime) {
    return {};
  }
  return {
    machineDeliveryDateTime,
    machinePickupDateTime,
  };
};

/**
 * compare the delivery datetime and the pickup datetime.
 * @returns boolean
 */
export const checkDateTimeValues = (entity, indexToCheck) => {
  if (!entity || entity.id !== MACHINE_PICKUP_DATE_ENTITY_ID) {
    return false;
  }
  const {
    machineDeliveryDateTime,
    machinePickupDateTime,
  } = getDeliveryAndPickupDateTime(indexToCheck);
  if (!machineDeliveryDateTime || !machinePickupDateTime) {
    return false;
  }
  const formattedDeliveryDate = moment(machineDeliveryDateTime, 'DD/MM/YYYY[T]HH:mm').toDate();
  const formattedPickupDate = moment(machinePickupDateTime, 'DD/MM/YYYY[T]HH:mm').toDate();
  return formattedDeliveryDate >= formattedPickupDate;
};
