import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SearchBar.scss';

const SearchBar = ({
  onChange
}) => {
  const { t } = useTranslation('overview');

  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const inputField = useRef();

  useEffect(() => {
    if (!isFocused) {
      onChange(searchValue);
    }
  }, [isFocused, searchValue, onChange]);

  const handleSearch = () => {
    if (searchValue) {
      inputField.current.blur();
    }
  };

  const handleKeyEvent = (event) => {
    if (searchValue) {
      switch (event.key) {
        case 'Enter': {
          event.preventDefault();
          handleSearch();
          break;
        }
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  return (
    <div className="search-bar">
      <input
        ref={inputField}
        type="text"
        placeholder={t('searchbar')}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <div className="line-input" />
      {
        searchValue && !isFocused ? (
          <button type="button" onClick={() => setSearchValue('')}>
            <span className="ox-icon ox-icon--close search-icon" />
          </button>
        ) : (
          <button type="button" onClick={() => handleSearch()}>
            <span className="ox-icon ox-icon--search search-icon" />
          </button>
        )
      }
    </div>
  );
};

export default SearchBar;
