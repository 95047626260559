import React from 'react';
import OrderItem from './OrderItem';

const isArchivedTab = (ToggleValue) => ToggleValue === 1;

const DocumentList = ({
  Mails, OnRowClick, ToggleValue, isLoading, handleUnassignOrder,
}) => Mails.map((mail) => (
  <OrderItem
    key={mail.id}
    mail={mail}
    isLoading={isLoading}
    handleMailClicked={OnRowClick}
    handleUnassigned={handleUnassignOrder}
    archivedTab={isArchivedTab(ToggleValue)}
  />
));

export default DocumentList;
