import axios from 'axios';
import * as annotationTypes from './annotationTypes';
import {
  authenticationHeader,
  baseUrl,
  DIFFERENT_DOCUMENT_ERROR,
  DUPLICATE_ANNOTATION_ERROR,
  OUT_OF_BOUNDS_ERROR,
  TEXT_IDS_EMPTY,
} from '../../constants/constants';
import { invalidApiKey } from '../user/userActions';
import { history } from '../../components/App';
import { tokenIsValid } from '../../helpers/accountHelpers';
import { addErrorMessage } from '../feedback/feedbackActions';
import { logError } from '../../services/loggingService';

const fetchAnnotationsLoading = () => ({ type: annotationTypes.FETCH_ANNOTATIONS });
const fetchAnnotationsSuccess = () => ({ type: annotationTypes.FETCH_ANNOTATIONS_SUCCESS });
const fetchAnnotationsFailed = (error) => (
  { type: annotationTypes.FETCH_ANNOTATIONS_FAILED, error }
);

const saveAnnotationsSuccess = () => ({ type: annotationTypes.REMOVE_ANNOTATIONS_SUCCESS });

export const handleAnnotationChange = (marks, documentId) => (
  {
    type: annotationTypes.UPDATE_ANNOTATIONS,
    marks,
    documentId,
  }
);

export const overwriteAnnotations = (mailDocuments, entityTypes) => (
  {
    type: annotationTypes.OVERWRITE_ANNOTATIONS,
    mailDocuments,
    entityTypes,
  }
);

export const clearAnnotations = () => ({
  type: annotationTypes.CLEAR_ANNOTATIONS,
});

export const removeAnnotationsSuccess = () => ({ type: annotationTypes.REMOVE_ANNOTATIONS_SUCCESS })

export const removeAnnotationsForEntityId = (entityId, index, orderIndex = '1') => ({
  type: annotationTypes.REMOVE_ANNOTATIONS_BY_ENTITY_ID,
  entityId,
  index,
  orderIndex,
});

export const removeAnnotationsForGroup = (configMap, indexToRemove, groupName) => ({
  type: annotationTypes.REMOVE_ANNOTATIONS_BY_GROUP,
  configMap,
  indexToRemove,
  groupName,
});

export const saveAnnotations = (documents) => (dispatch) => {
  let promises = []
  for (const [documentId, document] of Object.entries(documents)) {
    const marks = document.marks
    const localMarks = marks.map((mark) => ({
      entityTypeId: mark.entity.id,
      index: mark.entity.index,
      page: mark.page,
      scores: mark.scores,
      textIds: mark.textIds,
      tokens: mark.tokens,
    }));

    const promise = axios.post(`${baseUrl}/entity-annotation/${documentId}/batch`, localMarks, authenticationHeader);
    promise.then((result) => {
      dispatch(saveAnnotationsSuccess());
      return result;
    }, (error) => {
      dispatch(addErrorMessage('Failed to save annotations.'));
      return error;
    });
    promises.push(promise)
  }
  return promises;
};

export const resetAnnotationsState = () => ({
  type: annotationTypes.RESET_STATE,
});
