import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../helpers/date-helpers';
import { addErrorMessage } from '../../actions/feedback/feedbackActions';
import { addToMetrics } from '../../actions/metricsReport/metricsReportActions';
import Avatar from '../avatar/Avatar';
import TableDropdownMenu from './TableDropdownMenu';
import { getIdOfUser } from '../../helpers/accountHelpers';
import ProgressLabel from './progressLabel/ProgressLabel';
import FlagLabel from '../flagLabel/FlagLabel';
import SiteIndicator from './SiteIndicator';
import PriorityLabel from '../priorityLabel/PriorityLabel';
import { sendMailBack } from '../../services/mailService';
import { siteColorMapping } from '../../constants/constants';
import useLocalStorage from '../../hooks/useLocalStorage';
import './OrderItem.scss';

const OrderItem = ({
  mail,
  isLoading,
  handleMailClicked,
  handleUnassigned,
  archivedTab = false,
}) => {
  const { t } = useTranslation('overview');
  const [fadeOut, setFadeOut] = useState(false);
  const { getValue: getActiveUser } = useLocalStorage('active-user');

  const dispatch = useDispatch();

  const isDisabled = () => !archivedTab && (mail.employee && mail.employee.id !== getIdOfUser(getActiveUser()));

  const handleSendBack = async () => {
    const userId = getIdOfUser(getActiveUser());
    const sendBackResponse = await sendMailBack(mail.id, userId);
    if (sendBackResponse) {
      setFadeOut(true);
      dispatch(addToMetrics(userId, mail.id, 'SEND_BACK'));
    } else {
      dispatch(addErrorMessage('Failed to send mail back to O365, try again later.'));
    }
  };

  return (
    <div
      className={`order-item-container ${isDisabled() ? 'disable-row' : ''} ${fadeOut ? 'scale-out-ver-top' : ''}`}
      onClick={isDisabled() ? null : () => handleMailClicked(mail)}
    >
      <SiteIndicator color={siteColorMapping[mail.branchOffice.id]} />
      <div className="order-item-assigned-info">
        <Avatar name={mail.employee ? mail.employee.username : ''} />
      </div>
      <div className="order-item-customer-info">
        <div className="order-item-meta-info">
          <h3>{mail.subject}</h3>
          <div>
            <h6>{formatDateTime(mail.timestamp, 'DD/MM/YYYY, HH:mm')}</h6>
          </div>
        </div>
        <label className="meta-info-subject">{(mail.sender || mail.id)}</label>
      </div>
      <div className="order-item-button">
        <div className="order-item-button-importance-info">
          { (!archivedTab && mail.flag.toLowerCase() !== 'notflagged') ? <FlagLabel label={mail.flag} /> : null }
          { !archivedTab && mail.priority ? <PriorityLabel label={mail.priority.name} /> : null }
          {
            archivedTab ? (
              <ProgressLabel
                statusId={mail.status.id}
                status={(mail.employee || {}).username ? 'done' : (t(`status.${mail.status.id}`) || 'To Do')}
                processed={`${(mail.employee || {}).username} - ${formatDateTime(mail.processedAt, 'DD/MM/YYYY, HH:mm')}`}
              />
            ) : null
          }
        </div>
        <div className="order-item-status-info">
          {
            !archivedTab ? (
              <ProgressLabel
                statusId={mail.status.id}
                status={mail.processedAt ? 'done' : (t(`status.${mail.status.id}`) || 'To Do')}
              />
            ) : null
          }
        </div>
      </div>
      {
        !archivedTab ? (
          <div
            role="button"
            className="order-item-extra-options"
            onClick={(event) => event.stopPropagation()}
          >
            <TableDropdownMenu
              isLoading={isLoading}
              mail={mail}
              disabled={isDisabled(mail)}
              handleSendBack={() => handleSendBack()}
              handleRemoveStatus={() => handleUnassigned(mail.id)}
            />
          </div>
        ) : null
      }
    </div>
  );
};

export default OrderItem;
