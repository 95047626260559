import axios from 'axios';
import * as documentTypes from './documentTypes';
import { authenticationHeader, baseUrl } from '../../constants/constants';
import { invalidApiKey } from '../user/userActions';
import { history } from '../../components/App'; // eslint-disable-line
import { addErrorMessage } from '../feedback/feedbackActions';
import { tokenIsValid } from '../../helpers/accountHelpers';

const fetchDocumentTypesSuccess = (data) => ({ type: documentTypes.FETCH_DOCUMENT_TYPES_SUCCESS, data });
const fetchDocumentTypesFailed = (error) => ({ type: documentTypes.FETCH_DOCUMENT_TYPES_FAILED, error });
const fetchDocumentTypesLoading = () => ({ type: documentTypes.FETCH_DOCUMENT_TYPES });

export const changeSelectedDocumentType = (selectedDocumentTypeId) => ({
  type: documentTypes.CHANGE_SELECTED_DOCUMENT_TYPE,
  id: selectedDocumentTypeId,
});

export function fetchDocumentTypes() {
  return (dispatch) => {
    dispatch(fetchDocumentTypesLoading());

    return axios.get(`${baseUrl}/document-type`, authenticationHeader)
      .then((response) => {
        const fetchedDocumentTypes = response.data;

        // We want to filter out all null values from the document types
        const result = fetchedDocumentTypes.map((documentType) => {
          const cleanedDocumentType = {};

          Object.keys(documentType).forEach((property) => {
            if (documentType[property] !== null) {
              cleanedDocumentType[property] = documentType[property];
            }
          });

          return cleanedDocumentType;
        });
        dispatch(fetchDocumentTypesSuccess(result));
        return result;
      }, (error) => {
        if (error.response.status && error.response.status === 401) {
          if (!tokenIsValid()) {
            dispatch(invalidApiKey());
            history.push('/login');
          } else {
            history.push('/');
          }
        }
        dispatch(fetchDocumentTypesFailed(error));
        dispatch(addErrorMessage(error.response.data.error.message));
      });
  };
}
