import React from 'react';
import './Modal.scss';

export default ({
  Content,
  Open,
  Close,
  HideCloseButton,
  Wide,
}) => (
  <dialog
    className={`ox-modal generic-modal ${Wide ? 'wide' : ''}`}
    aria-labelledby="ox-modal-label"
    aria-modal="true"
    open={Open}
  >
    {
      HideCloseButton
        ? null
        : (
          <button
            type="button"
            onClick={Close}
            className="ox-modal__close"
            aria-label="Close the modal"
            data-modal-trigger="dox-modal-example"
          >
            <span className="ox-icon ox-icon--close ox-icon--small" />
          </button>
        )
    }
    {Content}
  </dialog>
);
