import moment from 'moment';

// Legacy functionality, use localStorage hook instead
export function getActiveUserFromLocalstorage() {
  if (localStorage.getItem('active-user')) {
    return JSON.parse(localStorage.getItem('active-user'));
  }
  return null;
}

export function getInitialsFromName(name) {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.shift() || '')).toUpperCase();
}

export function getIdOfUser(user) {
  if (user) {
    return user.id;
  }
  return null;
}

export function getOfficeIdOfUser(user) {
  if (user) {
    return user.officeId;
  }
  return null;
}

export function getApiKeyFromActiveUser() {
  const user = getActiveUserFromLocalstorage();
  if (user) {
    return user['api-key'];
  }
  return null;
}

export function getLanguageFromUser(user) {
  if (user) {
    return user.language;
  }
  return null;
}

export function tokenIsValid(user) {
  let activeUser;
  if (user) {
    activeUser = user;
  } else {
    activeUser = getActiveUserFromLocalstorage();
  }

  if (activeUser && activeUser['api-key']) {
    const { exp } = JSON.parse(atob(activeUser['api-key'].split('.')[1]));
    const expireDate = moment.unix(exp).toDate();
    const now = moment().toDate();

    return expireDate > now;
  }
  return false;
}

export function checkIfAdmin(user) {
  if (user) {
    return user.roles.toLowerCase() === 'admin';
  }
  return false;
}

export function checkIfUser(user) {
  if (user) {
    return user.roles.toLowerCase() === 'user';
  }
  return false;
}
