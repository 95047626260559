import React, { useCallback } from 'react';
import './EntitiesSidebarSkeleton.scss';
import './SkeletonAnimation.scss';

export default () => {
  const groups = [1, 8, 5, 5];

  const generateSkeletonBlock = useCallback((rows, index) => {
    const skeletonRows = Array(rows).fill(0).map((val, rowIndex) => {
      const key = `skeleton-${val}${rowIndex}`;
      return (
        <div className="skeleton-item-container" key={key}>
          <div className="skeleton-item-row">
            <span className="skeleton-hotkey-block" />
            <div className="skeleton-info-container">
              <span className="skeleton-info-name skeleton-box" />
              <span className="skeleton-info-value skeleton-box" />
            </div>
          </div>
        </div>
      );
    });
    return (
      <React.Fragment key={`skeleton-wrapper-${index}`}>
        <div className="skeleton-header-wrapper">
          <span className="skeleton-header-title skeleton-box" />
          <hr className="skeleton-header-fill-line" />
        </div>
        {skeletonRows}
      </React.Fragment>
    );
  }, []);

  return (
    <div className="sidebar entities">
      <div className="skeleton-list">
        {
          groups.map((groupCount, index) => generateSkeletonBlock(groupCount, index))
        }
      </div>
    </div>
  );
};
