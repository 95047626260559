import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers/index';

function configureStore() {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );
}

const store = configureStore();
export default store;
