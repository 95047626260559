import React from 'react';

const iconMapping = {
  pdf: 'file-pdf',
  mail: 'envelope',
  image: 'file-image',
  docx: 'file-word',
};

// const typeMapping = {
//   '/': '',
//   'travel route': 'This is a travel route.',
//   order: 'This is an order.',
// };

const shouldShowTooltip = (DocumentType) => DocumentType && DocumentType.name !== '/';

export default ({
  AttachmentIcon, AttachmentName, Selected, OnClick, DocumentType,
}) => (
  <div className="attachment-item-container">
    <div
      className={`attachment ${Selected ? 'selected' : ''} ${shouldShowTooltip(DocumentType) ? 'ox-has-tooltip' : ''}`}
      onClick={OnClick}
      role="button"
      data-tooltip={`${DocumentType ? DocumentType.name : ''}`}
    >
      <span className={`fa fa-${iconMapping[AttachmentIcon.toLowerCase()] || 'file'} attachment-icon`} />
      <span className="attachment-name">{AttachmentName}</span>
    </div>
  </div>
);
