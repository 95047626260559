import axios from 'axios';
import * as extraOptionTypes from './extraOptionsTypes';
import { authenticationHeader, baseUrl } from '../../constants/constants';
import { invalidApiKey } from '../user/userActions';
import { history } from '../../components/App';
import { tokenIsValid } from '../../helpers/accountHelpers';

const fetchExtraOptionsLoading = () => ({ type: extraOptionTypes.FETCH_EXTRA_OPTIONS });
const fetchExtraOptionsSuccess = (data, t) => ({ type: extraOptionTypes.FETCH_EXTRA_OPTIONS_SUCCESS, data, t });
const fetchExtraOptionsFailed = (error) => ({ type: extraOptionTypes.FETCH_EXTRA_OPTIONS_FAILED, error });

export function fetchExtraOptions(t) {
  return (dispatch) => {
    dispatch(fetchExtraOptionsLoading());

    return axios.get(`${baseUrl}/extra-options`, authenticationHeader)
      .then((response) => {
        dispatch(fetchExtraOptionsSuccess(response.data, t));
      }, (error) => {
        if (error.response.status && error.response.status === 401) {
          if (!tokenIsValid()) {
            dispatch(invalidApiKey());
            history.push('/login');
          } else {
            history.push('/');
          }
        }
        dispatch(fetchExtraOptionsFailed(error));
      });
  };
}
