import React, {
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

const MultiCheckBox = ({
  disabled,
  value,
  entityName,
  options,
  onChange,
  hasChanged,
}) => {
  const { t } = useTranslation('annotationView');
  const handleCheckboxChange = useCallback((label) => {
    const index = value.indexOf(label);
    if (index !== -1) {
      onChange(null, index);
    } else if (value.includes(null)) {
      onChange(label, value.length - 1);
    } else {
      onChange(label, value.length);
    }
    hasChanged(true);
  }, [value]);

  const renderCheckBoxes = useMemo(() => options.map((option) => (
    <div className="ox-form__checkbox" key={option.label}>
      <input
        id={option.label}
        type="checkbox"
        className="ox-form__checkbox__input"
        disabled={disabled}
        checked={value.includes(option.label)}
        onChange={() => handleCheckboxChange(option.label)}
      />
      <label htmlFor={option.label} className="ox-form__checkbox__label">
        {t(`entityOptions.${entityName}.${option.label}`)}
      </label>
    </div>
  )), [options, value]);

  return (
    <div className="ox-form__group">
      {renderCheckBoxes}
    </div>
  );
};

export default MultiCheckBox;
