import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef
} from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useTranslation } from 'react-i18next';
import Annotator from 'react-pdf-ner-annotator';
import PDFPage from './PDFPage';
import Preloader from '../preloader/Preloader';
import './TokenAnnotator.scss';
import * as annotationActionFile from '../../actions/annotation/annotationActions';
import * as entityActionFile from '../../actions/entity/entityActions';
import * as mailActionFile from '../../actions/mail/mailActions';
import * as keyEventActionFile from '../../actions/keyEvent/keyEventActions';
import * as orderActionFile from '../../actions/order/orderActions';
import * as feedbackActionFile from '../../actions/feedback/feedbackActions';
import * as appStateActionFile from '../../actions/appState/appStateActions';
import * as configMapActionsFile from '../../actions/configMap/configMapActions';
import * as metricsReportActionsFile from '../../actions/metricsReport/metricsReportActions';
import * as customerActionsFile from '../../actions/customer/customerActions';
import DocumentControls from './DocumentControls';
import EntitiesSidebar from './entitiesSidebar/EntitiesSidebar';
import InfoSidebar from './infoSidebar/InfoSidebar';
import InfoSidebarSkeleton from './skeleton/InfoSidebarSkeleton';
import Mail from './Mail';
import { getApiKeyFromActiveUser } from '../../helpers/accountHelpers';
import { getChangedAnnotations } from '../../helpers/annotationHelpers';
import { getIdOfUser } from '../../helpers/accountHelpers';
import { addValue } from '../../helpers/arrayHelpers';
import { downloadPdf } from '../../services/pdfService';
// import DocumentTypeSelector from './infoSidebar/DocumentTypeSelector';
import EntitiesSidebarSkeleton from './skeleton/EntitiesSidebarSkeleton';
import {
  ARCHIVE_REASONS,
  baseUrl,
  CONTACT_PERSON_EMAIL_ENTITY_ID,
  CONTACT_PERSON_FIRST_NAME_ENTITY_ID,
  CONTACT_PERSON_LAST_NAME_ENTITY_ID,
} from '../../constants/constants';
import NavigationAlert from '../navigationAlert/navigationAlert';
import { addNewContactPerson, queryPossibleContactPersons } from '../../services/mailService';
import ArchiveModal from './entitiesSidebar/modals/ArchiveModal';
import useAssigned from '../../hooks/useAssigned';
import useLocalStorage from '../../hooks/useLocalStorage';
import Viewer from './pdf/Viewer';
import FullScreenLoader from '../fullScreenLoader/FullScreenLoader';
import { Mixpanel } from '../../mixpanel/Mixpanel';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  mailActions: bindActionCreators(mailActionFile, dispatch),
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  entityActions: bindActionCreators(entityActionFile, dispatch),
  keyEventActions: bindActionCreators(keyEventActionFile, dispatch),
  orderActions: bindActionCreators(orderActionFile, dispatch),
  feedbackActions: bindActionCreators(feedbackActionFile, dispatch),
  appStateActions: bindActionCreators(appStateActionFile, dispatch),
  configMapActions: bindActionCreators(configMapActionsFile, dispatch),
  metricsReportActions: bindActionCreators(metricsReportActionsFile, dispatch),
  customerActions: bindActionCreators(customerActionsFile, dispatch),
});

const TokenAnnotator = ({
  annotationActions,
  mailActions,
  entityActions,
  feedbackActions,
  appStateActions,
  orderActions,
  configMapActions,
  annotationReducer,
  metricsReportActions,
  keyEventActions,
  mailReducer: {
    activeMail,
    mailDocuments,
    activeDocument,
    orderDocument,
    loading: mailIsLoading,
  },
  orderReducer: {
    activeOrder,
    isEdited,
    activeTabIndex,
  },
  configMapReducer: {
    configMap,
    loading: configMapLoading,
  },
  machineReducer: {
    machines,
  },
  entityReducer: {
    selectedEntity,
  },
  annotationReducer: {
    documents,
    marks,
    removeMarks,
  },
  keyEventReducer,
  history,
  match: {
    params: {
      annotationType,
      mailId,
    },
  },
  location: {
    state: { hasBeenAssigned } = { hasBeenAssigned: false },
  },
  customerActions,
}) => {

  const [annotations, setAnnotations] = useState(null);
  const [prevMarks, setPrevMarks] = useState(null);
  const { t, i18n } = useTranslation('annotationView');

  const annotatorRef = useRef(null);

  const [customersInitialized, setCustomersInitialized] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  // const [annotationsLoading, setAnnotationsLoading] = useState(true);
  const [mailFetched, setMailFetched] = useState(false);
  const [shouldBlock, setShouldBlock] = useState(true);
  const [pdfView, setPdfView] = useState(false);
  const [auditAction, setAuditAction] = useState('BACK');
  const [possibleContactPerson, setPossibleContactPerson] = useState(null);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [orderConfirmation, setOrderConfirmation] = useState(true);
  const { getValue: getActiveUser } = useLocalStorage('active-user');

  // This form is given to all the components that need it.
  const form = useForm();
  // All errors are set in the EntityRow component -> validateValue()
  const { errors } = form; // https://react-hook-form.com/api#errors

  const { isAssigned, setIsAssigned } = useAssigned(getIdOfUser(getActiveUser()), mailId, hasBeenAssigned);

  const annotatingDisabled = (mailDocuments[activeDocument] && !mailDocuments[activeDocument].pdfAsJson)
    || (activeMail && activeMail.processedAt) || pdfView;

  const isLoading = !activeMail || mailIsLoading || configMapLoading;

  const handleKeyEvent = useCallback((event) => {
    if (keyEventReducer.isKeyEventEnabled && !isLoading && !annotatingDisabled) {
      if (annotationType === 'ner' && configMap) {
        const entityTypes = configMap[activeTabIndex].groupBlocks.map((configMapItem) => configMapItem.entityTypes).flat(1);
        entityTypes.forEach((entity) => {
          if (entity && entity.hotkey && entity.hotkey.toUpperCase() === event.key.toUpperCase()) {
            entityActions.changeSelectedEntity(entity.id, entity.color, entity.name, '1');
          }
        });
      }
    }
  }, [configMap, keyEventReducer, activeTabIndex, isLoading, annotatingDisabled]);

  const addKeyEventListener = useCallback(() => {
    document.addEventListener('keydown', handleKeyEvent, false);
  }, [configMap, keyEventReducer, isLoading, annotatingDisabled, activeTabIndex]);

  const removeKeyEventListener = useCallback(() => {
    document.removeEventListener('keydown', handleKeyEvent, false);
  }, [configMap, keyEventReducer, isLoading, annotatingDisabled, activeTabIndex]);

  // Remove annotations
  useEffect(() => {
    if (!removeMarks || !removeMarks.length) return;
    const activeDocumentId = mailDocuments[activeDocument].id.toString()

    let removeMarkIds = []
    removeMarks.forEach((markToDelete) => {
      if (activeDocumentId === markToDelete.documentId){
        removeMarkIds = removeMarkIds.concat(markToDelete.mark.id.toString())
      } else {
        let documentMarks = documents[markToDelete.documentId].marks
        const indexToDelete = documentMarks.findIndex((mark) => mark.id.toString() === markToDelete.mark.id.toString());
        documentMarks.splice(indexToDelete, 1);
        annotationActions.handleAnnotationChange(documentMarks, markToDelete.documentId)
      }
    })
    if (removeMarkIds.length){
      annotatorRef.current.removeAnnotation(removeMarkIds)
    }
    annotationActions.removeAnnotationsSuccess()
  }, [removeMarks])

  // Update annotation marks
  useEffect(() => {
    if (!annotations){
      return;
    }
    // Update previous marks
    setPrevMarks(marks)

    // Update annotations with new values
    const activeDocumentId = mailDocuments[activeDocument].id
    annotationActions.handleAnnotationChange(annotations, activeDocumentId)
  }, [annotations])

  // Update order values when marks have changed
  useEffect(() => {
    // Check which annotations are created and which are removed
    const modifiedAnnotations = getChangedAnnotations(marks, prevMarks)

    // Update order values
    // -- Do not update order values on start, but get them from the API
    if (!modifiedAnnotations || !modifiedAnnotations.length){
      return;
    }

    // -- Modify order validates
    if (modifiedAnnotations && modifiedAnnotations.length > 0) {
      modifiedAnnotations.forEach((modified) => {
        // Get entity id and index
        const entityId = modified.entity.id
        const index = modified.entity.index

        // Get all other annotations of the same entity
        const entityAnnotations = marks.filter(annotation => annotation.entity.id == entityId && annotation.entity.index == index)

        // Clear order values
        if (!entityAnnotations.length){
          orderActions.clearVariable(entityId, modified.entity.index)
        }

        // Add order values
        const orderValues = {}
        entityAnnotations.forEach((annotation, i) => {
          addValue(orderValues, index, annotation.tokens.join(' '))
        });
        for (let index in orderValues){
          const values = orderValues[index]
          orderActions.changeVariable({
            orderEntityId: entityId,
            indexToAdjust: index,
            value: values
          })
        }
      })
    }
  }, [marks])

  useEffect(() => {
    orderActions.changeActiveTabIndex(0);
    configMapActions.fetchConfigMap();
    metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), mailId, 'OPEN');
  }, []);

  useEffect(() => {
    if (configMap && !mailFetched && !mailIsLoading) {
      // const entityTypes = configMap[activeTabIndex].groupBlocks.map((configMapItem) => configMapItem.entityTypes).flat(1);
      const entityTypes = configMap.map((tab, index) => tab.groupBlocks.map((block) => block.entityTypes.map((entity) => {
        // eslint-disable-next-line no-param-reassign
        entity.tabIndex = index;
        return entity;
      }).flat(1)).flat(1)).flat(1);
      mailActions.fetchMailById(mailId, entityTypes).then(() => {
        setMailFetched(true);
      });

    }
  }, [configMap, activeTabIndex, mailFetched]);

  useEffect(() => {
    appStateActions.updateSubtitle(t('title'));
  }, [i18n.language]);

  useEffect(() => {
    if ((activeMail && activeMail.length > 0 && activeDocument) && !customersInitialized) {
      const customerNumber = activeOrder.find((item) => item.entityId === 1);
      if (customerNumber) {
        customerActions.findCustomerById((customerNumber.value['1'][0] || '').toString());
        setCustomersInitialized(true);
      }
    }
  }, [activeOrder, activeMail]);

  async function checkEmail(email) {
    const contactPerson = await queryPossibleContactPersons(email);
    setPossibleContactPerson(contactPerson);
  }

  useEffect(() => {
    if (activeMail) {
      if (!activeMail.employee && !activeMail.processedAt && !hasBeenAssigned) {
        setIsAssigned(true);
        const patchBody = [
          {
            op: 'replace',
            path: '/status',
            value: {
              id: 2,
            },
          },
          {
            op: 'replace',
            path: '/employee',
            value: {
              id: getIdOfUser(getActiveUser()),
            },
          },
        ];
        mailActions.patchMail(mailId, patchBody);
      }
      // Check if the contact person can be found based on the sender email.
      const senderEmail = activeMail.sender;
      // Shouldn't do anything with this promise.
      // checkEmail(senderEmail);
    }
  }, [activeMail, activeDocument, mailId]);

  useEffect(() => {
    if (!isAssigned && activeMail && !activeMail.processedAt) {
      feedbackActions.addErrorMessage(t('mailUnassigned'), false);
    }
  }, [isAssigned, activeMail]);

  // This effect hook listens for changes in entity types
  // It will effectively change the event listeners only when there was a change in entities
  useEffect(() => {
    addKeyEventListener();

    // Cleanup function
    return () => {
      removeKeyEventListener();
      setPossibleContactPerson(null);
      entityActions.clearSelectedEntity();
    };
  }, [configMap, keyEventReducer, isLoading, annotatingDisabled, activeTabIndex]);

  const getOrderContactValues = useCallback(() => {
    if (activeOrder) {
      const firstName = activeOrder.filter((orderItem) => orderItem.entityId === CONTACT_PERSON_FIRST_NAME_ENTITY_ID).map((orderItem) => orderItem.value['1'][0])[0] || '';
      const lastName = activeOrder.filter((orderItem) => orderItem.entityId === CONTACT_PERSON_LAST_NAME_ENTITY_ID).map((orderItem) => orderItem.value['1'][0])[0] || '';
      const email = activeOrder.filter((orderItem) => orderItem.entityId === CONTACT_PERSON_EMAIL_ENTITY_ID).map((orderItem) => orderItem.value['1'][0])[0] || '';

      return {
        firstName,
        lastName,
        email,
      };
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
    };
  }, [activeOrder]);

  const handleConfirmClicked = async (action) => {
    const patchBody = [
      {
        op: 'replace',
        path: '/status',
        value: {
          id: 1,
        },
      },
      {
        op: 'remove',
        path: '/employee',
      },
    ];
    await mailActions.patchMail(mailId, patchBody);
    await metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), mailId, action);
  };

  const approveMail = async () => {
    Mixpanel.track('Approve click');
    const readOnly = activeMail.processedAt !== null;
    if (!readOnly) {
      setIsApproving(true);
      setShouldBlock(false);

      Promise.all([
        annotationActions.saveAnnotations(documents),
        orderActions.approveOrder(activeOrder, mailId, orderConfirmation),
      ])
        .then(async () => {
          await annotationActions.saveAnnotations(documents);
          await orderActions.approveOrder(activeOrder, mailId);
          await metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), mailId, 'APPROVE');
          feedbackActions.addSuccessMessage('The order has been processed.');
          history.push('/');
        }, () => {
          setIsApproving(false);
        });
    }
  };

  const holdMail = async () => {
    Mixpanel.track('Hold click');
    const readOnly = activeMail.processedAt !== null;
    if (!readOnly) {
      const patchBody = [
        {
          op: 'replace',
          path: '/status',
          value: {
            id: 3,
          },
        },
        {
          op: 'replace',
          path: '/attachments',
          value: mailDocuments,
        },
      ];
      setIsApproving(true);
      setShouldBlock(false);

      Promise.all([
        mailActions.patchMail(mailId, patchBody),
        annotationActions.saveAnnotations(documents),
        orderActions.holdOrder(activeOrder, mailId),
      ])
        .then(async () => {
          await feedbackActions.addInfoMessage('Mail was put on hold');
          await metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), mailId, 'HOLD');
          history.push('/');
        }, () => {
          setIsApproving(false);
        });
    }
  };

  const cancelMail = async (action) => {
    Mixpanel.track('Cancel click');
    await setAuditAction(action);
    const readOnly = activeMail.processedAt !== null;
    if (!readOnly) {
      if (!isEdited) {
        await handleConfirmClicked(action);
      }
    }
    history.push('/');
  };

  const Document = useMemo(() => {
    if (mailDocuments[activeDocument] && mailDocuments[activeDocument].pdfAsJson) {
      const objectName = `${mailDocuments[activeDocument].file_location}`;
      const activeDocumentId = mailDocuments[activeDocument].id;

      if (pdfView) {
        return (
          <Viewer url={`${baseUrl}/bucket/pdf?objectName=${objectName}`} />
        );
      }

      let entity = null
      if (selectedEntity.id){
        entity = selectedEntity
      }

      console.log('Update annotator')
      return (
        <Annotator
          url={`${baseUrl}/bucket/pdf?objectName=${objectName}`}
          httpHeaders={{ Authorization: `Bearer ${getApiKeyFromActiveUser()}` }}
          ref={annotatorRef}
          initialTextMap={mailDocuments[activeDocument].pdfAsJson}
          entity={entity}
          getAnnotations={setAnnotations}
          defaultAnnotations={documents[activeDocumentId].marks}
        />
      );
    }

    return (
      <div className="not-supported-message">
        <span className="fas icon fa-exclamation-triangle" />
        <p>Cannot display this type of document</p>
      </div>
    );
  }, [activeDocument, mailDocuments, pdfView, selectedEntity]); //marks, marksHash,

  const disableApproveButton = useMemo(() => {
    if (!configMap) {
      return true;
    }

    return configMap.map((tab) => tab.groupBlocks.map((configMapItem) => configMapItem.entityTypes).flat(1).map((configMapEntity) => {
      const orderItem = activeOrder.find((item) => configMapEntity.id === item.entityId);
      // All form errors should be registered in the errors object from the useForm hook.
      // Checking the amount of keys in the form will result in being able to approve the order.

      const filteredErrors = {};

      // Filter out all 'disabled-feature-errors'
      Object.entries(errors).forEach(((possibleError) => {
        const [key, value] = possibleError;
        if (value.type !== 'disabled') {
          filteredErrors[key] = value;
        }
      }));

      const errorsFound = Object.keys(filteredErrors);

      if (errorsFound && errorsFound.length > 0) {
        return true;
      }
      if (orderItem && !configMapEntity.multipleValues) {
        if (orderItem.value && Object.values(orderItem.value).flat(1).length > Object.keys(orderItem.value).length) {
          return true;
        }
      }

      return false;
    })).flat().includes(true); // flat because it's a list of lists
  }, [activeOrder, machines, configMap, errors, form]);

  const renderNavigationBlocker = useMemo(() => {
    const readOnly = activeMail && activeMail.processedAt !== null;
    if (!shouldBlock) return null;
    return (
      <NavigationPrompt when={isEdited && !readOnly} disableNative>
        {({ onConfirm, onCancel }) => (
          <NavigationAlert
            onConfirm={async () => {
              await handleConfirmClicked(auditAction);
              onConfirm();
            }}
            onCancel={() => {
              onCancel();
            }}
            navigationBlocked={isEdited}
            title={t('blockTitle')}
            message={t('blockMessage')}
          />
        )}
      </NavigationPrompt>
    );
  }, [activeMail, shouldBlock, isEdited, auditAction]);


  return (
    <div className="annotator" tabIndex={-1}>
      <FullScreenLoader loading={isApproving} />
      {renderNavigationBlocker}
      {
        isLoading ? <InfoSidebarSkeleton /> : (
          <InfoSidebar
            cancelMail={cancelMail}
            disabled={mailDocuments[activeDocument] && activeMail.processedAt}
          />
        )
      }
      <main className="content">
        <div className="text">
          {!isLoading ? (
            <h3 className="document-name">
              {
                mailDocuments[activeDocument].name === 'body' ? activeMail.subject || 'No subject' : mailDocuments[activeDocument].name
              }
              {
                mailDocuments[activeDocument].file_location && mailDocuments[activeDocument].file_location.toLowerCase().includes('.pdf')
                  ? (
                    <div className="document-actions-wrapper">
                      <div className="document-actions-view">
                        <label className="document-actions-view-label">Enable Annotation</label>
                        <input
                          type="checkbox"
                          checked={!pdfView}
                          className="ox-form__pillswitch document-actions-view-switch"
                          onChange={(event) => setPdfView(!event.target.checked)}
                        />
                      </div>
                      <span
                        role="button"
                        className="ox-icon ox-icon--download download-button"
                        onClick={() => downloadPdf(mailDocuments[activeDocument].file_location)}
                      />
                    </div>
                  )
                  : null
              }
            </h3>
          ) : null}
          {!isLoading ? Document : <Preloader />}
        </div>
        <DocumentControls
          approve={approveMail}
          hold={holdMail}
          isLoading={isLoading}
          cancel={() => cancelMail('CANCEL')}
          archive={() => {
            setShouldBlock(false);
            setIsApproving(true);
            setOpenReportModal(true);
            keyEventActions.disableKeyEventListener();
          }}
          disabled={!isAssigned || isApproving || mailIsLoading || !mailFetched || (activeMail && activeMail.processedAt)}
          approveDisabled={isApproving || disableApproveButton}
          orderConfirmation={orderConfirmation}
          setOrderConfirmation={setOrderConfirmation}
        />
      </main>
      {
        !isLoading
          ? (
            <EntitiesSidebar
              type={annotationType}
              documentId={mailId}
              disabled={activeMail && activeMail.processedAt !== null}
              annotatingDisabled={annotatingDisabled}
              form={form}
            />
          ) : (
            <EntitiesSidebarSkeleton />
          )
      }
      <ArchiveModal
        open={openReportModal}
        close={() => {
          setShouldBlock(true);
          setIsApproving(false);
          setOpenReportModal(false);
          keyEventActions.enableKeyEventListener();
        }}
        reasons={ARCHIVE_REASONS}
        mailId={mailId}
        orderDocument={mailDocuments[orderDocument]}
        history={history}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenAnnotator);
