import * as machineTypes from '../actions/machine/machineTypes';

const initialState = {
  loading: false,
  error: undefined,
  machines: [],
  options: {},
  attachments: {},
  machineOptions: {},
  machineAttachments: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case machineTypes.FETCH_MACHINES:
      return { ...state, loading: true };
    case machineTypes.FETCH_MACHINES_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        machines: data,
        loading: false,
      };
    }
    case machineTypes.FETCH_MACHINES_FAILED: {
      const { error } = action;

      return { ...state, loading: false, error };
    }
    case machineTypes.FETCH_OPTIONS: {
      const { data } = action;
      return {
        ...state,
        options: data,
      };
    }

    case machineTypes.FETCH_ATTACHMENTS: {
      const { data } = action;
      return {
        ...state,
        attachments: data,
      };
    }

    case machineTypes.FETCH_MACHINE_OPTIONS: {
      const { machineCode, data } = action;
      const { options, machineOptions } = state;
      const parsed = data.map((item) => ({
        code: item.id,
        description: options[item.id],
      }));
      return {
        ...state,
        machineOptions: { ...machineOptions, [machineCode]: parsed },
      };
    }

    case machineTypes.FETCH_MACHINE_ATTACHMENTS: {
      const { machineCode, data } = action;
      const { attachments, machineAttachments } = state;
      const parsed = data.map((item) => ({
        code: item.id,
        description: attachments[item.id],
      }));

      return {
        ...state,
        machineAttachments: { ...machineAttachments, [machineCode]: parsed },
      };
    }

    case machineTypes.CLEAR_MACHINE_ATTACHMENTS_AND_OPTIONS:
      return {
        ...state,
        machineAttachments: {},
        machineOptions: {},
      };

    default:
      return state;
  }
};
