import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as keyEventActionFile from '../../../actions/keyEvent/keyEventActions';
import './EntityRow.scss';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  keyEventActions: bindActionCreators(keyEventActionFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  name,
  value,
  disabled,
  required,
  onChange,
  updateStore,
  keyEventActions,
  autoFormatValues,
  extraInfo = null,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputField = useRef(null);
  const isEmpty = value === null;

  const handleFocus = () => {
    if (disabled) {
      return;
    }
    if (isEmpty) {
      onChange('');
    }
    keyEventActions.disableKeyEventListener();
    setIsEditing(true);

    setTimeout(() => {
      inputField.current.focus();
    });
  };

  const handleBlur = () => {
    if (value === '') {
      onChange(null);
    }

    setIsEditing(false);

    updateStore();
    autoFormatValues();
    keyEventActions.enableKeyEventListener();
  };

  const handleKeyEvent = (event) => {
    if (isEditing) {
      switch (event.key) {
        case 'Enter': {
          event.preventDefault();
          inputField.current.blur();
          break;
        }
        default:
          break;
      }
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  const extraInfoContainer = useMemo(() => {
    if (extraInfo) {
      return <span className="ox-form__feedback">{extraInfo}</span>;
    }
    return null;
  }, [extraInfo]);

  return (
    <div className="ox-form__group">
      <div className="ox-form__input-group ox-form__input-group--append">
        <input
          type="number"
          className="ox-form__field"
          aria-labelledby="machine-height-label min-height machine-height-unit"
          id={name}
          disabled={disabled}
          ref={inputField}
          value={value}
          required={required}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          onChange={({ target: { value: changedValue } }) => onChange(changedValue)}
        />
        <label
          htmlFor="min-height"
          id="machine-height-unit"
          className="ox-form__input-group__unit"
          aria-label="in meters"
        >m
        </label>
      </div>
      {extraInfoContainer}
    </div>
  );
});
