import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getAccessibleTextColor from '../../helpers/entityHelpers';
import * as annotationActionFile from '../../actions/annotation/annotationActions';
import * as orderActionsFile from '../../actions/order/orderActions';
import * as entityActionFile from '../../actions/entity/entityActions';
import './PDFPage.scss';
import { baseUrl } from '../../constants/constants';
import { formatDate, formatHour } from '../../helpers/date-helpers';
import { shouldBeNumeric } from '../../helpers/formValidationHelpers';
import { checkIfMachineCodeCanBeFound } from '../../helpers/configMapConstraintHelpers';

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  entityActions: bindActionCreators(entityActionFile, dispatch),
  orderActions: bindActionCreators(orderActionsFile, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(({
  orderActions,
  entityActions,
  annotationActions,
  entityReducer: {
    selectedEntity: {
      id: selectedEntityId,
      index: selectedEntityIndex,
    },
    previousSelectedEntity: {
      id: previousSelectedEntityId,
      index: previousSelectedEntityIndex,
    },
    hoveredEntityId,
  },
  configMapReducer: {
    configMap,
  },
  orderReducer: {
    activeTabIndex,
  },
  annotationReducer: {
    marks,
  },
  documentOutline,
  documentId,
  readOnly,
  uuid,
}) => {
  const [selection, setSelection] = useState([]);
  const [hoveredMark, setHoveredMark] = useState(null); // eslint-disable-line
  const [relatedMarks, setRelatedMarks] = useState([]);
  const handleNothingHovered = () => {
    setRelatedMarks([]);
    setHoveredMark(null);
  };

  const handleRemoveAnnotation = (event) => {
    const tagID = parseInt(event.target.attributes['data-i'].value, 10);
    const annotationIndex = marks.findIndex((mark) => mark.textIds.indexOf(tagID) !== -1);

    if (annotationIndex >= 0) {
      const { entityID, index } = marks[annotationIndex];
      const entityAnnotationIndex = marks.filter((mark) => mark.entityID === entityID)
        .findIndex((mark) => mark.textIds.indexOf(tagID) !== -1);
      handleNothingHovered();
      setHoveredMark(null);
      orderActions.clearVariable(entityID, index, entityAnnotationIndex);
      annotationActions.handleAnnotationChange([
        ...marks.slice(0, annotationIndex),
        ...marks.slice(annotationIndex + 1),
      ]);
    }
  };

  const handleHoveredEntityRow = (hoveredId) => {
    if (hoveredId) {
      // Filter down on all occurrences of the entityID 'hoveredEntityId'
      // Concatenate all related id's into one array
      const results = marks
        .filter((mark) => mark.entityID === hoveredId)
        .map((mark) => mark.textIds)
        .reduce((accumulator, current) => accumulator.concat(current), []);

      const id = (results || [])[0];
      if (id) {
        setRelatedMarks(results);
        setHoveredMark(id);
      }
    } else {
      handleNothingHovered();
    }
  };

  useEffect(() => {
    const foundMark = marks.find((mark) => mark.entityID === hoveredEntityId);
    if (foundMark) {
      handleHoveredEntityRow(hoveredEntityId);
    } else {
      handleNothingHovered();
    }
  }, [hoveredEntityId]);

  const decodeHtmlEntities = (encodedString) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  };

  const confirmSelection = async (selectedId = null, selectedIndex = null) => {
    const entitySelection = selectedId || selectedEntityId;
    if (entitySelection && selection.length) {
      const entityTypes = configMap[activeTabIndex].groupBlocks.map((configMapItem) => configMapItem.entityTypes).flat(1);
      const entity = entityTypes.find((e) => e.id === entitySelection);
      const index = selectedEntityIndex || '1';

      orderActions.setFormDirty(true);

      // This code makes it so that you can't have two different annotations for one entity type
      // This is done for now, so as to make sure that the order has one set value
      // const filteredMarks = marks.filter((mark) => entity.id !== mark.entityID);

      // Transforms temp selection into a mark and resets the selection
      await annotationActions.handleAnnotationChange(
        [...marks, {
          documentId,
          textIds: selection.map((s) => s.id).sort(),
          entityID: entity.id,
          background: entity.color,
          color: getAccessibleTextColor(entity.color),
          tab: activeTabIndex,
          entity: entity.name,
          index: index.toString(),
          tokens: selection.sort((a, b) => a.id - b.id).map((s) => s.text),
        }],
      );

      // This will also change the variable in the sidebar
      const selectedText = decodeHtmlEntities(selection.sort((a, b) => a.id - b.id).map((s) => s.text).join(' '));
      let value = [selectedText];
      const lowerCasedName = (entity.name || '').toLowerCase();
      const isDate = lowerCasedName.includes('date');
      const isHour = lowerCasedName.includes('hour');
      if (isDate) {
        const { returnValue } = await formatDate(value);
        if (returnValue) {
          value = returnValue;
        }
      }

      if (isHour) {
        const { returnValue } = await formatHour(value);
        if (returnValue) {
          value = returnValue;
        }
      }

      if (shouldBeNumeric(entity)) {
        if (entity.canBeAnnotated) {
          const remainingChars = value[0].toString().replace(/[^0-9,.]/ig, '');
          if (remainingChars) {
            value = [parseInt(remainingChars, 10)];
          }
        }
      }

      const indexToAdjust = (selectedIndex || selectedEntityIndex).toString();

      checkIfMachineCodeCanBeFound(entity, value, orderActions, indexToAdjust);

      orderActions.changeVariable({
        orderEntityId: entity.id,
        indexToAdjust,
        value,
      });

      // Clear entity selection and reset temporary selection of text objects
      // entityActions.clearSelectedEntity();
      setSelection([]);
    }
  };

  useEffect(() => {
    confirmSelection(previousSelectedEntityId, previousSelectedEntityIndex);
  }, [selectedEntityId]);

  const handleKeyEvent = (e) => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        confirmSelection();
        break;
      case 'Escape':
        setSelection([]);
        entityActions.changeSelectedEntity();
        break;
      default:
        break;
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);
    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  const handleMarkHovered = (event) => {
    if (!selectedEntityId && event.target.attributes['data-i']) {
      const id = parseInt(event.target.attributes['data-i'].value, 10);
      // Filter down on all occurrences of an ID in any marks
      // Concatenate all related id's into one array
      const results = marks
        .filter((mark) => mark.textIds.indexOf(id) !== -1)
        .map((mark) => mark.textIds)
        .reduce((accumulator, current) => accumulator.concat(current), []);

      setRelatedMarks(results);
      setHoveredMark(id);
    }
  };

  // This function will filter the existing annotations for the selectedEntityId from the store.
  const resetExistingAnnotations = () => {
    const filteredMarks = marks.filter((mark) => {
      if (mark.index.toString() === `${selectedEntityIndex}`) {
        if (mark.entityID === selectedEntityId) {
          return false;
        }
      }
      return true;
    });
    if (marks.length !== filteredMarks.length) {
      annotationActions.handleAnnotationChange(
        [...filteredMarks],
      );
    }
  };

  const handleWordClicked = (event) => {
    // The click is only relevant when an entity type was selected...
    if (!readOnly && selectedEntityId && event.target.attributes['data-i']) {
      const id = parseInt(event.target.attributes['data-i'].value, 10);
      const text = event.target.innerHTML;
      const newSelection = [...selection];
      resetExistingAnnotations();

      // Only add new selection if not present in list
      if (newSelection.map((s) => s.id).indexOf(id) === -1) {
        newSelection.push({
          id,
          text,
        });
      } else {
        newSelection.splice(newSelection.map((s) => s.id).indexOf(id), 1);
      }

      setSelection(newSelection);
    }
  };

  // A list of tags we're looking into adding to the tree
  const VALID_TAGS = [
    'div',
    'span',
    'img',
    'style',
    'br',
    'p',
    'b',
    'i',
  ];

  /**
   * Converts kebab-case to camelCase
   * @param  {string} string kebab-case text
   * @return {string}        The same string but camelCase
   */
  const convertToCamelCase = (string) => {
    const parts = string.split('-');
    return parts.map((part, index) => (index === 0 ? part.toLowerCase() : `${part.charAt(0).toUpperCase()}${part.slice(1)}`)).join('');
  };

  /**
   * Takes a styles string like: "position:relative;width:892px;height:1263px;"
   * Converts this to an object with the correct casing
   * @param  {String} data A css non-whitespaced string
   * @return {Object}      A React style object that can be used with the style attribute
   */
  const convertToReactStylesObject = (data) => {
    const listOfStyles = {};
    data.split(';').filter((styleString) => styleString.length > 0).forEach((styleString) => {
      const property = convertToCamelCase(styleString.split(':')[0]);
      const value = styleString.split(':')[1];

      listOfStyles[property] = value;
    });

    return listOfStyles;
  };

  /**
   * Given an Array of attributes, will attempt to add values to certain attributes safely
   * Safely meaning: will create the attribute if it doesn't exist or will append if it does
   * Also supports object-type attributes like React style object
   * @param  {Object} attributes    An Object containing all attributes and their values (does not mutate)
   * @param  {String} name          The name of the attribute to be edited or created
   * @param  {String|Object} value  The value that should be given to the new attribute
   * @return {Object}               The resulting object of attributes
   */
  const safelyAppendAttribute = (attributes, name, value) => {
    const result = { ...attributes };
    if (typeof value === 'string') {
      // Normal attributes are string values, and then these cases apply
      if (result[name]) {
        result[name] = `${result[name]} ${value}`;
      } else {
        result[name] = value;
      }
    } else if (typeof value === 'object') {
      // Style attributes specifically implement object-type attributes, and this is the support for that
      result[name] = {
        ...result[name],
        ...value,
      };
    }

    return result;
  };

  /**
   * Extract the tag attributes for a given element
   * @param  {Object} data Data structure splice from the PDF JSON
   * @return {Object}      A dict containing the key-value pairs for the attributes and their values
   */
  const getAttributes = (data) => {
    let result = {};

    // Don't include style tag
    const keys = Object.keys(data).filter((key) => /^[@][\w-]+$/.test(key));

    keys.forEach((key) => {
      let attributeName = key.replace('@', '');
      let content = data[key];

      if (attributeName === 'src') {
        content = `${baseUrl}/bucket/image?objectName=${uuid}/${content}`;
      }

      // Common exception: class has to become className
      if (attributeName === 'class') {
        attributeName = 'className';
      }

      // Style objects have to be converted from string to React styles object
      if (attributeName === 'style') {
        content = convertToReactStylesObject(content);
      }

      result[attributeName] = content;
    });

    if (Number.isInteger(result['data-i']) && selectedEntityId) {
      result = safelyAppendAttribute(result, 'className', 'selectable');
    }

    return result;
  };

  /**
   * Extracts the content from a given element
   * Content is usually brought under a tag containing '#'
   * @param  {Object} data Data structure splice from the PDF JSON
   * @return {String}      The string content for a given element
   */
  const getContent = (data) => {
    // Check to see if the data has a # property
    const keys = Object.keys(data).filter((key) => /^[#]\w+$/.test(key));

    // If it does have, there can only be one prop like that used as content
    if (keys.length > 1) {
      throw new Error('PDF contains more than one content attribute with #');
    } else if (keys.length > 0) {
      return data[keys[0]];
    }

    // If the passed data is a string, then that's the content
    if (typeof data === 'string') {
      return data;
    }

    return null;
  };

  /**
   * ⚠️ Recursive function in which we crawl down a JSON tree of elements
   * We do this dynamically generate HTML elements for a PDF document
   * @param  {Object} outline     JSON object which contains the HTML tree structure
   * @param  {Array}  boundaries  A list of word boundaries kept throughout traversing the tree
   * @param  {Array}  marks       A list of marked words
   * @return {Array}              A tree/list is generated from the JSON outline
   */
  const extractChildren = (outline, annotationMarks = []) => {
    // Prepare a tree of React elements which serves as the final result
    const tree = [];

    // Extract all non-attribute and non-content props from the element as a key-value dict
    // Anything without @ or #
    const children = Object.keys(outline).filter((key) => /^[^@#]+$/.test(key)).map((key) => ({
      tag: key,
      data: outline[key],
    }));

    // Iterate over all valid child items. They will consist out of a tag and accompanying content
    children.forEach(({ tag, data }) => {
      // Exception for br elements
      if (tag === 'br') {
        pushToTree(tree, tag, {}, annotationMarks); // eslint-disable-line
      }
      if (VALID_TAGS.indexOf(tag) !== -1 && data && Array.isArray(data)) {
        // If the content is a list of elements, iterate over all those and push them onto the trees consecutively
        data.forEach((element) => {
          if (element) {
            pushToTree(tree, tag, element, annotationMarks); // eslint-disable-line
          }
        });
      } else if (VALID_TAGS.indexOf(tag) !== -1 && data) {
        // If the content is only one single element, just add it to the tree
        pushToTree(tree, tag, data, annotationMarks); // eslint-disable-line
      }
    });

    return tree;
  };

  /**
   * Allows you to push a new element to the tree given its tag, the parsed element, and its marks
   * @param  {Array} tree             An array with nested items consisting of the whole outline in React elements
   * @param  {String} tag             The tag the HTML element should exhibit
   * @param  {Object} element         The element object from back-end from which to extract content and attributes
   * @param  {Array} annotationMarks  The list of marks currently in the active document
   * @return {Array}                  The new tree with the elements added to it
   */
  const pushToTree = (tree, tag, element, annotationMarks) => {
    const injectedChildren = [];
    const content = getContent(element);
    let attributes = { ...getAttributes(element) };

    if (typeof content === 'string' && tag !== 'style') {
      attributes.onClick = handleWordClicked;

      // Mark the current temporary selection
      if (selectedEntityId) {
        const entityTypes = configMap[activeTabIndex].groupBlocks.map((configMapItem) => configMapItem.entityTypes).flat(1);
        const entity = entityTypes.find((e) => e.id === selectedEntityId);
        selection.map((s) => s.id).forEach((tempMarkId) => {
          if (tempMarkId === attributes['data-i']) {
            attributes = safelyAppendAttribute(attributes, 'className', 'temp-mark');
            attributes = safelyAppendAttribute(attributes, 'style', {
              backgroundColor: entity.color,
              color: getAccessibleTextColor(entity.color),
            });
          }
        });
      }

      // Mark entities that have already been annotated
      annotationMarks.forEach((mark) => {
        if (mark.textIds.indexOf(attributes['data-i']) !== -1 && mark.documentId === documentId && mark.tab === activeTabIndex) {
          if (relatedMarks.length && relatedMarks.indexOf(attributes['data-i']) === -1) {
            attributes = safelyAppendAttribute(attributes, 'style', {
              opacity: 0.2,
            });
          }

          attributes.onMouseEnter = handleMarkHovered;
          attributes.onMouseLeave = handleNothingHovered;
          if (!readOnly) {
            attributes.onClick = handleRemoveAnnotation;
          }
          attributes = safelyAppendAttribute(attributes, 'className', 'marked');
          attributes = safelyAppendAttribute(attributes, 'style', {
            backgroundColor: mark.background,
            color: mark.color,
          });
        }
      });
    }

    // ⚠️ Will call extractChildren recursively!
    // injectedChildren is used for when extra content needs to be added alongside the element's content and is not a child in the element
    tree.push(React.createElement(tag, attributes, content, ...injectedChildren, ...extractChildren(element, marks)));
  };

  return (
    <div className="pdf">
      {documentOutline.map((page) => extractChildren(page, marks))}
    </div>
  );
});
