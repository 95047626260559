import store from '../store';
import { ATTACHMENTS_ENTITY_ID, OPTIONS_ENTITY_ID } from '../constants/constants';

const getMachineAttachmentsAndOptions = () => {
  const state = store.getState();
  const {
    machineReducer: {
      options,
      attachments,
    },
  } = state;
  return { options, attachments };
};
const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const mapDescriptionToCode = (orderItem) => {
  const fixedValues = {};
  const { options, attachments } = getMachineAttachmentsAndOptions();

  Object.keys(orderItem.value).forEach((key, index) => {
    fixedValues[index + 1] = orderItem.value[key].map((valueItem) => {
      if (valueItem) {
        const foundValue = orderItem.entityId === OPTIONS_ENTITY_ID ? getKeyByValue(options, valueItem) : getKeyByValue(attachments, valueItem);
        if (foundValue) {
          return foundValue;
        }
      }
      return valueItem;
    });
  });
  return fixedValues;
};

export const mapCodeToDescription = (values, entityId) => {
  const { options, attachments } = getMachineAttachmentsAndOptions();

  if (entityId === ATTACHMENTS_ENTITY_ID || entityId === OPTIONS_ENTITY_ID) {
    return values.map((value) => {
      const foundValue = entityId === OPTIONS_ENTITY_ID ? options[parseInt(value || '-1', 10)]
        : attachments[parseInt(value || '-1', 10)];
      if (foundValue) {
        return foundValue;
      }
      return value;
    });
  }
  return values;
};
