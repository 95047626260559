import * as appStateTypes from '../actions/appState/appStateTypes';

const initialState = {
  toggleValue: 0,
  title: '',
  subtitle: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case appStateTypes.UPDATE_SUBTITLE: {
      const { subtitle } = action;
      return {
        ...state,
        subtitle,
      };
    }

    case appStateTypes.CHANGE_TOGGLE_VALUE: {
      const { toggleValue } = action;
      return { ...state, toggleValue };
    }
    default:
      return state;
  }
};
