import moment from 'moment';
import { calculateFutureDate } from '../services/parser';
import { queryPossibleMachine } from '../services/machineService';
import store from '../store/index';
import {
  DELIVERY_DATE_ENTITY_ID,
  DURATION_ENTITY_ID,
  DURATION_TYPE_ENTITY_ID,
  FIXED_RENTAL_END_ENTITY_ID,
  MACHINE_ENTITY_ID,
  MACHINE_TYPE_ENTITY_ID,
  PICKUP_DATE_ENTITY_ID,
} from '../constants/constants';

const getActiveOrder = () => {
  const state = store.getState();
  const {
    orderReducer: {
      activeOrder,
    },
  } = state;
  return activeOrder;
};

const findEntityInGroup = (group, id) => group.entityTypes.find((groupEntity) => groupEntity.id === id);

const checkDependantDefaultValues = (group, dependentDefaultIndex, value, orderActions, indexToAdjust) => {
  if (!dependentDefaultIndex) {
    return;
  }
  const foundDependentEntity = findEntityInGroup(group, dependentDefaultIndex);
  const { dependentDefaultValue } = foundDependentEntity;
  if (!dependentDefaultValue) {
    return;
  }
  const entityToCheck = getActiveOrder().find((orderEntity) => orderEntity.entityId === dependentDefaultIndex);
  const entityValuesToCheck = Object.values((entityToCheck || {}).value || {});
  entityValuesToCheck.forEach((entityValueToCheck) => {
    if (value && value[0] && !(entityValueToCheck && entityValueToCheck[0])) {
      const keys = Object.keys((entityToCheck || {}).value || {});
      if (!keys.length > 0) {
        return;
      }
      orderActions.changeVariable({
        orderEntityId: dependentDefaultIndex,
        indexToAdjust,
        value: [dependentDefaultValue],
      });
    }
  });
};

const checkDependantRequiredValue = (group, value, dependentRequiredOption, actions, activeTabIndex) => {
  if (!dependentRequiredOption) {
    return;
  }
  const dependentObjects = JSON.parse(JSON.stringify(dependentRequiredOption));
  if (dependentObjects && dependentObjects.length) {
    dependentObjects.forEach((dependentObject) => {
      const { requiredIndex, value: requiredValue } = dependentObject;
      const foundDependentEntity = findEntityInGroup(group, requiredIndex);

      if (value && value[0] === requiredValue) {
        actions.editEntityConfig(foundDependentEntity.id, { isRequired: true, groupIndex: group.index, activeTabIndex });
      } else {
        actions.editEntityConfig(foundDependentEntity.id, { isRequired: false, groupIndex: group.index, activeTabIndex });
      }
    });
  }
};

const checkIfFutureDateShouldBeCalculated = (group, configMap, entity, value, orderActions) => {
  if (!(entity.id === FIXED_RENTAL_END_ENTITY_ID || entity.id === DURATION_ENTITY_ID)) {
    return;
  }

  const foundDeliveryDate = getActiveOrder().find((item) => item.entityId === DELIVERY_DATE_ENTITY_ID) || {};
  const foundDuration = getActiveOrder().find((item) => item.entityId === DURATION_ENTITY_ID) || {};
  const foundFixedRentalEnd = getActiveOrder().find((item) => item.entityId === FIXED_RENTAL_END_ENTITY_ID) || {};
  const foundDurationType = getActiveOrder().find((item) => item.entityId === DURATION_TYPE_ENTITY_ID) || {};

  const currentValue = value && value[0] ? value : null;
  const fixedRentalValue = entity.id === FIXED_RENTAL_END_ENTITY_ID ? currentValue : (Object.values(foundFixedRentalEnd.value || {})[0]);
  const foundDurationValue = entity.id === FIXED_RENTAL_END_ENTITY_ID ? (Object.values(foundDuration.value || {})[0]) : currentValue;
  const foundDurationTypeValue = (Object.values(foundDurationType.value || {})[0]);
  const foundDeliveryValue = Object.values(foundDeliveryDate.value || {})[0];

  const isFixed = fixedRentalValue === 'FIXED' || (fixedRentalValue && fixedRentalValue[0]) === 'FIXED';

  if (isFixed && foundDurationValue && foundDurationTypeValue && foundDeliveryValue) {
    calculateFutureDate(foundDeliveryValue, foundDurationValue, foundDurationTypeValue).then((result) => {
      if (!result) {
        return;
      }
      orderActions.changeVariable({
        orderEntityId: PICKUP_DATE_ENTITY_ID,
        indexToAdjust: 1,
        value: [moment(result).format('DD/MM/YYYY')],
      });
    }).catch((error) => {
      console.error(error);
    });
  }
};

export const checkIfMachineCodeCanBeFound = (entity, value, orderActions, indexToAdjust) => {
  if (value && !value[0]) {
    return null;
  }
  if (entity.id === MACHINE_TYPE_ENTITY_ID) {
    queryPossibleMachine(value).then((machineCode) => {
      if (!machineCode) {
        return;
      }
      orderActions.changeVariable({
        orderEntityId: MACHINE_ENTITY_ID,
        value: [machineCode],
        indexToAdjust,
      });
    }).catch((error) => {
      console.error(error);
    });
  }
  return null;
};

export const checkAllDependentEntitiesForValueChanges = (requiredVariables) => {
  const {
    configMapActions,
    configMap,
    entity,
    indexToAdjust,
    orderActions,
    value,
    activeTabIndex,
  } = requiredVariables;
  configMap[activeTabIndex].groupBlocks.forEach((group) => {
    const foundCurrentEntityConfig = findEntityInGroup(group, entity.id);
    if (!foundCurrentEntityConfig) {
      return;
    }
    const { dependentDefaultIndex, dependentRequiredOption } = foundCurrentEntityConfig;
    checkDependantDefaultValues(group, dependentDefaultIndex, value, orderActions, indexToAdjust);
    checkDependantRequiredValue(group, value, dependentRequiredOption, configMapActions, activeTabIndex);
    checkIfFutureDateShouldBeCalculated(group, configMap, entity, value, orderActions);
    checkIfMachineCodeCanBeFound(entity, value, orderActions, indexToAdjust);
  });
};
