import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import './DateTimeField.scss';

const TIME_REGEX = RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]');
const DATE_REGEX = RegExp('^([0-2][0-9]|(3)[0-1])(\\/)(((0)[0-9])|((1)[0-2]))(\\/)\\d{4}');

const DateTimeField = ({
  label,
  value,
  disabled,
  onChange,
  changeIsEditing,
}) => {
  const field = useRef(null);
  const [dateTimeValue, setDateTimeValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation('annotationView');

  useEffect(() => {
    if (value) {
      const [date, time] = value.split('T');
      if (DATE_REGEX.test(date) && TIME_REGEX.test(time)) {
        setDateTimeValue(`${date} ${time}`);
      } else {
        setDateTimeValue('');
      }
    } else {
      setDateTimeValue('');
    }
  }, [value]);

  useEffect(() => {
    if (!isEditing && isValid && dateTimeValue) {
      onChange(dateTimeValue.replace(' ', 'T'));
      changeIsEditing(false);
    }
  }, [isEditing, isValid, dateTimeValue]);

  const handleKeyEvent = (event) => {
    if (isEditing) {
      switch (event.key) {
        case 'Enter': {
          event.preventDefault();
          field.current.querySelector('input').blur();
          break;
        }
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  const handleFocus = () => {
    setIsEditing(true);
    changeIsEditing(true);
  };

  const handleBlur = () => {
    const [date, time] = dateTimeValue.split(' ');
    if (DATE_REGEX.test(date) && TIME_REGEX.test(time)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setIsEditing(false);
  };

  const handleChange = (newValue) => {
    setDateTimeValue(newValue);
  };

  return (
    <div className="ox-form__group">
      <label htmlFor="text-field" className="ox-form__label ox-form__label-bold">
        {t(`entities.${label || ''}`).replace('entities.', '')}
      </label>
      <div className="datetime-container" ref={field}>
        <InputMask
          mask="99/99/9999 99:99"
          placeholder="DD/MM/YYYY 00:00"
          className={`ox-form__field ${disabled ? 'disabled' : ''}`}
          onChange={({ target: { value: changedValue } }) => handleChange(changedValue)}
          value={dateTimeValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default DateTimeField;
