import React, {
  useRef, useEffect, useState, useMemo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import './Overview.scss';
import { useInView } from 'react-intersection-observer';
import * as mailActionsFile from '../../actions/mail/mailActions';
import * as appStateActionFile from '../../actions/appState/appStateActions';
import DocumentList from './DocumentList';
import Toggle from '../toggle/Toggle';
import ScrollButton from '../scrollButton/ScrollButton';
import Preloader from '../preloader/Preloader';
import { getIdOfUser, getOfficeIdOfUser } from '../../helpers/accountHelpers'
import * as metricsReportActionsFile from '../../actions/metricsReport/metricsReportActions';
import * as annotationActionFile from '../../actions/annotation/annotationActions';
import * as orderActionFile from '../../actions/order/orderActions';
import * as branchOfficeActionFile from '../../actions/branchOffice/branchOfficeActions';
import * as machineActionFile from '../../actions/machine/machineActions';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useWebSocket from '../../hooks/useWebSocket';
import useQueue from '../../hooks/useQueue';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Mixpanel } from '../../mixpanel/Mixpanel';
import InfiniteScroll from '../infiniteScroll/InfiniteScroll'
import SearchBar from './searchBar/SearchBar'

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  appStateActions: bindActionCreators(appStateActionFile, dispatch),
  mailActions: bindActionCreators(mailActionsFile, dispatch),
  metricsReportActions: bindActionCreators(metricsReportActionsFile, dispatch),
  orderActions: bindActionCreators(orderActionFile, dispatch),
  branchOfficeActions: bindActionCreators(branchOfficeActionFile, dispatch),
  machineActions: bindActionCreators(machineActionFile, dispatch),
});

const UserOverview = ({
  appStateReducer: {
    toggleValue,
  },
  branchOfficeReducer: {
    updateBranchOffice,
  },
  mailReducer: {
    mails,
    mailCount,
    lastMessageId,
    hasMoreArchivedMails,
    hasMoreOverviewMails,
    loading,
    unassignedId,
  },
  annotationActions,
  appStateActions,
  mailActions,
  metricsReportActions,
  orderActions,
  branchOfficeActions,
  machineActions,
  history,
}) => {
  const initial = useRef(true);
  const { t, i18n } = useTranslation('overview');
  const [inViewRef, inView] = useInView({ threshold: 0 });

  const toggleValues = [t('toggle.toggle1'), t('toggle.toggle2')];
  const branchOfficeTopic = 'branch-office';

  const [archivePage, setArchivePage] = useState(0);
  const [overviewPage, setOverviewPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { getValue: getActiveUser } = useLocalStorage('active-user');

  const branchOfficeIds = useMemo(() => {
    return getOfficeIdOfUser(getActiveUser());
  }, [getActiveUser]);

  const filterBarRef = useRef();

  const { queueLength, addMessageToQueue, getMessageFromQueue } = useQueue();
  const {
    socketConnected,
    subscribeTopic,
    sendMessage,
  } = useWebSocket(addMessageToQueue);


  useOnClickOutside(filterBarRef, () => setShowFilters(false));

  useEffect(() => {
    setOverviewPage(0);
    setArchivePage(0);
    initial.current = true;
    mailActions.emptyMailList();
  }, [searchValue]);

  useEffect(() => {
    if (initial.current) {
      if (toggleValue === 0) {
        loadMoreOverviewMails();
      } else {
        loadMoreArchivedMails();
      }

      initial.current = false;
      return;
    }

  }, [toggleValue, archivePage, overviewPage]);

  useEffect(() => {
    annotationActions.clearAnnotations();
    mailActions.clearFetchedMail();
    orderActions.clearOrder();
    machineActions.clearMachineAttachmentsAndOptions();
    return () => {
      branchOfficeActions.clearBranchOffices();
    };
  }, []);

  useEffect(() => {
    if (queueLength > 0) {
      const message = getMessageFromQueue();
      if (message.topic.includes(branchOfficeTopic)) {
        branchOfficeActions.fetchBranchOfficeSuccess(message.body);
      }
    }
  }, [queueLength]);

  useEffect(() => {
    if (socketConnected) {
      subscribeTopic(branchOfficeTopic);
    }
  }, [socketConnected]);

  useEffect(() => {
    appStateActions.updateSubtitle(t('title'));
  }, [i18n.language]);

  useEffect(() => {
    if (updateBranchOffice) {
      sendMessage(branchOfficeTopic, updateBranchOffice);
    }
  }, [updateBranchOffice]);

  useEffect(() => {
    // Set page back to zero
    if (toggleValue === 0) setArchivePage(0);
    if (toggleValue === 1) setOverviewPage(0);
  }, [toggleValue, isSearching]);


  useEffect(() => {
    if (branchOfficeIds.length > 0) {
      mailActions.fetchDocumentCount(branchOfficeIds);
    }
  }, [branchOfficeIds])

  const loadMoreArchivedMails = useCallback(() => {
    if(loading) return;
    if (hasMoreArchivedMails && branchOfficeIds.length > 0) {
      mailActions.fetchArchivedMails(branchOfficeIds, archivePage, searchValue)
      setArchivePage(archivePage+1)
    } else {
      mailActions.fetchedAllArchivedMails();
    }
  }, [hasMoreArchivedMails, branchOfficeIds, archivePage, loading, searchValue]);

  const loadMoreOverviewMails = useCallback(() => {
    if(loading) return;
    if (hasMoreOverviewMails && branchOfficeIds.length > 0) {
      mailActions.fetchOverviewMails(branchOfficeIds, overviewPage, searchValue)
      setOverviewPage(overviewPage+1)
    } else {
      mailActions.fetchedAllOverviewMails();
    }
  }, [hasMoreOverviewMails, branchOfficeIds, overviewPage, loading, searchValue]);

  const handleUnassignOrder = async (mailId) => {
    const patchBody = [
      {
        op: 'replace',
        path: '/status',
        value: {
          id: 1,
        },
      },
      {
        op: 'remove',
        path: '/employee',
      },
    ];
    await mailActions.patchMail(mailId, patchBody);
    metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), mailId, 'UNASSIGN');
  };

  const handleMailClick = async (mail) => {
    Mixpanel.track('Document clicked');
    annotationActions.resetAnnotationsState();
    if (!mail.employee && !mail.processedAt) {
      const patchBody = [
        {
          op: 'replace',
          path: '/status',
          value: {
            id: 2,
          },
        },
        {
          op: 'replace',
          path: '/employee',
          value: {
            id: getIdOfUser(getActiveUser()),
          },
        },
      ];
      await mailActions.patchMail(mail.id, patchBody);
    }
    history.push({
      pathname: `/annotator/ner/${mail.id}`,
      state: { hasBeenAssigned: true },
    });

    mailActions.emptyMailList();
    setArchivePage(0)
    setOverviewPage(0)
  };

  const createMailOverview = useMemo(() => {
    if (toggleValues[toggleValue].toLowerCase() === toggleValues[1].toLowerCase()) {
      return (
        <>
          <InfiniteScroll
            loadMore={loadMoreArchivedMails}
            hasMore={hasMoreArchivedMails}
            loader={(
              <div className="loader-ellips" key="loader-ellips">
                <span className="loader-ellips__dot" />
                <span className="loader-ellips__dot" />
                <span className="loader-ellips__dot" />
                <span className="loader-ellips__dot" />
              </div>
            )}
          >
            <DocumentList
              ToggleValue={toggleValue}
              Mails={mails}
              OnRowClick={handleMailClick}
            />
          </InfiniteScroll>
          <ScrollButton shouldRender={!inView} />
        </>
      );
    }

    return (
      <>
        <InfiniteScroll
          loadMore={loadMoreOverviewMails}
          hasMore={hasMoreOverviewMails}
          loader={(
            <div className="loader-ellips" key="loader-ellips">
              <span className="loader-ellips__dot" />
              <span className="loader-ellips__dot" />
              <span className="loader-ellips__dot" />
              <span className="loader-ellips__dot" />
            </div>
          )}
        >
          <DocumentList
            ToggleValue={toggleValue}
            Mails={mails}
            OnRowClick={handleMailClick}
            handleUnassignOrder={handleUnassignOrder}
          />
        </InfiniteScroll>
        <ScrollButton shouldRender={!inView} />
      </>
    );
  }, [mails, lastMessageId, toggleValue, hasMoreArchivedMails, hasMoreOverviewMails, unassignedId, inView, branchOfficeIds, loading, searchValue]);

  return (
    <div className="overview">
      <div className="ox-navigation-horizontal toggle-menu">
        <Toggle
          className="annotation-type"
          toggleValues={toggleValues}
          toggleCounts={mailCount}
          selectedValue={toggleValue}
        />
      </div>
      <div className="centered">
        <SearchBar onChange={setSearchValue} />
        <div className="annotated-documents" id="documents-overview">
          {loading && initial.current ? <Preloader /> : createMailOverview}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview);
