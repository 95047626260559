import React from 'react';
import './EntitiesSidebarNav.scss';
import { useTranslation } from 'react-i18next';
import { sortJsonArrayByKey } from '../../../helpers/arrayHelpers';

export default ({ tabItems, activeIndex, indexChange }) => {
  const { t } = useTranslation('annotationView');
  return (
    <div className="ox-navigation-horizontal sidebar-nav-container">
      <ul className="ox-navigation ox-navigation--grow nav-list">
        {
          sortJsonArrayByKey(tabItems).map((item) => (
            <li key={item.id} className="ox-navigation__item" aria-current={item.index === activeIndex ? 'page' : ''}>
              <span
                role="button"
                onClick={() => indexChange(item.index)}
                className="ox-navigation__item__link nav-item"
              >
                {t(`tabs.${item.id}`)}
              </span>
            </li>
          ))
        }
      </ul>
    </div>
  );
};
