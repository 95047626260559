import React, { useCallback } from 'react';

const DocumentTypeSelector = ({
  DocumentId,
  Type,
  ChangeSelection,
  DocumentTypes,
  Disabled,
}) => {
  const handleSelectionChange = useCallback((event) => {
    if (!Disabled) {
      const typeId = parseInt(event.target.value, 10) || null;
      const data = {
        documentId: DocumentId,
        documentTypes: DocumentTypes,
        typeId,
      };
      ChangeSelection(data);
    }
  }, [DocumentId, Disabled]);


  return (
    <div className="attachment-item-container">
      <select
        className="custom-select ox-badge ox-badge--update ox-badge--has-border"
        onChange={handleSelectionChange}
        disabled={Disabled}
        value={Type.id}
      >
        {
          DocumentTypes.map((documentType) => (
            <option className="doc-type-option" key={documentType.name} value={documentType.id}>{documentType.name}</option>
          ))
        }
      </select>
    </div>
  );
};

export default DocumentTypeSelector;
