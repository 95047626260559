import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { patchAuthenticationHeader, baseUrl, LANGUAGES } from '../constants/constants';
import useLocalStorage from './useLocalStorage';

export const useLanguage = () => {
  const { getValue: getActiveUser, setValue: setActiveUser } = useLocalStorage('active-user');
  const selectedLanguage = getActiveUser().language;
  const { i18n } = useTranslation();

  const changeLanguage = (userId, language) => {
    if (userId && LANGUAGES.map((lang) => lang.code).includes(language)) {
      const payload = [
        {
          op: 'replace',
          path: '/language',
          value: language,
        },
      ];
      return axios.patch(`${baseUrl}/employee/${userId}`, payload, patchAuthenticationHeader)
        .then(() => {
          setActiveUser({ ...getActiveUser(), language });
          i18n.changeLanguage(language);
        })
        .catch((error) => console.error(error));
    }
    return null;
  };

  return {
    changeLanguage,
    selectedLanguage,
  };
};
