import * as uploadTypes from '../actions/upload/uploadTypes';

const initialState = {
  loading: false,
  uploadError: undefined,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadTypes.UPLOAD_DOCUMENT:
      return { ...state, loading: true };
    case uploadTypes.UPLOAD_DOCUMENT_SUCCESS: {
      return { ...state, loading: false };
    }
    case uploadTypes.UPLOAD_DOCUMENT_FAILED:
      return { ...state, loading: false, uploadError: action.error };
    case uploadTypes.INVALID_API_KEY:
      localStorage.removeItem('active-user');
      return { ...state };
    default:
      return state;
  }
};

export { uploadReducer as default };
