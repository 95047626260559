import { useEffect } from 'react';

/**
 * Use this hook if you want to fire some code when you click outside of a component
 * Make a ref and pass it to the this hook, and attach it to the element you want to click outside of
 * Pass a handler arrow function which will execute only when you click outside of the ref'd element
 * @param  {Ref}      ref     The ref from a useRef hook that you will also attach to the element you want to click outside of
 * @param  {Function} handler A function which will be executed when you click outside
 */
export default (ref, handler = () => {}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // Will fire code once an outside click is detected
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
