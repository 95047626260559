import React from 'react';

export default ({
  id,
  label,
  value,
  disabled,
  onChange,
  hasChanged,
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.checked);
      hasChanged(true);
    }
  };
  return (
    <div className="ox-form__group">
      <div className="ox-form__checkbox">
        {/* JSON Parse here will parse the string "false" into a boolean */}
        <input
          type="checkbox"
          id={id}
          checked={JSON.parse(value)}
          className="ox-form__checkbox__input"
          onChange={handleChange}
          disabled={disabled}
        />
        <label htmlFor={id} className="ox-form__checkbox__label">
          {label}
        </label>
      </div>
    </div>
  );
};
