import React, { useEffect, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

const InfiniteScroll = ({ loadMore, hasMore, loader, children }) => {
	const initialRender = useRef(true);

	const [inViewRef, inView] = useInView({ threshold: 0 });

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}

		if (inView && hasMore) {
			loadMore();
		}
	}, [loadMore, hasMore, inView]);

	const renderLoader = useMemo(() => {
		if (hasMore && loader) {
			return loader;
		}

		return null
	}, [hasMore, loader]);

	return (
		<>
			{children}
			{renderLoader}
			<div ref={inViewRef} />
		</>
	);
};

export default InfiniteScroll;
